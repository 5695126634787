import { get } from 'lodash'

// import {
//   CertificationsListType,
//   CertificationsOptionsType
// } from 'types/Certifications.types'

import { types } from './Protocols.actions'

type Certifications = {
  certifications: any[]
  selectedCertifications: any | null
  loading: boolean
  options?: any[]
}

const INITIAL_STATE = {
  certifications: [],
  selectedCertifications: null,
  loading: false,
  options: []
}

function setState(state: Certifications) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET_LOADING]: setState(state),
    [types.SET_PROTOCOLS]: setState(state),
    [types.SET_SELECTED_PROTOCOLS]: setState(state),
    [types.SET_PROTOCOLS_OPTIONS]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}
