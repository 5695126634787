import { call, put } from 'redux-saga/effects'

import { ProtocolsService } from 'services'
import { toasts } from 'utils/toasts'

import * as actions from './Protocols.actions'

export function* fetchAll(event) {
  yield put(actions.setLoading({ loading: true }))

  const $certifications = new ProtocolsService()
  const {
    success,
    status,
    data: certifications
  } = yield call(
    $certifications.fetchAll.bind($certifications),
    event.payload.filters
  )

  yield put(actions.setLoading({ loading: false }))

  if (status === 404) {
    return yield put(
      actions.setProtocols({
        certifications: []
      })
    )
  } else if (success) {
    return yield put(actions.setProtocols({ certifications }))
  } else toasts.generalFail()
}

export function* getProtocolsOptions() {
  const $certifications = new ProtocolsService()
  const { success, status, data } = yield call(
    $certifications.options.bind($certifications)
  )

  if (success) {
    return yield put(actions.setProtocolsOptions({ options: data }))
  } else if (status === 404) {
    return yield put(actions.setProtocolsOptions({ options: [] }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}
