import { TraceabilityFilter } from 'types/Traceability.types'

export const types = Object.freeze({
  GET_BOXES: '@box/getBoxes',
  GET_BOXES_EXPORT: '@box/getBoxesExported',
  SET_FILTERS_BOXES: '@box/setFiltersBoxes',
  SET_BOXES: '@box/setBoxes',
  CLEAN_BOXES: '@box/cleanBoxes'
})

export function getBoxes(
  filterBoxes?: TraceabilityFilter,
  ordersKeys?: string[]
) {
  return { type: types.GET_BOXES, payload: { filterBoxes, ordersKeys } }
}

export function getBoxesExported(
  filterBoxes?: TraceabilityFilter,
  ordersKeys?: string[]
) {
  return { type: types.GET_BOXES_EXPORT, payload: { filterBoxes, ordersKeys } }
}

export function setFilterBoxes(filterBoxes: TraceabilityFilter) {
  return { type: types.SET_BOXES, payload: { filterBoxes } }
}

export function cleanBoxes() {
  return { type: types.CLEAN_BOXES }
}

export function setBoxes({ boxes = {} } = {}) {
  return { type: types.SET_BOXES, payload: { boxes } }
}
