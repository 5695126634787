/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux'
import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { traceabilityTablesActions } from 'store/TraceabilityTables'
import * as S from './styled'
import { NewCard } from 'components/Cards/NewCards'
import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'
import { useHistory } from 'react-router-dom'

const links = {
  invoices: '/notas-fiscais',
  sifProd: '/sif-producao',
  netWeight: '/peso-liquido',
  properties: '/properties'
}

export function ConsolidationCards() {
  const history = useHistory()
  const currentPath = history.location.pathname
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    properties,
    invoices,
    sifProduction,
    trackedTons,
    cardsLoading,
    filters
  } = useSelector(all)

  const i18nLang = window.localStorage.getItem('i18nextLng')

  useEffect(() => {
    if (filters.begin && filters.end && !cardsLoading) {
      dispatch(traceabilityTablesActions.getKpisCards(filters))
    }
  }, [filters])
  return (
    <S.GridContainer data-testid="cards">
      <S.GridIconCard item xs={12} md={8}>
        <S.GridCards item xs={12} md={12}>
          <S.GridCard item xs={12} md={6}>
            <NewCard
              variant="order"
              quantity={(invoices && invoices.toLocaleString(i18nLang)) || 0}
              redirect={currentPath + links.invoices}
              service={t('invoices')}
              description={t('accessInvoices')}
              icon={
                theme.appTheme === THEMES_NAME.jbsFriboi
                  ? 'calendar-right-jbs'
                  : 'calendar-right'
              }
              isLoading={cardsLoading}
            />
          </S.GridCard>
          <S.GridCard item xs={12} md={6}>
            <NewCard
              variant="netWeight"
              quantity={Number(trackedTons).toLocaleString(i18nLang) || 0}
              redirect={currentPath + links.netWeight}
              service={t('netWeight')}
              description={t('accessNetWeightTitle')}
              icon={
                theme.appTheme === THEMES_NAME.jbsFriboi
                  ? 'calendar-right-jbs'
                  : 'calendar-right'
              }
              isLoading={cardsLoading}
            />
          </S.GridCard>
        </S.GridCards>
        <S.GridCards item xs={12} md={12}>
          <S.GridCard item xs={12} md={6}>
            <NewCard
              variant="sifProd"
              quantity={
                (sifProduction && sifProduction.toLocaleString(i18nLang)) || 0
              }
              redirect={currentPath + links.sifProd}
              service={t('sifProduction')}
              description={t('accessSifProduction')}
              icon={
                theme.appTheme === THEMES_NAME.jbsFriboi
                  ? 'calendar-right-jbs'
                  : 'calendar-right'
              }
              isLoading={cardsLoading}
            />
          </S.GridCard>
          <S.GridCard item xs={12} md={6}>
            <NewCard
              variant="property"
              quantity={
                (properties && properties.toLocaleString(i18nLang)) || 0
              }
              redirect={currentPath + links.properties}
              service={t('properties')}
              description={t('accessProperties')}
              icon={
                theme.appTheme === THEMES_NAME.jbsFriboi
                  ? 'calendar-right-jbs'
                  : 'calendar-right'
              }
              isLoading={cardsLoading}
            />
          </S.GridCard>
        </S.GridCards>
      </S.GridIconCard>
    </S.GridContainer>
  )
}
