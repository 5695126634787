import { call, put } from 'redux-saga/effects'

import { ViewModuleService } from 'services'
import { toasts } from 'utils/toasts'

import * as actions from './ViewModule.actions'

export function* fetchAll(event) {
  yield put(actions.setLoading({ loading: true }))

  const $viewModule = new ViewModuleService()
  const {
    success,
    status,
    data: viewModules
  } = yield call($viewModule.fetchAll.bind($viewModule), event.payload.filters)

  yield put(actions.setLoading({ loading: false }))

  if (status === 404) {
    return yield put(
      actions.setViewModules({
        viewModules: []
      })
    )
  } else if (success) {
    return yield put(actions.setViewModules({ viewModules }))
  } else toasts.generalFail()
}

export function* getViewModuleOptions() {
  const $viewModule = new ViewModuleService()
  const { success, status, data } = yield call(
    $viewModule.options.bind($viewModule)
  )

  if (success) {
    return yield put(actions.setViewModulesOptions({ options: data }))
  } else if (status === 404) {
    return yield put(actions.setViewModulesOptions({ options: [] }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* getViewModuleNavigation(action) {
  const { id } = action.payload
  yield put(actions.setLoading({ loading: true }))

  const $viewModule = new ViewModuleService()
  const modules = yield call($viewModule.navigation.bind($viewModule), id)
  if (modules.success) {
    return yield put(
      actions.setViewModules({
        viewModules: modules.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}
