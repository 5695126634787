import { omit, pick } from 'lodash'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { viewMode } from 'config'

import { Button } from 'components/Button'
import { UserService, UserStorageService } from 'services'
import { setAvatar } from 'store/User/User.actions'
import { toasts } from 'utils/toasts'

import { BasicInformation } from './BasicInformation/BasicInformation'
import { GroupTable } from './GroupTable/GroupTable'
import { ProfileImage } from './ProfileImage/ProfileImage'

import { useTranslation } from 'react-i18next'

import * as S from './styled'
import { ViewModuleTable } from './ViewModuleTable/ViewModuleTable'
import { CertificationsTable } from './CertificationsTable/CertificationsTable'

export const INITIAL_STATE = {
  CPF: '',
  department: '',
  email: '',
  emailRecovery: '',
  firstName: '',
  lang: '',
  lastName: '',
  groups: [],
  roleId: '',
  role: '',
  clientGroups: [],
  industryGroups: [],
  modules: [],
  certifications: [],
  createdAt: '',
  lastSignIn: ''
}

export const EDITABLE_INITIAL_STATE = {
  avatar: null,
  lang: 'Português (brasileiro)'
}

export function ProfileForm() {
  const { t, i18n } = useTranslation()
  const [form, setForm] = useState({ ...INITIAL_STATE })
  const haveCertifications = UserStorageService.getCertification()
  const [toUploadImage, setImageToUpload] = useState<File>(null)
  const [editableForm, setEditableForm] = useState({
    ...EDITABLE_INITIAL_STATE
  })

  const dispatch = useDispatch()
  const { goBack } = useHistory()

  function onLoadImage() {
    return function handleSelectImage(e: ChangeEvent<HTMLInputElement>) {
      if (!e.target.files) return
      const [img] = Array.from(e.target.files)
      setImageToUpload(img)
      setEditableForm(state => ({
        ...state,
        avatar: { src: URL.createObjectURL(img), filename: img.name }
      }))
    }
  }

  function onRemoveImage() {
    setImageToUpload(null)
    setEditableForm(state => ({ ...state, avatar: null }))
  }

  async function submit() {
    const $user = new UserService()
    const loggedInUser = UserStorageService.getUserData()

    const promises = [
      $user.updateProfile(loggedInUser.id, omit(editableForm, 'avatar'))
    ]
    if (toUploadImage) {
      const uploadPromise = $user.uploadAvatar(loggedInUser.id, toUploadImage)
      promises.push(uploadPromise)
    }

    await Promise.all(promises)

    if (toUploadImage) {
      dispatch(setAvatar({ avatar: editableForm.avatar.src }))
      setImageToUpload(null)
    }

    setForm(prev => ({ ...prev, ...editableForm }))

    i18n.changeLanguage(editableForm.lang)
    goBack()
    toast.success(t('users:profileUpdated'))
  }

  useEffect(() => {
    const fetch = async () => {
      if (!Object.keys(form).includes('id')) {
        const loggedInUser = UserStorageService.getUserData()
        const { data, success } = await new UserService().fetchOne(
          loggedInUser.id
        )
        const _data = viewMode.industry
          ? {
              ...data,
              clientGroups: data.groups,
              industryGroups: data.groupsIndustry
            }
          : data
        if (!success) toasts.generalFail()
        else {
          setEditableForm(pick(data, 'avatar', 'lang'))
          setForm(prev => ({
            ...prev,
            ..._data
          }))
        }
      }
    }

    fetch()
  })

  async function sendEmail({ form }) {
    const payload = {
      ...form,
      id: form.id,
      email: form.email,
      token: form.recoveryToken
    }
    const user = await new UserService().sendEmailNewPwd(payload)
    if (await user) {
      toast.success(t('toasts:sendEmailSuccess'))
      goBack()
    }
    return user
  }

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.AvatarGrid item xs={12} md={4}>
          <S.AvatarFlex>
            <S.GridAvatarPhoto>
              <ProfileImage
                payload={editableForm.avatar || {}}
                onLoadImage={onLoadImage()}
                onRemoveImage={() => onRemoveImage()}
              />
            </S.GridAvatarPhoto>
            <S.GridAvatarInfo>
              <span className="Name">
                {form.firstName} {form.lastName}
              </span>
              <span className="Department">{form.department}</span>
            </S.GridAvatarInfo>
            <S.GridAvatarInfo>
              <span className="Separator"></span>
              <S.GridAvatarGroupDate>
                <S.GridAvatarDate>
                  <div className="createdAt">
                    <span>{t('users:dateRegister')}</span>
                    <span className="Date">{form.createdAt}</span>
                  </div>
                </S.GridAvatarDate>
                <S.GridAvatarDate>
                  <div className="lastAccess">
                    <span>{t('users:lastAccessDate')}</span>
                    <span className="Date">{form.lastSignIn}</span>
                  </div>
                </S.GridAvatarDate>
              </S.GridAvatarGroupDate>
            </S.GridAvatarInfo>
          </S.AvatarFlex>
        </S.AvatarGrid>
        <S.InfoGrid item xs={12} md={8}>
          <S.InfoFlex>
            <S.GridHeader>
              <S.GridTitle item container xs={12}>
                <S.BoxTitle>{t('users:basicInformations')}</S.BoxTitle>
              </S.GridTitle>
            </S.GridHeader>
            <BasicInformation
              form={form}
              sendEmail={() => sendEmail({ form })}
            />
          </S.InfoFlex>
        </S.InfoGrid>
        {viewMode.industry && (
          <S.GroupsGrid item xs={12}>
            {haveCertifications && (
              <S.GroupsFlex>
                <S.GridHeader>
                  <S.GridTitle item container xs={12}>
                    <S.BoxTitle>{t('users:certificationAccess')}</S.BoxTitle>
                  </S.GridTitle>
                </S.GridHeader>
                <CertificationsTable rows={form.certifications} />
              </S.GroupsFlex>
            )}
            <S.GroupsFlex>
              <S.GridHeader>
                <S.GridTitle item container xs={12}>
                  <S.BoxTitle>{t('users:viewModuleAccess')}</S.BoxTitle>
                </S.GridTitle>
              </S.GridHeader>
              <ViewModuleTable rows={form.modules} />
            </S.GroupsFlex>
            <S.GroupsFlex>
              <S.GridHeader>
                <S.GridTitle item container xs={12}>
                  <S.BoxTitle>{t('users:industryGroupAccess')}</S.BoxTitle>
                </S.GridTitle>
              </S.GridHeader>
              <GroupTable rows={form.industryGroups} />
            </S.GroupsFlex>
            <S.GroupsFlex>
              <S.GridHeader>
                <S.GridTitle item container xs={12}>
                  <S.BoxTitle>{t('users:customerGroupAccess')}</S.BoxTitle>
                </S.GridTitle>
              </S.GridHeader>
              <GroupTable rows={form.clientGroups} />
            </S.GroupsFlex>
          </S.GroupsGrid>
        )}
      </S.FullGrid>
      <S.GridButtons item xs={12}>
        <Button variant="default" onClick={goBack} size="medium">
          <p>{t('common:cancel')}</p>
        </Button>
        <Button variant="alternative" onClick={submit} size="medium">
          <p>{t('common:save')}</p>
        </Button>
      </S.GridButtons>
    </S.Wrapper>
  )
}
