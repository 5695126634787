import styled from 'styled-components'
import { Button, Icon, Input } from 'components'
import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'

export const GoBackLoginButton = styled(Button)`
  margin: 4rem 0 0 0;
  min-width: 24rem;

  @media screen and (min-width: 375px) {
    min-width: 32rem;
  }
`

export const Wrapper = styled(Grid)`
  justify-content: center;
  text-align: center;
  > button {
    height: 4.8rem;
    width: 31rem;
  }
`

export const TitleMargin = styled.span`
  display: block;
  height: 2.4rem;
`

export const FullGrid = styled(Grid)`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
  text-align: center;
  padding-right: 2rem;
  width: 100%;
`

export const SideBarCorner = styled.div`
  align-self: center;
  margin-left: -9.35rem;
  margin-top: -15rem;
  .chevron {
    color: white;
    display: block;
    font-size: 4.8rem;
    margin-left: 9rem;
    margin-top: -16rem;
    transform: translateY(-110%);
  }
`

export const Logo = styled.img.attrs({
  alt: 'Lupa com uma folha com Ecotrace escrito ao lado',
  src: '/images/logo.png'
})`
  height: auto;
  margin: 0 0 2.4rem 0;
  object-fit: contain;
  object-position: center;
  width: 17rem;
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.8rem;
  font-weight: 600;
  text-align: center;
`

export const Description = styled(Grid)`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-bottom: 2rem;
  padding-top: 1.2rem;
`

export const DescriptionBox = styled(Grid)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundMessage};
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  margin: 2rem 0 0 0;
  width: 48rem;
`

export const BoldText = styled.span`
  font-weight: 600;
`

export const DescriptionText = styled.p`
  color: ${({ theme }) => theme.colors.backgroundLoggedOut};
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1.6rem 3.2rem;
  text-align: center;
`

export const FormContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`

export const FormInput = styled(Input)`
  width: 42rem;

  .MuiInputLabel-root {
    transform: translate(3.2rem, 1.7rem) scale(1);
  }

  .MuiOutlinedInput-input {
    padding: 1.57rem 0;
  }
`

export const ForgetPassword = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 1.6rem;
  font-size: 1.6rem;
  text-decoration: underline;
`

export const InputError = styled.span`
  color: ${({ theme }) => theme.colors.red};
  display: flex;
  flex-direction: row;
  font-size: 1.1rem;
  height: 3rem;
  margin-left: -28rem;
  place-content: center;
`

export const InputErrorIcon = styled(Icon)`
  margin-top: -1.5rem;
`

export const InputErrorText = styled.p`
  align-self: center;
  color: ${({ theme }) => theme.colors.red};
  margin-bottom: 1rem;
  margin-left: 1rem;
`

export const LogoEco = styled.img.attrs({
  alt: 'Lupa com uma folha com Ecotrace escrito ao lado',
  src: '/images/logo.png'
})`
  height: auto;
  object-fit: contain;
  object-position: center;
  margin: 0 0 3rem 0;
  width: 17rem;
`

export const LogoFriboi = styled.img.attrs({
  alt: 'Logo JBS',
  src: '/images/logo_jbs_login.png'
})`
  height: auto;
  object-fit: contain;
  object-position: center;
  margin: 0 0 3rem 0;
  width: 23rem;
`
