import i18n from 'i18n'

export const INITIAL_STATE = {
  barcode: '',
  batch: '',
  begin: null,
  boningoutinspectionnum: '',
  car: '',
  nrProtocols: '',
  cargo: '',
  destinationcnpj: '',
  end: null,
  invoice: '',
  fazCode: '',
  ie: '',
  order: '',
  sku: '',
  producerdoc: '',
  propname: '',
  producername: '',
  sequential: '',
  slaughtersif: '',
  typeDate: 'invoiceat',
  trackable: 'true',
  limit: null,
  page: 0,
  column: 'order',
  direction: 'asc',
  totalItems: 0,
  totalPages: 0,
  stockcnpj: '',
  productionType: 'all',
  invoiceType: '',
  processedSIF: '',
  opNumberProcessed: '',
  years: [],
  months: [],
  accessGroupsFilter: [],
  industryGroupsFilter: [],
  suitable: '',
  bioma: '',
  state: '',
  city: '',
  boningoutindustrycnpj: '',
  analyze: '',
  protocols: 'all'
}

export const INITIAL_STATE_ERRORS = {
  barcode: '',
  batch: '',
  begin: null,
  boningoutinspectionnum: '',
  car: '',
  nrProtocols: '',
  cargo: '',
  destinationcnpj: '',
  end: null,
  invoice: '',
  ie: '',
  order: '',
  sku: '',
  producerdoc: '',
  propname: '',
  producername: '',
  sequential: '',
  slaughtersif: '',
  typeDate: '',
  trackable: '',
  stockcnpj: '',
  productionType: '',
  invoiceType: '',
  processedSIF: '',
  opNumberProcessed: '',
  years: '',
  months: '',
  accessGroupsFilter: '',
  industryGroupsFilter: '',
  suitable: '',
  bioma: '',
  state: '',
  city: '',
  analyze: '',
  boningoutindustrycnpj: '',
  fazCode: '',
  protocols: ''
}

export const typesDate = () => [
  { title: i18n.t('common:invoice'), value: 'invoiceat' },
  { title: i18n.t('common:order'), value: 'orderedat' },
  { title: i18n.t('common:slaughter'), value: 'slaughteredat' },
  { title: i18n.t('common:production'), value: 'boningoutat' },
  { title: i18n.t('common:validity'), value: 'expiresat' }
]

export const suitableType = () => [
  // { title: i18n.t('common:all'), value: 'all' },
  { title: i18n.t('common:fit'), value: 'true' },
  { title: i18n.t('common:notFit'), value: 'false' }
]

export const trackables = () => [
  { title: i18n.t('common:yes'), value: 'true' },
  { title: i18n.t('common:no'), value: 'false' },
  { title: i18n.t('common:all'), value: 'all' }
]
export const protocols = () => [
  { title: i18n.t('certification:all'), value: 'all' },
  { title: i18n.t('certification:cfv'), value: 'cfv' },
  { title: i18n.t('certification:none'), value: 'none' }
]

export const productionTypeOptions = () => [
  { title: i18n.t('common:all'), value: 'all' },
  // { title: i18n.t('common:processed'), value: 'processed' },
  { title: i18n.t('common:innatura'), value: 'innatura' },
  { title: i18n.t('common:offal'), value: 'offal' }
]

export const productionTypeRadio = () => [
  { title: i18n.t('common:innatura'), value: 'innatura' },
  { title: i18n.t('common:processed'), value: 'processed' },
  { title: i18n.t('common:offal'), value: 'offal' }
]

export const productionTypeRadioRetails = () => [
  { title: i18n.t('common:innatura'), value: 'innatura' },
  { title: i18n.t('common:offal'), value: 'offal' }
]
export const invoiceTypeOptions = () => [
  { title: i18n.t('common:in'), value: 'in' },
  { title: i18n.t('common:out'), value: 'out' }
]

export const biomaTypeOptions = () => [
  { title: i18n.t('common:in'), value: 'in' },
  { title: i18n.t('common:out'), value: 'out' }
]
