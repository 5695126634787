import { useState } from 'react'

import {
  FormActionRow,
  Input,
  InputDate,
  InputSelect,
  RadioButton,
  InputAutocomplete,
  MoreFilter
} from 'components'

import { startOfYear } from 'date-fns'
import { UserStorageService } from 'services'

import { ERRORS_INITIAL_STATE } from 'constants/client.constants'
import { submitOnEnter } from 'utils/submitOnEnter'
import { USER_ROLES } from 'constants/user.constants'

import { useTranslation } from 'react-i18next'

import {
  INITIAL_STATE_ERRORS,
  suitableType,
  trackables,
  protocols,
  typesDate
} from 'constants/traceability.constants'

import * as S from './styled'
import { STATES } from 'constants/location.constants'
import { useSelector } from 'react-redux'
import { all as allCity } from 'store/City/City.selector'
import { getUserYearInit } from 'utils/GetUserYearFilter'
import { THEMES_NAME } from 'config/theme.config'

type InputGeneralFiltersProps = {
  filter: any
  errors: typeof INITIAL_STATE_ERRORS
  onSubmit: () => void
  clearFilter: () => void
  handleInputData: (_value, _name) => void
  handleSelect: (_value, _name) => void
  accessGroupsFilter: Record<string, any>[]
  industryGroupsFilter: Record<string, any>[]
  isPropertyPage: boolean
  toggleMoreFilter(): void
}

export function InputGeneralFilters({
  filter,
  errors,
  onSubmit,
  clearFilter,
  handleInputData,
  handleSelect,
  accessGroupsFilter,
  industryGroupsFilter,
  isPropertyPage,
  toggleMoreFilter
}: InputGeneralFiltersProps) {
  const { t } = useTranslation()
  const [formErrors, _setFormErrors] = useState({ ...ERRORS_INITIAL_STATE })
  const { cities } = useSelector(allCity)
  const { user } = UserStorageService.getUserData()
  const theme = UserStorageService.getViewModuleTheme()
  const haveProtocols = UserStorageService.getCertification()

  // vvvvvvvvvvvvvvvvvv GENERAL INPUT FILTERS vvvvvvvvvvvvvvvvvv
  const BeginDate = () => (
    <InputDate
      error={Boolean(errors.begin)}
      fullWidth
      helperText={errors.begin}
      id="dtStart"
      minDate={startOfYear(new Date(getUserYearInit(user), 0))}
      label={t('common:initialDate')}
      name="dtStart"
      value={filter.begin}
      onInput={value => handleInputData(value, 'begin')}
    />
  )
  const FinalDate = () => (
    <InputDate
      error={Boolean(errors.end)}
      fullWidth
      helperText={errors.end}
      id="dtFinal"
      label={t('common:finalDate')}
      name="dtFinal"
      value={filter.end}
      onInput={value => handleInputData(value, 'end')}
    />
  )
  const RadioDateFilter = () => (
    <RadioButton
      error={Boolean(errors.typeDate)}
      helperText={errors.typeDate}
      options={typesDate()}
      label=""
      onSelected={value => handleSelect(value, 'typeDate')}
      value={filter.typeDate}
    />
  )
  const AccessGroupFilter = () => (
    <InputAutocomplete
      padding={0.7}
      defaultValue={filter.accessGroupsFilter}
      error={Boolean(formErrors.accessGroupsFilter)}
      fullWidth
      helperText={formErrors.accessGroupsFilter}
      label={t('common:accessGroupsFilter')}
      onSelected={value => handleSelect(value, 'accessGroupsFilter')}
      optionLabel="accessGroupName"
      options={accessGroupsFilter}
      allowRemoveAllItems={user.tag === USER_ROLES.admin}
      disableRemoveButton={
        user.tag === USER_ROLES.viewer && filter.accessGroupsFilter.length === 1
      }
      clearFilter={clearFilter}
    />
  )
  const IndustryGroupFilter = () => (
    <InputAutocomplete
      padding={0.7}
      defaultValue={filter.industryGroupsFilter}
      error={Boolean(formErrors.industryGroupsFilter)}
      fullWidth
      helperText={formErrors.industryGroupsFilter}
      label={t('common:industryGroupsFilter')}
      onSelected={value => handleSelect(value, 'industryGroupsFilter')}
      optionLabel="industryGroupName"
      options={industryGroupsFilter}
      allowRemoveAllItems={user.tag === USER_ROLES.admin}
      disableRemoveButton={
        user.tag === USER_ROLES.admin &&
        filter.industryGroupsFilter.length === 1
      }
      clearFilter={clearFilter}
    />
  )

  const InvoiceNumber = () => (
    <Input
      error={Boolean(errors.invoice)}
      fullWidth
      helperText={errors.invoice}
      label={t('common:invoice')}
      value={filter.invoice}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'invoice')}
    />
  )
  const CodeNumber = () => (
    <Input
      error={Boolean(errors.fazCode)}
      fullWidth
      helperText={errors.fazCode}
      label="Código Propriedade"
      value={filter.fazCode}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'fazCode')}
    />
  )
  const CorporateNameOwnership = () => (
    <Input
      error={Boolean(errors.propname)}
      fullWidth
      helperText={errors.propname}
      label={t('common:corporateNameOwnership')}
      value={filter.propname}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'propname')}
    />
  )
  const ProducerName = () => (
    <Input
      error={Boolean(errors.producername)}
      fullWidth
      helperText={errors.producername}
      label={t('common:producerName')}
      value={filter.producername}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'producername')}
    />
  )
  const ProducerDocument = () => (
    <Input
      error={Boolean(errors.producerdoc)}
      fullWidth
      helperText={errors.producerdoc}
      label={t('common:producerDocument')}
      value={filter.producerdoc}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'producerdoc')}
    />
  )
  const CarNumber = () => (
    <Input
      error={Boolean(errors.car)}
      fullWidth
      helperText={errors.car}
      label={t('common:carNumber')}
      value={filter.car}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'car')}
    />
  )
  // ^^^^^^^^^^^^^^^^^ GENERAL INPUT FILTERS ^^^^^^^^^^^^^^^^^

  // vvvvvvvvvvvvvvvvv INPUT FILTERS (EXCEPT FOR PROPERTY) vvvvvvvvvvvvvvvvv
  // const InvoiceType = () => (
  //   <Input
  //     error={Boolean(errors.invoiceType)}
  //     fullWidth
  //     helperText={errors.invoiceType}
  //     label={t('common:invoiceType')}
  //     value={filter.invoiceType}
  //     onKeyPress={submitOnEnter(onSubmit)}
  //     onInput={value => handleInputData(value, 'invoiceType')}
  //   />
  // )
  const OrderNumber = () => (
    <Input
      error={Boolean(errors.order)}
      fullWidth
      helperText={errors.order}
      label={t('common:orderNumber')}
      value={filter.order}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'order')}
    />
  )
  const nrSku = () => (
    <Input
      error={Boolean(errors.sku)}
      fullWidth
      helperText={errors.sku}
      label={t('common:sku')}
      value={filter.sku}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'sku')}
    />
  )
  const Charge = () => (
    <Input
      error={Boolean(errors.cargo)}
      fullWidth
      helperText={errors.cargo}
      label={t('common:charge')}
      value={filter.cargo}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'cargo')}
    />
  )
  const ShipperDocument = () => (
    <Input
      error={Boolean(errors.stockcnpj)}
      fullWidth
      helperText={errors.stockcnpj}
      label={t('common:shipperCNPJ')}
      value={filter.stockcnpj}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'stockcnpj')}
    />
  )
  const DestinationID = () => (
    <Input
      error={Boolean(errors.destinationcnpj)}
      fullWidth
      helperText={errors.destinationcnpj}
      label={t('common:destinationId')}
      value={filter.destinationcnpj}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'destinationcnpj')}
    />
  )
  const BarCode = () => (
    <Input
      error={Boolean(errors.barcode)}
      fullWidth
      helperText={errors.barcode}
      label={t('common:barCode')}
      value={filter.barcode}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'barcode')}
    />
  )
  const SlaughterSIF = () => (
    <Input
      error={Boolean(errors.slaughtersif)}
      fullWidth
      helperText={errors.slaughtersif}
      label={t('common:slaughterSIF')}
      value={filter.slaughtersif}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'slaughtersif')}
    />
  )
  const BoningAndProductionSIF = () => (
    <Input
      error={Boolean(errors.boningoutinspectionnum)}
      fullWidth
      helperText={errors.boningoutinspectionnum}
      label={t('common:boningSIF')}
      value={filter.boningoutinspectionnum}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'boningoutinspectionnum')}
    />
  )
  const CNPJProduction = () => (
    <Input
      error={Boolean(errors.boningoutindustrycnpj)}
      fullWidth
      helperText={errors.boningoutindustrycnpj}
      label={t('common:boningoutindustrycnpj')}
      value={filter.boningoutindustrycnpj}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'boningoutindustrycnpj')}
    />
  )
  const StateRegistration = () => (
    <Input
      error={Boolean(errors.ie)}
      fullWidth
      helperText={errors.ie}
      label={t('common:stateRegistration')}
      value={filter.ie}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'ie')}
    />
  )
  const Trackable = () => (
    <InputSelect
      error={Boolean(errors.trackable)}
      fullWidth
      helperText={errors.trackable}
      label={t('common:traceability')}
      onSelected={value => handleSelect(value, 'trackable')}
      optionLabel="title"
      optionValue="value"
      options={trackables()}
      value={filter.trackable}
    />
  )
  const HaveCFV = () => (
    <InputSelect
      error={Boolean(errors.protocols)}
      fullWidth
      helperText={errors.protocols}
      label={t('common:protocols')}
      onSelected={value => handleSelect(value, 'protocols')}
      optionLabel="title"
      optionValue="value"
      options={protocols()}
      value={filter.protocols}
    />
  )
  const NRProtocols = () => (
    <Input
      error={Boolean(errors.nrProtocols)}
      fullWidth
      helperText={errors.nrProtocols}
      label={t('common:nrProtocols')}
      value={filter.nrProtocols}
      onKeyPress={submitOnEnter(onSubmit)}
      onInput={value => handleInputData(value, 'nrProtocols')}
    />
  )
  // ^^^^^^^^^^^^^^^^^ INPUT FILTERS (EXCEPT FOR PROPERTY) ^^^^^^^^^^^^^^^^^

  // vvvvvvvvvvvvvvvvv PROPERTY EXCLUSIVE FILTERS vvvvvvvvvvvvvvvvvvv
  const AdditionalPropertyFilters = () => (
    <>
      <S.GridInput item xs={12} sm={4} md={3}>
        <InputSelect
          error={Boolean(errors.analyze)}
          fullWidth
          helperText={errors.analyze}
          label={t('common:analyze')}
          onSelected={value => handleSelect(value, 'analyze')}
          optionLabel="title"
          optionValue="value"
          options={suitableType()}
          value={filter.analyze}
        />
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={3}>
        <InputSelect
          error={Boolean(errors.state)}
          fullWidth
          helperText={errors.state}
          label={t('common:state')}
          onSelected={({ value }) => handleSelect(value, 'state')}
          optionLabel="title"
          optionValue="value"
          options={STATES}
          value={filter.state}
        />
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={3}>
        <InputSelect
          disabled={filter.state === ''}
          error={Boolean(errors.city)}
          fullWidth
          helperText={errors.city}
          label={t('common:city')}
          onSelected={value => handleSelect(value.title, 'city')}
          optionLabel="title"
          options={cities.filter(city => city.state === filter.state)}
          optionValue={'title'}
          value={filter.city}
        />
      </S.GridInput>
    </>
  )
  // ^^^^^^^^^^^^^^^ PROPERTY EXCLUSIVE FILTERS ^^^^^^^^^^^^^^^^^^

  const NonPropertyFilters = () => (
    <>
      {/* <S.GridInput item xs={12} sm={4} md={2}>
        {InvoiceType()}
      </S.GridInput> */}
      <S.GridInput item xs={12} sm={4} md={2}>
        {InvoiceNumber()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={2}>
        {OrderNumber()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={2}>
        {Charge()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={2}>
        {ShipperDocument()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={2}>
        {DestinationID()}
      </S.GridInput>
      {theme !== THEMES_NAME.sampco && (
        <S.GridInput item xs={12} sm={4} md={2}>
          {BarCode()}
        </S.GridInput>
      )}
      <S.GridInput item xs={12} sm={4} md={2}>
        {nrSku()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={2}>
        {SlaughterSIF()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={2}>
        {BoningAndProductionSIF()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={2}>
        {CNPJProduction()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={2}>
        {ProducerDocument()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={2}>
        {StateRegistration()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={2}>
        {CorporateNameOwnership()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={2}>
        {CarNumber()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={2}>
        {ProducerName()}
      </S.GridInput>
      {user.tag === USER_ROLES.admin && (
        <S.GridInput item xs={12} sm={4} md={2}>
          {Trackable()}
        </S.GridInput>
      )}
      {haveProtocols && (
        <>
          <S.GridInput item xs={12} sm={4} md={2}>
            {HaveCFV()}
          </S.GridInput>
          <S.GridInput item xs={12} sm={4} md={2}>
            {NRProtocols()}
          </S.GridInput>
        </>
      )}
    </>
  )

  const PropertyFilters = () => (
    <>
      <S.GridInput item xs={12} sm={4} md={3}>
        {CodeNumber()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={3}>
        {CorporateNameOwnership()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={3}>
        {ProducerName()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={3}>
        {ProducerDocument()}
      </S.GridInput>
      <S.GridInput item xs={12} sm={4} md={3}>
        {CarNumber()}
      </S.GridInput>
      {AdditionalPropertyFilters()}
      <S.GridInput item xs={12} sm={4} md={3}>
        {InvoiceNumber()}
      </S.GridInput>
    </>
  )

  return (
    <S.Wrapper container spacing={4}>
      <S.GridFilter container alignItems="center">
        <S.GridInput item xs={12} sm={4} md={2}>
          {BeginDate()}
        </S.GridInput>
        <S.GridInput item xs={12} sm={4} md={2}>
          {FinalDate()}
        </S.GridInput>
        <S.GridInput item xs={12} sm={4} md={7}>
          {RadioDateFilter()}
        </S.GridInput>
        <S.GridToggleButton xs={12} sm={4} md={1}>
          <MoreFilter
            border="not"
            toggle={toggleMoreFilter}
            arrowAction="close"
          />
        </S.GridToggleButton>
        {AccessGroupFilter()}
        {IndustryGroupFilter()}
        {!isPropertyPage ? NonPropertyFilters() : PropertyFilters()}
      </S.GridFilter>
      <S.GridFilter container>
        <S.GridButtons item xs={12} sm={12} md={12} lg={12}>
          <FormActionRow
            onExit={clearFilter}
            onSubmit={onSubmit}
            type="filter"
          />
        </S.GridButtons>
      </S.GridFilter>
    </S.Wrapper>
  )
}
