import { ChartSIF } from 'components/Charts'
import { AnalisysSIFTable } from 'pages/SIFProduction/Details/AnalisysSIFTable'
import { useTranslation } from 'react-i18next'
import * as S from './styled'
export function Details() {
  const { t } = useTranslation()
  return (
    <S.Wrapper>
      <S.Graphic item md={8}>
        <S.GridHeader>
          <S.BoxTitle>
            <S.Title>{t('sifProduction')}</S.Title>
          </S.BoxTitle>
        </S.GridHeader>
        <ChartSIF />
      </S.Graphic>
      <S.Details item md={4}>
        <S.GridHeader>
          <S.BoxTitle>
            <S.Title>{t('analyzeBySIF')}</S.Title>
          </S.BoxTitle>
        </S.GridHeader>
        <S.Table>
          <AnalisysSIFTable />
        </S.Table>
      </S.Details>
    </S.Wrapper>
  )
}
