import { useEffect, useState } from 'react'

import { CheckButton } from 'components/CheckButton'
import { GroupButtonSearch } from 'components/GroupButtonSearch'
import { Input } from 'components/Input'
import { useTranslation } from 'react-i18next'
import { submitOnEnter } from 'utils/submitOnEnter'
import { getUserYearsForFilter } from 'utils/GetUserYearFilter'
import { UserStorageService } from 'services'
import { USER_ROLES } from 'constants/user.constants'
import { useDispatch, useSelector } from 'react-redux'
import { traceabilityTablesActions } from 'store/TraceabilityTables'
import { all as allTraceability } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { INITIAL_STATE } from 'constants/traceability.constants'

import * as S from './styled'
import { isEqual } from 'lodash'
import { MoreFilter } from 'components/MoreFilter'

interface IYearButtonFilter {
  toggleMoreFilter(): void
}

export function InlineProductionSifFilter({
  toggleMoreFilter
}: IYearButtonFilter) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { filters } = useSelector(allTraceability)
  const INITIAL_YEARS_STATE = filters.years.length
    ? filters.years
    : [new Date().getFullYear()]
  const [yearsState, setYearsState] = useState(INITIAL_YEARS_STATE)
  const [localInput, setLocalInput] = useState(
    filters.boningoutinspectionnum || ''
  )

  const { user } = UserStorageService.getUserData()

  const years = getUserYearsForFilter(user)

  function toggleValues(value: number) {
    setYearsState([value])
  }

  async function onSubmit() {
    try {
      dispatch(
        traceabilityTablesActions.setFilters({
          ...filters,
          boningoutinspectionnum: localInput
        })
      )
    } catch (ex) {
      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      console.log('ERROR: ', JSON.stringify(pairs))
    }
  }

  function setBeginEndDate(isClear: boolean) {
    const begin = new Date(yearsState[0], 0, 1)
    const atualYear = new Date().getFullYear()
    const end =
      isClear || yearsState.includes(atualYear)
        ? new Date()
        : new Date(yearsState[yearsState.length - 1], 11, 31, 23, 59, 59)
    return { begin, end, atualYear }
  }

  function handleInputData(value: string) {
    setLocalInput(value)
  }

  function clearFilter() {
    const { begin, end, atualYear } = setBeginEndDate(true)
    setLocalInput('')
    dispatch(
      traceabilityTablesActions.setFilters({
        ...INITIAL_STATE,
        begin,
        end,
        years: [atualYear]
      })
    )
    setYearsState([new Date().getFullYear()])
  }

  useEffect(() => {
    if (!isEqual(filters.years, yearsState)) {
      const { begin, end } = setBeginEndDate(false)
      dispatch(
        traceabilityTablesActions.setFilters({
          ...filters,
          begin,
          end,
          years: yearsState
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearsState])

  return (
    <S.Wrapper container>
      <S.GridYear item xs={12} md={3}>
        <S.Title>{t('dashboard:year')}</S.Title>
        <S.Group>
          {years.map(({ labelAdmin, labelView, valueAdmin, valueView }) => {
            const yearValue =
              user.tag === USER_ROLES.admin ? valueAdmin : valueView
            return (
              <CheckButton
                data-testid="yearButton"
                defaultValue={
                  user.tag === USER_ROLES.admin
                    ? yearsState.includes(valueAdmin)
                    : yearsState.includes(valueView)
                }
                onToggle={() => toggleValues(yearValue)}
                disableChange={
                  yearsState.length === 1 && yearsState.includes(valueAdmin)
                }
                key={valueAdmin}
              >
                <span className="years-btn">
                  {user.tag === USER_ROLES.admin ? labelAdmin : labelView}
                </span>
              </CheckButton>
            )
          })}
        </S.Group>
      </S.GridYear>
      <S.GridInput item xs={12} md={4}>
        <Input
          label={t('productionSIF')}
          fullWidth
          value={localInput}
          onKeyPress={submitOnEnter(onSubmit)}
          onInput={value => handleInputData(value)}
          error={false}
        />
      </S.GridInput>
      <S.GridButtons item xs={12} md={3}>
        <GroupButtonSearch
          onExit={clearFilter}
          onSubmit={onSubmit}
          type="filter"
        />
      </S.GridButtons>
      <MoreFilter border="not" toggle={toggleMoreFilter} />
    </S.Wrapper>
  )
}
