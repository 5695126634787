import CssBaseline from '@material-ui/core/CssBaseline'
import { Provider as ReduxProvider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { theme } from 'config'
import { THEMES } from 'themes'
import { store } from 'store'
import { GlobalStyle } from 'styles/global.styles'

import { ToastContainer } from './components/ToastContainer'
import { Spinner } from './components/Spinner'
import { Router } from './router'

import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import { createContext, useState } from 'react'
import { UserStorageService } from 'services'

interface ThemeContextType {
  currentTheme: string
  setTheme: (_theme: string) => void
}

const storedTheme = UserStorageService.getViewModuleTheme()
export const ThemeContext = createContext<ThemeContextType>({
  currentTheme: 'jbsFriboi',
  setTheme: (_theme = null) => {}
})

function App() {
  const [currentTheme, setTheme] = useState(storedTheme || theme.appTheme)
  return (
    <ReduxProvider store={store}>
      <ThemeContext.Provider
        value={{ currentTheme, setTheme } as ThemeContextType}
      >
        <ThemeProvider theme={THEMES[currentTheme]}>
          <I18nextProvider i18n={i18n}>
            <GlobalStyle />
            <CssBaseline />
            <Router />
            <Spinner />
            <ToastContainer />
          </I18nextProvider>
        </ThemeProvider>
      </ThemeContext.Provider>
    </ReduxProvider>
  )
}

export default App
