import { Base } from 'layouts'

import * as S from './styled'
import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'
import { ModuleCard } from 'components/Cards/ModuleCards'
import { useEffect, useState, useContext } from 'react'
import { all as viewModuleSelector } from 'store/ViewModule/ViewModule.selector'
import { useDispatch, useSelector } from 'react-redux'
import { viewModuleActions } from 'store/ViewModule'
import { useHistory } from 'react-router-dom'
import { UserStorageService } from 'services'
import { ThemeContext } from 'App'

export function ViewModules() {
  const dispatch = useDispatch()
  const { viewModules } = useSelector(viewModuleSelector)
  const [selected, setSelected] = useState(null)
  const history = useHistory()
  const { setTheme } = useContext(ThemeContext)

  function handleSelect(module) {
    setSelected(module)
  }

  useEffect(() => {
    const loggedInUser = UserStorageService.getUserDateForLangDropdown().id
    dispatch(viewModuleActions.getViewModulesNavigation(loggedInUser))
    setTheme(THEMES_NAME.jbsFriboi)
  }, [dispatch, setTheme])

  useEffect(() => {
    dispatch(
      viewModuleActions.setSelectedModule({
        selectedModule: selected
      })
    )
    if (selected && selected.link) {
      const theme = THEMES_NAME[selected.theme] || 'THEMES_NAME.jbsFriboi'
      setTheme(theme)
      UserStorageService.setViewModuleTheme(theme)
      history.push(selected.link)
    }
  }, [selected, history, dispatch, setTheme])

  return (
    <Base>
      <S.GridContainer data-testid="view-modules">
        <S.GridIconCard item xs={12} md={8}>
          <S.GridCards item xs={12} md={12}>
            {viewModules.length > 0 &&
              viewModules.map((module, key) => (
                <S.GridCard
                  onClick={() => handleSelect(module)}
                  item
                  xs={12}
                  md={12}
                  key={key}
                >
                  <ModuleCard
                    variant={module.color}
                    title={module.name}
                    redirect={module.link}
                    image={`/images/${module.image}`}
                    icon={
                      theme.appTheme === THEMES_NAME.jbsFriboi
                        ? 'calendar-right-jbs'
                        : 'calendar-right'
                    }
                    isLoading={false}
                  />
                </S.GridCard>
              ))}
          </S.GridCards>
        </S.GridIconCard>
      </S.GridContainer>
    </Base>
  )
}
