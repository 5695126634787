import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { pick } from 'lodash'

import { API as config, app as App } from 'config'
import { UserStorageService } from 'services'
import { GoTo } from 'utils/goto'
import { toasts } from 'utils/toasts'
import { setVisibility } from 'store/Spinner/Spinner.actions'

import { store } from 'store'
import { setApiVersion } from 'store/Version/Version.actions'

type Response = { success: boolean; status: number; data: Record<string, any> }
type DelParams = { endpoint: string; data?: Record<string, any> }
type GetParams = { endpoint: string; params?: Record<string, any> }
type PostParams = {
  endpoint: string
  payload?: Record<string, any>
  config?: AxiosRequestConfig
}

interface HTTPProvider {
  get(_settings: GetParams): Promise<Response>
  post(_settings: PostParams): Promise<Response>
  put(_settings: PostParams): Promise<Response>
  patch(_settings: PostParams): Promise<Response>
}

export class HTTP implements HTTPProvider {
  private $axios: AxiosInstance

  constructor() {
    this.$axios = axios.create(config)
    this.$axios.interceptors.request.use((config: Record<string, any>) => {
      if (config.showLoading) {
        store.dispatch(setVisibility(true))
      }
      config.headers.app = (() => {
        if (App.env !== 'development') return window.location.origin
        return 'http://ecotrace.info'
      })()

      config.headers.module = (() => {
        const module = UserStorageService.getModule() || ''
        if (!module) return ''
        return module
      })()

      config.headers.authorization = (() => {
        const { token } = JSON.parse(UserStorageService.getToken() || '{}')
        if (!token) return ''
        return `Bearer ${token}`
      })()

      if (App.env === 'development') {
        console.log(`[${config.method.toUpperCase()}] HTTP Req: \n`, config)
      }
      return config
    })
    this.$axios.interceptors.response.use(
      config => {
        store.dispatch(setVisibility(false))
        store.dispatch(setApiVersion(config.headers['api-version']))
        if (App.env === 'development') console.log('HTTP Res:\n', config)
        return config
      },
      err => {
        store.dispatch(setVisibility(false))
        if (App.env === 'development') console.log('HTTP Req Fails:\n', config)
        if (
          err.response.status === 401 &&
          !err.config.url?.toLocaleLowerCase().includes('sign-in') &&
          !err.config.url?.toLocaleLowerCase().includes('update-passwd')
        ) {
          GoTo.login()
        }
        return Promise.reject(err)
      }
    )
  }

  private async handle(req): Promise<Response> {
    try {
      const res = await req
      if (res.config.responseType === 'blob') {
        return res.data
      }
      return { success: true, status: res.status, data: res.data || {} }
    } catch (ex) {
      if (!ex.response) {
        toasts.generalFail()
        return { success: false, status: -1, data: ex }
      }

      if (ex.response.status === 500) toasts.generalFail()
      return { ...pick(ex.response, 'data', 'status'), success: false }
    }
  }

  async get({ endpoint, params = {} }: GetParams): Promise<Response> {
    const _params = Object.fromEntries(
      Object.keys(params)
        .filter(key => params[key])
        .map(key => [key, params[key]])
    )
    const req = this.$axios.get(endpoint, { params: _params })
    return await this.handle(req)
  }

  async download({ endpoint, params = {} }: GetParams): Promise<any> {
    const _params = Object.fromEntries(
      Object.keys(params)
        .filter(key => params[key])
        .map(key => [key, params[key]])
    )
    const req = this.$axios.get(endpoint, {
      responseType: 'blob',
      params: _params
    })

    return await this.handle(req)
  }

  async post({
    endpoint,
    payload = {},
    config
  }: PostParams): Promise<Response> {
    const req = this.$axios.post(endpoint, payload, config)
    return await this.handle(req)
  }

  async put({ endpoint, payload = {}, config }: PostParams): Promise<Response> {
    const req = this.$axios.put(endpoint, payload, config)
    return await this.handle(req)
  }

  async patch({
    endpoint,
    payload = {},
    config
  }: PostParams): Promise<Response> {
    const req = this.$axios.patch(endpoint, payload, config)
    return await this.handle(req)
  }

  async delete({ endpoint, data }: DelParams): Promise<Response> {
    const req = this.$axios.delete(endpoint, { data })
    return await this.handle(req)
  }
}
