import { GroupButtonSearch, MoreFilter } from 'components'
import { CheckButton } from 'components/CheckButton'
import { INITIAL_STATE } from 'constants/traceability.constants'
import { USER_ROLES } from 'constants/user.constants'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { UserStorageService } from 'services'
import { traceabilityTablesActions } from 'store/TraceabilityTables'
import { all as allTraceability } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { getUserYearsForFilter } from 'utils/GetUserYearFilter'
import * as S from './styled'

interface IYearButtonFilter {
  toggleMoreFilter(): void
}

export function InlineNetWeightFilter({ toggleMoreFilter }: IYearButtonFilter) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { filters } = useSelector(allTraceability)
  const INITIAL_YEARS_STATE = filters.years.length
    ? filters.years
    : [new Date().getFullYear()]
  const [yearsState, setYearsState] = useState(INITIAL_YEARS_STATE)
  const [monthsState, setMonthsState] = useState(
    filters.months.length ? filters.months : []
  )

  const { user } = UserStorageService.getUserData()

  const years = getUserYearsForFilter(user)

  const months = Array.from({ length: 12 }).map((_, i) => ({
    value: i + 1,
    isActive: false,
    label: new Date(new Date().getFullYear(), i, 1)
      .toLocaleString('pt-br', { month: 'short' })
      .replace('.', '')
  }))

  function toggleValues(
    isActive: boolean,
    value: number,
    type: 'year' | 'month'
  ) {
    if (
      type === 'year' &&
      yearsState.length === 1 &&
      yearsState.includes(value)
    ) {
      return
    }

    if (type === 'year') {
      setMonthsState([])
      return setYearsState([value])
    }

    if (isActive) {
      setMonthsState(prevState => prevState.concat(value).sort((a, b) => a - b))
    } else {
      setMonthsState(prevState => prevState.filter(month => month !== value))
    }
  }

  function setBeginEndDate(isClear: boolean = false) {
    const begin = new Date(yearsState[0], 0, 1)
    const atualYear = new Date().getFullYear()
    const end =
      isClear || yearsState.includes(atualYear)
        ? new Date()
        : new Date(yearsState[yearsState.length - 1], 11, 31, 23, 59, 59)
    return { begin, end, atualYear }
  }

  function clearFilter() {
    const { begin, end, atualYear } = setBeginEndDate(true)
    dispatch(
      traceabilityTablesActions.setFilters({
        ...INITIAL_STATE,
        begin,
        end,
        years: [atualYear]
      })
    )
    setMonthsState([])
    setYearsState([new Date().getFullYear()])
  }

  async function onSubmit() {
    const { begin, end } = setBeginEndDate()
    dispatch(
      traceabilityTablesActions.setFilters({
        ...filters,
        begin,
        end,
        months: monthsState,
        years: yearsState
      })
    )
  }

  useEffect(() => {
    if (!isEqual(filters.years, yearsState)) {
      const { begin, end } = setBeginEndDate()
      dispatch(
        traceabilityTablesActions.setFilters({
          ...filters,
          begin,
          end,
          months: monthsState,
          years: yearsState
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearsState])

  return (
    <S.Wrapper container md={12}>
      <S.WrapperYear item xs={12} md={2}>
        <S.Title>{t('dashboard:year')}</S.Title>
        <S.Group>
          {years.map(({ labelAdmin, labelView, valueAdmin, valueView }) => {
            const yearValue =
              user.tag === USER_ROLES.admin ? valueAdmin : valueView
            return (
              <CheckButton
                data-testid="yearButton"
                defaultValue={
                  user.tag === USER_ROLES.admin
                    ? yearsState.includes(valueAdmin)
                    : yearsState.includes(valueView)
                }
                onToggle={val => toggleValues(val, yearValue, 'year')}
                disableChange={
                  yearsState.length === 1 && yearsState.includes(valueAdmin)
                }
                key={valueAdmin}
              >
                <span className="years-btn">
                  {user.tag === USER_ROLES.admin ? labelAdmin : labelView}
                </span>
              </CheckButton>
            )
          })}
        </S.Group>
      </S.WrapperYear>
      <S.WrapperMonth item xs={12} md={7}>
        <S.Title>{t('dashboard:months')}</S.Title>
        <S.Group>
          {months.map(({ label, value }) => (
            <CheckButton
              className="checked"
              defaultValue={!!monthsState.find(f => f === value)}
              onToggle={val => toggleValues(val, value, 'month')}
              key={value}
            >
              {t(`months:${label}`)}
            </CheckButton>
          ))}
        </S.Group>
      </S.WrapperMonth>
      <S.GridButtons item xs={12} md={2}>
        <GroupButtonSearch
          onExit={clearFilter}
          onSubmit={onSubmit}
          type="filter"
        />
      </S.GridButtons>
      <MoreFilter border="not" toggle={toggleMoreFilter} />
    </S.Wrapper>
  )
}
