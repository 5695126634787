import { useCallback, useEffect, useState, MouseEvent, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Table, NewPagination, Icon, Button } from 'components'
import { GridHeader } from '../GridHeader'

import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { all as allRawMaterial } from 'store/RawMaterial/RawMaterial.selector'
import { all as allOrders } from 'store/Order/Order.selector'
import { RawMaterialActions } from 'store/RawMaterial'
import { RawMaterialBoxActions } from 'store/RawMaterialBox'

import { columns } from 'constants/rawMaterial.constants'
import { INITIAL_STATE } from 'constants/traceability.constants'
import { useTranslation } from 'react-i18next'

import * as S from './styled'
import { IconButton } from '@material-ui/core'
import { ThemeContext } from 'App'
import { THEMES } from 'themes'
import { ModalRawMaterialBoxes } from 'components/ModalRawMaterialBoxes'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { ModalSendEmail } from 'components/ModalSendMail'

export function RawMaterialList() {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { currentTheme } = useContext(ThemeContext)
  const theme = THEMES[currentTheme]

  const [filter, setFilter] = useState({ ...INITIAL_STATE })
  const [order, setOrder] = useState(null)
  const { filters, tableLoading } = useSelector(all)
  const { filterRawMaterial, rawMaterial = [] } = useSelector(allRawMaterial)
  const { ordersKeys } = useSelector(allOrders)
  const { pagination } = rawMaterial
  const [modalExport, setModalExport] = useState(false)
  const [isNewPage, setNewPage] = useState({ ...pagination })
  const fetch = useCallback(() => {
    setFilter(filters)
    if (!rawMaterial.items) {
      dispatch(RawMaterialActions.getRawMaterial(filters, ordersKeys))
    }
  }, [dispatch, filters, ordersKeys, rawMaterial.items])

  useEffect(() => {
    if (ordersKeys.length) fetch()
  }, [fetch, ordersKeys])

  async function handleChangePage(
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    const newFilter = {
      ...filterRawMaterial,
      ...filters
    }
    newFilter.page = newPage
    dispatch(RawMaterialActions.setFilterRawMaterial(newFilter))
    dispatch(RawMaterialActions.getRawMaterial(newFilter, ordersKeys))
  }

  function onExit() {
    dispatch(RawMaterialBoxActions.cleanRawMaterialBoxes())
    setOrder(null)
  }

  function actionsOnTable(item) {
    return (
      <IconButton
        id="action"
        color="inherit"
        aria-label="mapButton"
        onClick={() => setOrder(item)}
        size="medium"
        edge="end"
      >
        <Icon
          fill={theme.colors.primary}
          icon="white-search"
          width={11}
          height={11}
          aria-label="action"
        />
      </IconButton>
    )
  }

  function handleNewPage() {
    const newPage = isNewPage.page
    if (newPage > pagination.totalPages) {
      const newFilter = {
        ...filterRawMaterial,
        ...filters
      }
      newFilter.page = pagination.totalPages
      dispatch(RawMaterialActions.setFilterRawMaterial(newFilter))
      dispatch(RawMaterialActions.getRawMaterial(newFilter, ordersKeys))
      setNewPage({ ...pagination, page: newPage })
    } else {
      const newFilter = {
        ...filterRawMaterial,
        ...filters
      }
      newFilter.page = newPage
      dispatch(RawMaterialActions.setFilterRawMaterial(newFilter))
      dispatch(RawMaterialActions.getRawMaterial(newFilter, ordersKeys))
      setNewPage({ ...pagination, page: newPage })
    }
  }

  function handleInputPagination(name: string, value: number) {
    setNewPage({ ...pagination, [name]: value })
  }

  function onExport() {
    dispatch(RawMaterialActions.getRawMaterialExported(ordersKeys))
    setModalExport(true)
  }

  function handleChangeRowsPerPage(e) {
    const newFilter = {
      ...filterRawMaterial,
      ...filters
    }
    newFilter.limit = e.target.value
    dispatch(RawMaterialActions.setFilterRawMaterial(newFilter))
    dispatch(RawMaterialActions.getRawMaterial(newFilter, ordersKeys))
  }

  return (
    <>
      {order && <ModalRawMaterialBoxes order={order} onExitHandler={onExit} />}

      <S.FullGrid item xs={12}>
        <S.HeaderContainer>
          <S.GridSubtitle>
            <GridHeader title={t('tables:rawMaterialList')} />
          </S.GridSubtitle>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontSize: '1.4rem',
                color: 'gray',
                marginRight: '1.5rem'
              }}
            >
              {rawMaterial.pagination?.totalItems} {t('common:register')}
            </p>
            <Button variant="primary" size="medium" onClick={onExport}>
              <S.ButtonContent>
                <CloudDownloadIcon />
                <S.ButtonText>{t('common:export')}</S.ButtonText>
              </S.ButtonContent>
            </Button>
          </div>
        </S.HeaderContainer>

        <S.GridTable>
          <Table
            columns={columns()}
            rows={rawMaterial.items || []}
            sortColumn={{ column: filter.column, sort: filter.direction }}
            isLoading={tableLoading}
            actionTable={actionsOnTable}
            actionColumnLabel={t('common:details')}
            isScroll={rawMaterial.items?.length > 10}
          />
          {modalExport && (
            <ModalSendEmail
              title={t('modalExportOrders:titleRawMaterial')}
              description={t('modalExportOrders:message')}
              onExit={() => setModalExport(false)}
            />
          )}
          {pagination?.totalItems > 0 && (
            <NewPagination
              count={pagination?.totalPages}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 50, 100]}
              handleInput={handleInputPagination}
              handleNewPage={handleNewPage}
              page={pagination?.page}
              limit={pagination.limit}
            />
          )}
        </S.GridTable>
      </S.FullGrid>
    </>
  )
}
