import { useSelector } from 'react-redux'
import * as S from './styles'
import { version } from 'store/Version/Version.selector'

export const Footer = () => {
  const year = new Date().getFullYear()
  const { versionApi, versionApp } = useSelector(version)
  return (
    <S.Wrapper data-testid="footer">
      <S.Title>
        © {year}{' '}
        <a href="https://ecotrace.info/" target="_blanc">
          By Ecotrace Solutions | v{versionApp}-{versionApi}
        </a>
      </S.Title>
    </S.Wrapper>
  )
}
