export const types = Object.freeze({
  SET_LOGS: '@logs/setLogs',
  SET_LOADING: '@logs/setLoading',
  FETCH_BY_ID: '@logs/fetchById',
  FETCH_ALL: '@logs/fetchAll'
})

export function fetchById(id: string) {
  return {
    type: types.FETCH_BY_ID,
    payload: { id }
  }
}

export function fetchAll(
  { params = {} }: { params?: Record<string, any> } = { params: {} }
) {
  return { type: types.FETCH_ALL, payload: { params } }
}

export function setLoading({ loading = false } = {}) {
  return { type: types.SET_LOADING, payload: { loading } }
}

export function setLogs({ logs = {} } = {}) {
  return {
    type: types.SET_LOGS,
    payload: { logs, loading: false }
  }
}
