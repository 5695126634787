import { Base } from 'layouts'
import { Details } from './Details'
import { GeneralFilters } from 'components/GeneralFilters'
import { InlineProductionSifFilter } from './Components'

export function SIFProduction() {
  return (
    <Base>
      <GeneralFilters
        InlineFilter={InlineProductionSifFilter}
        showInlineFilters={false}
      />
      <Details />
    </Base>
  )
}
