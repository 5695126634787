import * as S from './styled'
import { useTranslation } from 'react-i18next'

export function ViewModuleListTable({ payload }) {
  const { t } = useTranslation()
  return (
    <S.Wrapper data-testid="ViewModuleListTable">
      <S.ScrollWrapper>
        <S.Table id="table-modal">
          <tbody>
            <S.Line>
              <S.Header>{t('tables:moduleName')}</S.Header>
            </S.Line>
            {payload.map((each, key) => (
              <S.Line id="values" key={key}>
                <S.Column>{each.description}</S.Column>
              </S.Line>
            ))}
          </tbody>
        </S.Table>
      </S.ScrollWrapper>
    </S.Wrapper>
  )
}
