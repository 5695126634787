import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { TableNotFoundHint } from 'components/TableNotFoundHint'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ColumnsProps = {
  align?: 'right' | 'left' | 'center'
  fixed?: boolean
  name: string
  title?: string
}

export type TableProps = {
  columns: ColumnsProps[]
  linkTableNotFound?: string
  rows: any[]
  sortColumn?: {
    column: string
    sort: string
  }
  actionTable?: (_row) => JSX.Element
  changeColumn?: (_props) => JSX.Element
  onSortColumn?: (_column) => void
  isLoading?: boolean
}

export function Table({
  columns = [],
  linkTableNotFound = '',
  rows = [],
  sortColumn,
  actionTable,
  changeColumn,
  onSortColumn,
  isLoading
}: TableProps) {
  const { t } = useTranslation()
  const columnFixed = columns.findIndex(f => f.fixed) > -1
  const columnScroll = columns.findIndex(f => !f.fixed) > -1

  const loadingTable = () => {
    if (!rows.length && isLoading) {
      return (
        <S.TableLoadingFistTime>
          {t('common:loadingData')}
        </S.TableLoadingFistTime>
      )
    }
    if (rows.length && isLoading) {
      return <S.TableLoading>{t('common:loadingData')}</S.TableLoading>
    }
    if (!rows.length && !isLoading) {
      return <TableNotFoundHint to={linkTableNotFound} />
    }
  }

  function IconHeader({ column = '' }) {
    return (
      <S.ButtonIcon aria-label="Chevron Right">
        {sortColumn.column !== column || sortColumn.sort === 'asc' ? (
          <ArrowDownwardIcon
            style={{
              color: sortColumn.column === column ? '#757575' : '#b1b1b1'
            }}
          />
        ) : (
          <ArrowUpwardIcon
            style={{
              color: sortColumn.column === column ? '#757575' : '#b1b1b1'
            }}
          />
        )}
      </S.ButtonIcon>
    )
  }

  return (
    <S.Wrapper
      data-testid="Table"
      columnFixed={columnFixed}
      columnScroll={columnScroll}
      actionTable={actionTable}
    >
      <S.Container isLoading={isLoading}>
        <S.Table id="table-fixed">
          <tbody>
            <S.Line id="header">
              {columns.map(
                (column, index) =>
                  column.fixed && (
                    <S.Header
                      key={index}
                      buttonOpacity={
                        sortColumn ? sortColumn.column === column.name : 0
                      }
                      className={column.align}
                      onClick={() =>
                        onSortColumn ? onSortColumn(column.name) : false
                      }
                    >
                      {onSortColumn && column.align === 'right' && (
                        <IconHeader column={column.name} />
                      )}
                      {column.title || column.name}
                      {onSortColumn && column.align !== 'right' && (
                        <IconHeader column={column.name} />
                      )}
                    </S.Header>
                  )
              )}
            </S.Line>
            {rows.map((row, index) => (
              <S.Line id="values" key={index}>
                {columns.map(
                  (column, i) =>
                    column.fixed && (
                      <S.Column key={i} className={column.align}>
                        {changeColumn
                          ? changeColumn({ column, row })
                          : row[column.name]}
                      </S.Column>
                    )
                )}
              </S.Line>
            ))}
          </tbody>
        </S.Table>
      </S.Container>
      {loadingTable()}
    </S.Wrapper>
  )
}
