import * as S from './styled'
import { useTranslation } from 'react-i18next'

export function CertificationsTable({ rows }) {
  const { t } = useTranslation()
  return (
    <S.Wrapper>
      <S.Table>
        <tbody>
          <S.Line id="header">
            <S.Header>{t('certification:certificationName')}</S.Header>
          </S.Line>
          {rows.map(row => (
            <S.Line id="values" key={row.id}>
              <S.Column>
                {row.name} - {row.description}
              </S.Column>
            </S.Line>
          ))}
        </tbody>
      </S.Table>
    </S.Wrapper>
  )
}
