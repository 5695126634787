import { HTTP } from 'providers'

export class ProtocolsService extends HTTP {
  private endpoint = '/certifications'
  private config: Record<string, any> = {
    showLoading: true
  }

  public fetchAll(params = {}) {
    return this.get({ endpoint: this.endpoint, params })
  }

  public fetchOne(id) {
    return this.get({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public async options() {
    return this.get({ endpoint: `${this.endpoint}/options` })
  }
}
