import { call, put } from 'redux-saga/effects'

import { viewMode } from 'config'

import { toasts } from 'utils/toasts'

import { UserService } from '../../services'

import i18n from 'i18n'

import * as actions from './User.actions'
import { getCountriesListByLanguage } from 'utils/Countries'

export function* changePassword(action) {
  const { payload } = action

  const $user = new UserService()

  const { success, status } = yield call($user.changePassword.bind($user), {
    payload
  })

  if (success) {
    toasts.updatePwd()
    return yield put(actions.pwdUpdate({ pwdUpdate: true }))
  } else if (status === 401) {
    toasts.updatePwdOldError()
    return yield put(actions.pwdUpdate({ pwdUpdate: false }))
  } else {
    toasts.updatePwdError()
    return yield put(actions.pwdUpdate({ pwdUpdate: false }))
  }
}

export function* fetchAll(action) {
  const { params } = action.payload

  yield put(actions.setUser({ user: {} }))

  const $user = new UserService()
  const fetchAll = $user.fetchAll.bind($user)

  const { success, status, data: users } = yield call(fetchAll, { params })
  if (status === 404) {
    return yield put(
      actions.setUsers({
        users: { items: [], pagination: { totalItems: 0 } }
      })
    )
  } else if (success) yield put(actions.setUsers({ users }))
  else toasts.generalFail()

  if (viewMode.industry) {
    const countries = getCountriesListByLanguage(i18n.language)

    const fetchFilterOptions = $user.fetchFilterOptions.bind($user)
    const { data: filterOptions } = yield call(fetchFilterOptions)
    filterOptions.countries = countries
    yield put(actions.setFilterOptions(filterOptions))
  }
}
export function* sendEmailPwd(action) {
  const { payload } = action
  yield put(actions.setLoading({ loading: true }))
  const $user = new UserService()
  const { success } = yield call($user.sendEmailNewPwd.bind($user), { payload })
  yield put(actions.setLoading({ loading: false }))
  if (success) {
    toasts.sendEmailPwd()
    return yield put(actions.sendEmailPwd({ sendEmailPwd: $user }))
  }
}
export function* create(action) {
  const { payload } = action

  yield put(actions.setLoading({ loading: true }))

  const $user = new UserService()
  const user = yield call($user.create.bind($user), { payload })

  yield put(actions.setLoading({ loading: false }))

  if (user.success) {
    toasts.created()
    return yield put(actions.setUserCreated({ userCreate: user.data }))
  } else if (user.data.code === 'DUPLICATED') {
    if (user.data.details === 'email') toasts.duplicatedEmail()
    else if (user.data.details === 'phone') toasts.duplicatedPhone()
    else toasts.duplicatedCPF()
  } else toasts.generalFail()
}

export function* fetchOne(action) {
  const { id } = action.payload
  yield put(actions.setLoading({ loading: true }))

  const $user = new UserService()
  const user = yield call($user.fetchOne.bind($user), id)
  if (user.success) {
    return yield put(
      actions.setUser({
        user: user.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* edit(action) {
  const { payload } = action

  yield put(actions.setLoading({ loading: true }))

  const $user = new UserService()
  const { success, data } = yield call($user.edit.bind($user), payload)

  yield put(actions.setLoading({ loading: false }))

  if (success) {
    toasts.edited()
    return yield put(actions.setUserEdit({ userEdit: data }))
  } else if (data.code === 'DUPLICATED') {
    if (data.details === 'email') toasts.duplicatedEmail()
    else if (data.details === 'phone') toasts.duplicatedPhone()
    else toasts.duplicatedCPF()
  } else toasts.generalFail()
}

export function* deleteUser(action) {
  const id = action.payload
  const $user = new UserService()

  const user = yield call($user.deleteUser.bind($user), { id })

  if (user.success) {
    toasts.deleted()
    return yield put(actions.fetchAll())
  } else toasts.generalFail()
}
