export const types = Object.freeze({
  FETCH_ALL: '@protocols/fetchAll',
  FETCH_ONE: '@protocols/fetchOne',
  FILTER_PROTOCOLS: '@protocols/filterProtocols',
  GET_PROTOCOLS_OPTIONS: '@protocols/getProtocolsOptions',
  SET_PROTOCOLS_OPTIONS: '@protocols/setProtocolsOptions',
  SET_LOADING: '@protocols/setLoading',
  SET_PROTOCOLS: '@protocols/setProtocols',
  SET_SELECTED_PROTOCOLS: '@protocols/setSelectedProtocols'
})

export function setLoading({ loading = false } = {}) {
  return { type: types.SET_LOADING, payload: { loading } }
}

export function fetchAll(filters = {}) {
  return { type: types.FETCH_ALL, payload: { filters } }
}

export function setProtocols({ certifications = [] } = {}) {
  return {
    type: types.SET_PROTOCOLS,
    payload: { certifications, loading: false }
  }
}

export function setSelectedProtocols({ selectedCertifications = null } = {}) {
  return {
    type: types.SET_PROTOCOLS,
    payload: { selectedCertifications }
  }
}

export function fetchOne(id: string) {
  return { type: types.FETCH_ONE, payload: { id } }
}

export function filterProtocols({ certificationsId, params }) {
  return {
    type: types.FILTER_PROTOCOLS,
    payload: { certificationsId, params }
  }
}

export function getProtocolsOptions() {
  return { type: types.GET_PROTOCOLS_OPTIONS }
}

export function setProtocolsOptions({ options = [] } = {}) {
  return {
    type: types.SET_PROTOCOLS_OPTIONS,
    payload: { options }
  }
}
