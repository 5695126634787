import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Wrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background: white;
  align-items: center;
  border-radius: 0.8rem;
  box-shadow: 0px 5px 25px #0000000d;
  margin-top: 8rem;
`
export const GridYear = styled(Grid)`
  display: flex;
  align-items: center;
`
export const WrapperInput = styled(Grid)``
export const Group = styled.div`
  display: flex;
  margin: 0 -1.1rem;
  padding: 0.7rem 1.6rem;
  button {
    display: inline-block;
    margin: 0 0.8rem;
    background-color: #f2f3f7;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.primary};
    border: 0 !important;
    border-radius: 0.8rem;
    &:active,
    :hover {
      background-color: ${({ theme }) => theme.colors.secondary} !important;
      color: white;
    }
  }
`
export const Title = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  font-weight: 600;
  padding-left: 2.4rem;
`

export const GridInput = styled(Grid)`
  margin-left: 4.8rem;
`
export const GridButtons = styled(Grid)`
  padding-bottom: 1.2rem;
  padding-right: 4.1rem;
  padding-top: 1.2rem;
`
