import { HTTP } from 'providers'
import { TraceabilityFilter } from 'types/Traceability.types'

export class BoxService extends HTTP {
  private endpoint = '/packing'

  public async getBoxes(params: TraceabilityFilter) {
    const boxes = await this.get({
      endpoint: this.endpoint,
      params
    })
    return boxes
  }

  public async getBoxesExported(params: TraceabilityFilter) {
    const { data } = await this.download({
      endpoint: `${this.endpoint}/export`,
      params
    })

    return data
  }
}
