import { Modal } from 'components/Modal'

import { ViewModuleListTable } from './ViewModuleListTable'
import { ViewModuleType } from 'types/ViewModule.types'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ModalViewModuleListProps = {
  modules: ViewModuleType[]
  onExit?: () => void
}

export function ModalViewModuleList({
  modules,
  onExit
}: ModalViewModuleListProps) {
  const { t } = useTranslation()
  const content = (
    <S.Wrapper>
      <S.GridHeader>
        <S.GridTitle item container xs={12}>
          <S.BoxTitle>{t('common:viewModules')}</S.BoxTitle>
        </S.GridTitle>
      </S.GridHeader>

      <S.Line />

      <S.GridTable>
        {modules.length > 0 && <ViewModuleListTable payload={modules} />}
      </S.GridTable>
    </S.Wrapper>
  )

  return (
    <Modal
      content={content}
      isActionsEnabled={false}
      width={70}
      onExit={onExit}
    />
  )
}
