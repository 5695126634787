/* eslint-disable react-hooks/exhaustive-deps */
import { BarChart } from 'components'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { all as allDrawer } from 'store/Sidebar/Sidebar.selector'
import { traceabilityTablesActions } from 'store/TraceabilityTables'
import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import * as S from './styled'
import { THEMES } from 'themes'
import { ThemeContext } from 'App'

export function NetWeightChart() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const monthsTranslate = t('common:months')
  const { currentTheme } = useContext(ThemeContext)

  const { trackedTonsByMonth, chartsLoading, filters } = useSelector(all)
  const { persistent } = useSelector(allDrawer)

  const trackedTonsSeries = {
    name: monthsTranslate,
    data: trackedTonsByMonth
  }

  useEffect(() => {
    if (filters.begin && filters.end && !chartsLoading) {
      dispatch(
        traceabilityTablesActions.getKpisCharts({
          ...filters,
          years: filters.years.length
            ? filters.years
            : [new Date().getFullYear()]
        })
      )
    }
  }, [dispatch, filters])

  return (
    <S.Wrapper item xs={12} md={12} container>
      <S.GridHeader>
        <S.BoxTitle>
          <S.Title>{t('common:netWeightTitle')}</S.Title>
        </S.BoxTitle>
      </S.GridHeader>
      <S.GridHorizontalDate item xs={12}>
        <BarChart
          color={THEMES[currentTheme].colors.chartBarColor}
          type="column"
          title=""
          subTitle=""
          series={trackedTonsSeries}
          tooltipSuffix={t('common:tonne')}
          isLoading={chartsLoading}
          width={persistent === false ? 1207 : 1008}
        />
      </S.GridHorizontalDate>
    </S.Wrapper>
  )
}
