import { IconButton, InputAdornment } from '@material-ui/core'
import { useState } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Button, Icon, ModalTermOfUse } from 'components'
import { YupValidator } from 'services/yupValidator.service'
import i18n from 'i18n'

import * as S from './styled'
import { toast } from 'react-toastify'
type Props = {
  onSubmit: (_payload: Record<string, any>) => void
}
type Payload = {
  confirmPassword: string
  password: string
  typeNotification?: string
  termOfUse?: string
}

const INITIAL_STATE = {
  password: '',
  confirmPassword: '',
  typeNotification: '0',
  termOfUse: ''
}
const validator = () => {
  return new YupValidator(
    Yup.object().shape({
      password: Yup.string().required().min(6).max(32),
      confirmPassword: Yup.string()
        .required()
        .oneOf(
          [Yup.ref('password')],
          i18n.t('modalUpdatePassword:beSameNewPassword')
        ),
      typeNotification: Yup.string().notRequired(),
      termOfUse: Yup.bool().required()
    })
  )
}
const validatorPWD = () => {
  return new YupValidator(
    Yup.object().shape({
      password: Yup.string().required().min(6).max(32),
      confirmPassword: Yup.string()
        .required()
        .oneOf(
          [Yup.ref('password')],
          i18n.t('modalUpdatePassword:beSameNewPassword')
        )
    })
  )
}

function VisibilityToggler({
  state,
  onClick
}: {
  state: boolean
  onClick: () => void
}) {
  return (
    <InputAdornment position="start">
      <IconButton onClick={onClick}>
        <Icon
          icon={state ? 'input-visible' : 'input-visible-not'}
          height={16}
          width={16}
        />
      </IconButton>
    </InputAdornment>
  )
}

export function ResetForm({ onSubmit }: Props) {
  const { t } = useTranslation()
  const [errors, setErrors] = useState<Payload>({ ...INITIAL_STATE })
  const [formData, setFormData] = useState<Payload>({ ...INITIAL_STATE })
  const [secretInputVisibility, setInputVisibility] = useState({
    0: false,
    1: false
  })
  const [modal, setModal] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  function onInput(key: string) {
    return function (val: string) {
      if (val === formData[key]) return
      if (errors[key]) setErrors({ ...errors, [key]: '' })
      setFormData({ ...formData, [key]: val })
    }
  }
  function onInputVisibilityToggle(key: number) {
    return function () {
      setInputVisibility(curr => ({
        ...secretInputVisibility,
        [key]: !curr[key]
      }))
    }
  }
  async function $onSubmit() {
    const [isValid, validationErrors] = await validator().validate(formData)
    if (!formData.termOfUse) {
      toast.error(t('authPages:acceptTerm'))
      setModal(true)
    } else if (isValid) {
      setModal(false)
      return onSubmit(formData)
    }
    setErrors(validationErrors as Payload)
  }
  function positionScroll() {
    const p = document.querySelector('#positionScroll')
    const position = p.getBoundingClientRect()
    if (position.bottom > 330) {
      return setScrolled(true)
    } else return setScrolled(false)
  }
  async function openModal() {
    const [isValid, validationErrors] = await validatorPWD().validate(formData)
    if (isValid) {
      setModal(true)
    } else {
      setErrors(validationErrors as Payload)
    }
  }
  function onExit() {
    setModal(false)
  }
  function handleCheck(name, value) {
    setErrors({ ...errors, [name]: '' })
    setFormData({ ...formData, [name]: value })
  }
  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.TitleMargin />
        <S.FormContainer>
          <S.FormInput
            error={Boolean(errors.password)}
            helperText={errors.password}
            iconLeft={<Icon icon="password-lock" height={16} width={16} />}
            iconRight={
              <VisibilityToggler
                state={secretInputVisibility[0]}
                onClick={onInputVisibilityToggle(0)}
              />
            }
            label={t('termOfUse:createPassword')}
            onInput={val => onInput('password')(val)}
            type={secretInputVisibility[0] ? 'text' : 'password'}
            value={formData.password}
          />
          <S.FormInput
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword}
            iconLeft={<Icon icon="password-lock" height={16} width={16} />}
            iconRight={
              <VisibilityToggler
                state={secretInputVisibility[1]}
                onClick={onInputVisibilityToggle(1)}
              />
            }
            label={t('termOfUse:confirmPassword')}
            onInput={val => onInput('confirmPassword')(val)}
            type={secretInputVisibility[1] ? 'text' : 'password'}
            value={formData.confirmPassword}
          />
        </S.FormContainer>
        <S.Details>
          <S.Check>
            {modal && (
              <ModalTermOfUse
                positionScroll={positionScroll}
                onSubmit={$onSubmit}
                scrolled={scrolled}
                onExit={onExit}
                onCheck={value => handleCheck('termOfUse', value)}
                value={formData.termOfUse}
              />
            )}
          </S.Check>
        </S.Details>
      </S.FullGrid>

      <Button variant="secondary" onClick={openModal}>
        {t('termOfUse:confirmRegistration')}
      </Button>
    </S.Wrapper>
  )
}
