import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { UserStorageService } from 'services'

import {
  Client,
  ClientEdit,
  ClientGroup,
  ClientGroupEdit,
  ClientGroupRegister,
  ClientRegister,
  ConfirmRegistration,
  General,
  Help,
  ConsolidationHomePage,
  Industry,
  IndustryEdit,
  IndustryGroup,
  IndustryGroupEdit,
  IndustryGroupRegister,
  IndustryRegister,
  Invoices,
  Login,
  ManageClientGroup,
  ManageIndustryGroup,
  PageNotFound,
  Profile,
  Property,
  PropertyEdit,
  PropertyRegister,
  RecoveryPwd,
  TraceabilityTables,
  User,
  UserEdit,
  UserRegister,
  NetWeight,
  // Conformity,
  SIFProduction,
  Properties,
  NewPassword,
  Logs
} from 'pages'
import { ViewModules } from 'pages/ViewModules'
import { ViewSubModules } from 'pages/ViewModules/ViewSubModule/ViewSubModule'
import { viewModuleActions } from 'store/ViewModule'
import { useDispatch } from 'react-redux'

function PrivateRoute({ component: Component, ...rest }: any) {
  const dispatch = useDispatch()
  let navpath: any = null
  let navComponent: any = null
  if (rest.path === '/panel') {
    const modulesOrSubModules = UserStorageService.getViewModule()
    if (modulesOrSubModules) {
      navpath = modulesOrSubModules.link
      if (modulesOrSubModules.componentModule) {
        dispatch(
          viewModuleActions.setSelectedModule({
            selectedModule: modulesOrSubModules.componentModule
          })
        )
      }
      navComponent = modulesOrSubModules.componentModule
        ? ViewSubModules
        : ConsolidationHomePage
    }
  }
  return (
    <Route
      {...rest}
      render={props => {
        if (UserStorageService.isAuthenticated()) {
          if (navpath && navComponent) {
            return (
              <Redirect
                to={{ pathname: navpath, state: { from: props.location } }}
              />
            )
          }

          return <Component props={props} />
        }

        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }}
    />
  )
}

export function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect from="/" to="/panel" exact />
        {/* <PrivateRoute path="/" component={ViewModules} exact /> */}
        <PrivateRoute path="/panel" component={ViewModules} exact />
        <PrivateRoute path="/panel/:module" component={ViewSubModules} exact />
        <PrivateRoute
          path="/panel/:module/:submodule"
          component={ConsolidationHomePage}
          exact
        />
        <PrivateRoute path="/ajuda" component={Help} exact />
        <PrivateRoute path="/cliente" component={Client} exact />
        <PrivateRoute path="/cliente/novo" component={ClientRegister} />
        <PrivateRoute path="/cliente/editar/:id" component={ClientEdit} />
        <PrivateRoute path="/geral" component={General} exact />
        <PrivateRoute
          path="/panel/:module/:submodule/properties"
          component={Properties}
          exact
        />
        <PrivateRoute
          path="/rastreabilidade"
          component={TraceabilityTables}
          exact
        />
        {/* Rota comentada por não utilizar essa tela atualemente, porém pode-se voltar com a mesma */}
        {/* <PrivateRoute path="/conformidade" component={Conformity} exact /> */}
        <PrivateRoute
          path="/panel/:module/:submodule/peso-liquido"
          component={NetWeight}
        />
        <PrivateRoute
          path="/panel/:module/:submodule/sif-producao"
          component={SIFProduction}
        />
        <PrivateRoute
          path="/panel/:module/:submodule/notas-fiscais"
          component={Invoices}
        />
        <PrivateRoute path="/painel-logs" component={Logs} exact />
        <PrivateRoute path="/industria" component={Industry} exact />
        <PrivateRoute path="/industria/novo" component={IndustryRegister} />
        <PrivateRoute path="/industria/editar/:id" component={IndustryEdit} />
        <PrivateRoute path="/perfil" component={Profile} />
        <PrivateRoute path="/grupo-cliente" component={ClientGroup} exact />
        <PrivateRoute
          path="/grupo-cliente/novo"
          component={ClientGroupRegister}
        />
        <PrivateRoute
          path="/grupo-cliente/editar/:id"
          component={ClientGroupEdit}
        />
        <PrivateRoute
          path="/grupo-cliente/gerenciar/:id"
          component={ManageClientGroup}
        />
        <PrivateRoute path="/grupo-industria" component={IndustryGroup} exact />
        <PrivateRoute
          path="/grupo-industria/novo"
          component={IndustryGroupRegister}
        />
        <PrivateRoute
          path="/grupo-industria/editar/:id"
          component={IndustryGroupEdit}
        />
        <PrivateRoute
          path="/grupo-industria/gerenciar/:id"
          component={ManageIndustryGroup}
        />

        <PrivateRoute path="/propriedade" component={Property} exact />
        <PrivateRoute path="/propriedade/novo" component={PropertyRegister} />
        <PrivateRoute path="/propriedade/editar/:id" component={PropertyEdit} />
        <PrivateRoute path="/usuario" component={User} exact />
        <PrivateRoute path="/usuario/novo" component={UserRegister} />
        <PrivateRoute path="/usuario/editar/:id" component={UserEdit} />

        <Route path="/login" component={Login} exact />
        <Route path="/esqueci-minha-senha" component={RecoveryPwd} />
        <Route
          path="/confirmacao-de-cadastro"
          component={ConfirmRegistration}
        />
        <Route path="/cadastro-nova-senha" component={NewPassword} />
        <Route path="/404" component={PageNotFound} />

        <Redirect from="*" to="/404" />
      </Switch>
    </BrowserRouter>
  )
}
