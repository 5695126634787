import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

type MoreFilterProps = {
  toggle: () => void
  border: 'yes' | 'not'
  arrowAction?: 'open' | 'close'
}

export function MoreFilter({
  toggle,
  border = 'yes',
  arrowAction = 'open'
}: MoreFilterProps) {
  const { t } = useTranslation()

  return (
    <S.ButtonAlign>
      <Button
        border={border}
        variant="filter"
        size="sizeFiler"
        onClick={() => toggle()}
      >
        <S.ButtonContent
          data-tip={
            arrowAction === 'open'
              ? t('dashboard:moreFilter')
              : t('dashboard:lessFilter')
          }
          data-for="tip"
        >
          <S.Arrow>
            <Icon
              className="icon-arrow"
              icon={arrowAction === 'close' ? 'filter-close' : 'filter-open'}
              width={12}
              height={12}
            />
          </S.Arrow>
        </S.ButtonContent>
      </Button>
      <ReactTooltip type="light" effect="solid" id="tip" />
    </S.ButtonAlign>
  )
}
