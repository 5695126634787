import { ColumnsProps } from 'components/Table/Table'
import i18n from 'i18n'

export const columns = (): ColumnsProps[] => [
  {
    align: 'right',
    fixed: true,
    name: 'sifproducao',
    title: i18n.t('common:SIF')
  },
  {
    align: 'right',
    fixed: true,
    name: 'pesoliquido',
    title: i18n.t('common:netWeightDelivered')
  }
]
