import { HTTP } from 'providers'
import { TraceabilityFilter } from 'types/Traceability.types'

export class RawMaterialBoxService extends HTTP {
  private endpoint = '/rawMaterialPacking'

  public async getBoxes(params: TraceabilityFilter) {
    const boxes = await this.get({
      endpoint: this.endpoint,
      params
    })
    return boxes
  }

  public async getBoxesExport(params: TraceabilityFilter) {
    const { data } = await this.get({
      endpoint: `${this.endpoint}/export`,
      params
    })

    return data
  }
}
