import i18n from 'i18n'

const countries = {
  AFG: {
    'pt-BR': 'Afeganistão',
    en: 'Afghanistan',
    es: 'Afganistán',
    ch: '阿富汗'
  },
  ALB: { 'pt-BR': 'Albânia', en: 'Albania', es: 'Albania', ch: '阿尔巴尼亚' },
  DZA: { 'pt-BR': 'Argélia', en: 'Algeria', es: 'Argelia', ch: '阿尔及利亚' },
  AND: { 'pt-BR': 'Andorra', en: 'Andorra', es: 'Andorra', ch: '安道尔' },
  AGO: { 'pt-BR': 'Angola', en: 'Angola', es: 'Angola', ch: '安哥拉' },
  ATG: {
    'pt-BR': 'Antígua e Barbuda',
    en: 'Antigua and Barbuda',
    es: 'Antigua y Barbuda',
    ch: '安提瓜和巴布达'
  },
  ARG: { 'pt-BR': 'Argentina', en: 'Argentina', es: 'Argentina', ch: '阿根廷' },
  ARM: { 'pt-BR': 'Armênia', en: 'Armenia', es: 'Armenia', ch: '亚美尼亚' },
  AUS: {
    'pt-BR': 'Austrália',
    en: 'Australia',
    es: 'Australia',
    ch: '澳大利亚'
  },
  AUT: { 'pt-BR': 'Áustria', en: 'Austria', es: 'Austria', ch: '奥地利' },
  AZE: {
    'pt-BR': 'Azerbaijão',
    en: 'Azerbaijan',
    es: 'Azerbaiyán',
    ch: '阿塞拜疆'
  },
  BHS: { 'pt-BR': 'Bahamas', en: 'Bahamas', es: 'Bahamas', ch: '巴哈马' },
  BHR: { 'pt-BR': 'Bahrein', en: 'Bahrain', es: 'Bahrein', ch: '巴林' },
  BGD: {
    'pt-BR': 'Bangladesh',
    en: 'Bangladesh',
    es: 'Bangladesh',
    ch: '孟加拉国'
  },
  BRB: { 'pt-BR': 'Barbados', en: 'Barbados', es: 'Barbados', ch: '巴巴多斯' },
  BLR: { 'pt-BR': 'Belarus', en: 'Belarus', es: 'Bielorrusia', ch: '白俄罗斯' },
  BEL: { 'pt-BR': 'Bélgica', en: 'Belgium', es: 'Bélgica', ch: '比利时' },
  BLZ: { 'pt-BR': 'Belize', en: 'Belize', es: 'Belice', ch: '伯利兹' },
  BEN: { 'pt-BR': 'Benin', en: 'Benin', es: 'Benín', ch: '贝宁' },
  BMU: { 'pt-BR': 'Bermudas', en: 'Bermuda', es: 'Bermudas', ch: '百慕大' },
  BTN: { 'pt-BR': 'Butão', en: 'Bhutan', es: 'Bután', ch: '不丹' },
  BOL: { 'pt-BR': 'Bolívia', en: 'Bolivia', es: 'Bolivia', ch: '玻利维亚' },
  BIH: {
    'pt-BR': 'Bósnia e Herzegovina',
    en: 'Bosnia and Herzegovina',
    es: 'Bosnia y Herzegovina',
    ch: '波斯尼亚和黑塞哥维那'
  },
  BWA: { 'pt-BR': 'Botsuana', en: 'Botswana', es: 'Botsuana', ch: '博茨瓦纳' },
  BRA: { 'pt-BR': 'Brasil', en: 'Brazil', es: 'Brasil', ch: '巴西' },
  BRN: { 'pt-BR': 'Brunei', en: 'Brunei', es: 'Brunéi', ch: '文莱' },
  BGR: { 'pt-BR': 'Bulgária', en: 'Bulgaria', es: 'Bulgaria', ch: '保加利亚' },
  BFA: {
    'pt-BR': 'Burkina Faso',
    en: 'Burkina Faso',
    es: 'Burkina Faso',
    ch: '布基纳法索'
  },
  BDI: { 'pt-BR': 'Burundi', en: 'Burundi', es: 'Burundi', ch: '布隆迪' },
  KHM: { 'pt-BR': 'Camboja', en: 'Cambodia', es: 'Camboya', ch: '柬埔寨' },
  CMR: { 'pt-BR': 'Camarões', en: 'Cameroon', es: 'Camerún', ch: '喀麦隆' },
  CAN: { 'pt-BR': 'Canadá', en: 'Canada', es: 'Canadá', ch: '加拿大' },
  CPV: {
    'pt-BR': 'Cabo Verde',
    en: 'Cape Verde',
    es: 'Cabo Verde',
    ch: '佛得角'
  },
  CAF: {
    'pt-BR': 'República Centro-Africana',
    en: 'Central African Republic',
    es: 'República Centroafricana',
    ch: '中非共和国'
  },
  TCD: { 'pt-BR': 'Chade', en: 'Chad', es: 'Chad', ch: '乍得' },
  CHL: { 'pt-BR': 'Chile', en: 'Chile', es: 'Chile', ch: '智利' },
  CHN: { 'pt-BR': 'China', en: 'China', es: 'China', ch: '中国' },
  COL: { 'pt-BR': 'Colômbia', en: 'Colombia', es: 'Colombia', ch: '哥伦比亚' },
  COM: { 'pt-BR': 'Comores', en: 'Comoros', es: 'Comoras', ch: '科摩罗' },
  COG: { 'pt-BR': 'Congo', en: 'Congo', es: 'Congo', ch: '刚果' },
  CRI: {
    'pt-BR': 'Costa Rica',
    en: 'Costa Rica',
    es: 'Costa Rica',
    ch: '哥斯达黎加'
  },
  CIV: {
    'pt-BR': 'Costa do Marfim',
    en: "Côte d'Ivoire",
    es: 'Costa de Marfil',
    ch: '科特迪瓦'
  },
  HRV: { 'pt-BR': 'Croácia', en: 'Croatia', es: 'Croacia', ch: '克罗地亚' },
  CUB: { 'pt-BR': 'Cuba', en: 'Cuba', es: 'Cuba', ch: '古巴' },
  CYP: { 'pt-BR': 'Chipre', en: 'Cyprus', es: 'Chipre', ch: '塞浦路斯' },
  CZE: {
    'pt-BR': 'República Tcheca',
    en: 'Czech Republic',
    es: 'República Checa',
    ch: '捷克共和国'
  },
  COD: {
    'pt-BR': 'República Democrática do Congo',
    en: 'Democratic Republic of the Congo',
    es: 'República Democrática del Congo',
    ch: '刚果民主共和国'
  },
  DNK: { 'pt-BR': 'Dinamarca', en: 'Denmark', es: 'Dinamarca', ch: '丹麦' },
  DJI: { 'pt-BR': 'Djibuti', en: 'Djibouti', es: 'Yibuti', ch: '吉布提' },
  DMA: { 'pt-BR': 'Dominica', en: 'Dominica', es: 'Dominica', ch: '多米尼加' },
  DOM: {
    'pt-BR': 'República Dominicana',
    en: 'Dominican Republic',
    es: 'República Dominicana',
    ch: '多米尼加共和国'
  },
  ECU: { 'pt-BR': 'Equador', en: 'Ecuador', es: 'Ecuador', ch: '厄瓜多尔' },
  EGY: { 'pt-BR': 'Egito', en: 'Egypt', es: 'Egipto', ch: '埃及' },
  SLV: {
    'pt-BR': 'El Salvador',
    en: 'El Salvador',
    es: 'El Salvador',
    ch: '萨尔瓦多'
  },
  GNQ: {
    'pt-BR': 'Guiné Equatorial',
    en: 'Equatorial Guinea',
    es: 'Guinea Ecuatorial',
    ch: '赤道几内亚'
  },
  ERI: { 'pt-BR': 'Eritreia', en: 'Eritrea', es: 'Eritrea', ch: '厄立特里亚' },
  EST: { 'pt-BR': 'Estônia', en: 'Estonia', es: 'Estonia', ch: '爱沙尼亚' },
  ETH: { 'pt-BR': 'Etiópia', en: 'Ethiopia', es: 'Etiopía', ch: '埃塞俄比亚' },
  FJI: { 'pt-BR': 'Fiji', en: 'Fiji', es: 'Fiyi', ch: '斐济' },
  FIN: { 'pt-BR': 'Finlândia', en: 'Finland', es: 'Finlandia', ch: '芬兰' },
  FRA: { 'pt-BR': 'França', en: 'France', es: 'Francia', ch: '法国' },
  GAB: { 'pt-BR': 'Gabão', en: 'Gabon', es: 'Gabón', ch: '加蓬' },
  GMB: { 'pt-BR': 'Gâmbia', en: 'Gambia', es: 'Gambia', ch: '冈比亚' },
  GEO: { 'pt-BR': 'Geórgia', en: 'Georgia', es: 'Georgia', ch: '格鲁吉亚' },
  DEU: { 'pt-BR': 'Alemanha', en: 'Germany', es: 'Alemania', ch: '德国' },
  GHA: { 'pt-BR': 'Gana', en: 'Ghana', es: 'Ghana', ch: '加纳' },
  GRC: { 'pt-BR': 'Grécia', en: 'Greece', es: 'Grecia', ch: '希腊' },
  GRD: { 'pt-BR': 'Granada', en: 'Grenada', es: 'Granada', ch: '格林纳达' },
  GTM: {
    'pt-BR': 'Guatemala',
    en: 'Guatemala',
    es: 'Guatemala',
    ch: '危地马拉'
  },
  GIN: { 'pt-BR': 'Guiné', en: 'Guinea', es: 'Guinea', ch: '几内亚' },
  HTI: { 'pt-BR': 'Haiti', en: 'Haiti', es: 'Haití', ch: '海地' },
  HND: { 'pt-BR': 'Honduras', en: 'Honduras', es: 'Honduras', ch: '洪都拉斯' },
  HUN: { 'pt-BR': 'Hungria', en: 'Hungary', es: 'Hungría', ch: '匈牙利' },
  ISL: { 'pt-BR': 'Islândia', en: 'Iceland', es: 'Islandia', ch: '冰岛' },
  IND: { 'pt-BR': 'Índia', en: 'India', es: 'India', ch: '印度' },
  IDN: {
    'pt-BR': 'Indonésia',
    en: 'Indonesia',
    es: 'Indonesia',
    ch: '印度尼西亚'
  },
  IRN: { 'pt-BR': 'Irã', en: 'Iran', es: 'Irán', ch: '伊朗' },
  IRQ: { 'pt-BR': 'Iraque', en: 'Iraq', es: 'Irak', ch: '伊拉克' },
  IRL: { 'pt-BR': 'Irlanda', en: 'Ireland', es: 'Irlanda', ch: '爱尔兰' },
  ISR: { 'pt-BR': 'Israel', en: 'Israel', es: 'Israel', ch: '以色列' },
  ITA: { 'pt-BR': 'Itália', en: 'Italy', es: 'Italia', ch: '意大利' },
  JAM: { 'pt-BR': 'Jamaica', en: 'Jamaica', es: 'Jamaica', ch: '牙买加' },
  JPN: { 'pt-BR': 'Japão', en: 'Japan', es: 'Japón', ch: '日本' },
  JOR: { 'pt-BR': 'Jordânia', en: 'Jordan', es: 'Jordania', ch: '约旦' },
  KAZ: {
    'pt-BR': 'Cazaquistão',
    en: 'Kazakhstan',
    es: 'Kazajistán',
    ch: '哈萨克斯坦'
  },
  KEN: { 'pt-BR': 'Quênia', en: 'Kenya', es: 'Kenia', ch: '肯尼亚' },
  KIR: { 'pt-BR': 'Kiribati', en: 'Kiribati', es: 'Kiribati', ch: '基里巴斯' },
  KWT: { 'pt-BR': 'Kuwait', en: 'Kuwait', es: 'Kuwait', ch: '科威特' },
  KGZ: {
    'pt-BR': 'Quirguistão',
    en: 'Kyrgyzstan',
    es: 'Kirguistán',
    ch: '吉尔吉斯斯坦'
  },
  LAO: { 'pt-BR': 'Laos', en: 'Laos', es: 'Laos', ch: '老挝' },
  LVA: { 'pt-BR': 'Letônia', en: 'Latvia', es: 'Letonia', ch: '拉脱维亚' },
  LBN: { 'pt-BR': 'Líbano', en: 'Lebanon', es: 'Líbano', ch: '黎巴嫩' },
  LSO: { 'pt-BR': 'Lesoto', en: 'Lesotho', es: 'Lesoto', ch: '莱索托' },
  LBR: { 'pt-BR': 'Libéria', en: 'Liberia', es: 'Liberia', ch: '利比里亚' },
  LBY: { 'pt-BR': 'Líbia', en: 'Libya', es: 'Libia', ch: '利比亚' },
  LIE: {
    'pt-BR': 'Liechtenstein',
    en: 'Liechtenstein',
    es: 'Liechtenstein',
    ch: '列支敦士登'
  },
  LTU: { 'pt-BR': 'Lituânia', en: 'Lithuania', es: 'Lituania', ch: '立陶宛' },
  LUX: {
    'pt-BR': 'Luxemburgo',
    en: 'Luxembourg',
    es: 'Luxemburgo',
    ch: '卢森堡'
  },
  MKD: { 'pt-BR': 'Macedônia', en: 'Macedonia', es: 'Macedonia', ch: '马其顿' },
  MDG: {
    'pt-BR': 'Madagascar',
    en: 'Madagascar',
    es: 'Madagascar',
    ch: '马达加斯加'
  },
  MWI: { 'pt-BR': 'Malawi', en: 'Malawi', es: 'Malawi', ch: '马拉维' },
  MYS: { 'pt-BR': 'Malásia', en: 'Malaysia', es: 'Malasia', ch: '马来西亚' },
  MDV: { 'pt-BR': 'Maldivas', en: 'Maldives', es: 'Maldivas', ch: '马尔代夫' },
  MLI: { 'pt-BR': 'Mali', en: 'Mali', es: 'Mali', ch: '马里' },
  MLT: { 'pt-BR': 'Malta', en: 'Malta', es: 'Malta', ch: '马耳他' },
  MHL: {
    'pt-BR': 'Ilhas Marshall',
    en: 'Marshall Islands',
    es: 'Islas Marshall',
    ch: '马绍尔群岛'
  },
  MRT: {
    'pt-BR': 'Mauritânia',
    en: 'Mauritania',
    es: 'Mauritania',
    ch: '毛里塔尼亚'
  },
  MUS: { 'pt-BR': 'Maurício', en: 'Mauritius', es: 'Mauricio', ch: '毛里求斯' },
  MEX: { 'pt-BR': 'México', en: 'Mexico', es: 'México', ch: '墨西哥' },
  FSM: {
    'pt-BR': 'Micronésia',
    en: 'Micronesia',
    es: 'Micronesia',
    ch: '密克罗尼西亚'
  },
  MDA: { 'pt-BR': 'Moldávia', en: 'Moldova', es: 'Moldavia', ch: '摩尔多瓦' },
  MCO: { 'pt-BR': 'Mônaco', en: 'Monaco', es: 'Mónaco', ch: '摩纳哥' },
  MNG: { 'pt-BR': 'Mongólia', en: 'Mongolia', es: 'Mongolia', ch: '蒙古' },
  MNE: {
    'pt-BR': 'Montenegro',
    en: 'Montenegro',
    es: 'Montenegro',
    ch: '黑山'
  },
  MAR: { 'pt-BR': 'Marrocos', en: 'Morocco', es: 'Marruecos', ch: '摩洛哥' },
  MOZ: {
    'pt-BR': 'Moçambique',
    en: 'Mozambique',
    es: 'Mozambique',
    ch: '莫桑比克'
  },
  MMR: { 'pt-BR': 'Myanmar', en: 'Myanmar', es: 'Myanmar', ch: '缅甸' },
  NAM: { 'pt-BR': 'Namíbia', en: 'Namibia', es: 'Namibia', ch: '纳米比亚' },
  NRU: { 'pt-BR': 'Nauru', en: 'Nauru', es: 'Nauru', ch: '瑙鲁' },
  NPL: { 'pt-BR': 'Nepal', en: 'Nepal', es: 'Nepal', ch: '尼泊尔' },
  NLD: {
    'pt-BR': 'Holanda',
    en: 'Netherlands',
    es: 'Países Bajos',
    ch: '荷兰'
  },
  NZL: {
    'pt-BR': 'Nova Zelândia',
    en: 'New Zealand',
    es: 'Nueva Zelanda',
    ch: '新西兰'
  },
  NIC: {
    'pt-BR': 'Nicarágua',
    en: 'Nicaragua',
    es: 'Nicaragua',
    ch: '尼加拉瓜'
  },
  NER: { 'pt-BR': 'Níger', en: 'Niger', es: 'Níger', ch: '尼日尔' },
  NGA: { 'pt-BR': 'Nigéria', en: 'Nigeria', es: 'Nigeria', ch: '尼日利亚' },
  NOR: { 'pt-BR': 'Noruega', en: 'Norway', es: 'Noruega', ch: '挪威' },
  OMN: { 'pt-BR': 'Omã', en: 'Oman', es: 'Omán', ch: '阿曼' },
  PAK: { 'pt-BR': 'Paquistão', en: 'Pakistan', es: 'Pakistán', ch: '巴基斯坦' },
  PLW: { 'pt-BR': 'Palau', en: 'Palau', es: 'Palau', ch: '帕劳' },
  PAN: { 'pt-BR': 'Panamá', en: 'Panama', es: 'Panamá', ch: '巴拿马' },
  PNG: {
    'pt-BR': 'Papua Nova Guiné',
    en: 'Papua New Guinea',
    es: 'Papúa Nueva Guinea',
    ch: '巴布亚新几内亚'
  },
  PRY: { 'pt-BR': 'Paraguai', en: 'Paraguay', es: 'Paraguay', ch: '巴拉圭' },
  PER: { 'pt-BR': 'Peru', en: 'Peru', es: 'Perú', ch: '秘鲁' },
  PHL: {
    'pt-BR': 'Filipinas',
    en: 'Philippines',
    es: 'Filipinas',
    ch: '菲律宾'
  },
  POL: { 'pt-BR': 'Polônia', en: 'Poland', es: 'Polonia', ch: '波兰' },
  PRT: { 'pt-BR': 'Portugal', en: 'Portugal', es: 'Portugal', ch: '葡萄牙' },
  QAT: { 'pt-BR': 'Catar', en: 'Qatar', es: 'Catar', ch: '卡塔尔' },
  ROU: { 'pt-BR': 'Romênia', en: 'Romania', es: 'Rumania', ch: '罗马尼亚' },
  RUS: { 'pt-BR': 'Rússia', en: 'Russia', es: 'Rusia', ch: '俄罗斯' },
  RWA: { 'pt-BR': 'Ruanda', en: 'Rwanda', es: 'Ruanda', ch: '卢旺达' },
  KNA: {
    'pt-BR': 'São Cristóvão e Nevis',
    en: 'Saint Kitts and Nevis',
    es: 'San Cristóbal y Nieves',
    ch: '圣基茨和尼维斯'
  },
  LCA: {
    'pt-BR': 'Santa Lúcia',
    en: 'Saint Lucia',
    es: 'Santa Lucía',
    ch: '圣卢西亚'
  },
  VCT: {
    'pt-BR': 'São Vicente e Granadinas',
    en: 'Saint Vincent and the Grenadines',
    es: 'San Vicente y las Granadinas',
    ch: '圣文森特和格林纳丁斯'
  },
  WSM: { 'pt-BR': 'Samoa', en: 'Samoa', es: 'Samoa', ch: '萨摩亚' },
  SMR: {
    'pt-BR': 'San Marino',
    en: 'San Marino',
    es: 'San Marino',
    ch: '圣马力诺'
  },
  STP: {
    'pt-BR': 'São Tomé e Príncipe',
    en: 'Sao Tome and Principe',
    es: 'Santo Tomé y Príncipe',
    ch: '圣多美和普林西比'
  },
  SAU: {
    'pt-BR': 'Arábia Saudita',
    en: 'Saudi Arabia',
    es: 'Arabia Saudita',
    ch: '沙特阿拉伯'
  },
  SEN: { 'pt-BR': 'Senegal', en: 'Senegal', es: 'Senegal', ch: '塞内加尔' },
  SRB: { 'pt-BR': 'Sérvia', en: 'Serbia', es: 'Serbia', ch: '塞尔维亚' },
  SYC: {
    'pt-BR': 'Seychelles',
    en: 'Seychelles',
    es: 'Seychelles',
    ch: '塞舌尔'
  },
  SLE: {
    'pt-BR': 'Serra Leoa',
    en: 'Sierra Leone',
    es: 'Sierra Leona',
    ch: '塞拉利昂'
  },
  SGP: { 'pt-BR': 'Singapura', en: 'Singapore', es: 'Singapur', ch: '新加坡' },
  SVK: {
    'pt-BR': 'Eslováquia',
    en: 'Slovakia',
    es: 'Eslovaquia',
    ch: '斯洛伐克'
  },
  SVN: {
    'pt-BR': 'Eslovênia',
    en: 'Slovenia',
    es: 'Eslovenia',
    ch: '斯洛文尼亚'
  },
  SLB: {
    'pt-BR': 'Ilhas Salomão',
    en: 'Solomon Islands',
    es: 'Islas Salomón',
    ch: '所罗门群岛'
  },
  SOM: { 'pt-BR': 'Somália', en: 'Somalia', es: 'Somalia', ch: '索马里' },
  ZAF: {
    'pt-BR': 'África do Sul',
    en: 'South Africa',
    es: 'Sudáfrica',
    ch: '南非'
  },
  KOR: {
    'pt-BR': 'Coreia do Sul',
    en: 'South Korea',
    es: 'Corea del Sur',
    ch: '韩国'
  },
  SSD: {
    'pt-BR': 'Sudão do Sul',
    en: 'South Sudan',
    es: 'Sudán del Sur',
    ch: '南苏丹'
  },
  ESP: { 'pt-BR': 'Espanha', en: 'Spain', es: 'España', ch: '西班牙' },
  LKA: {
    'pt-BR': 'Sri Lanka',
    en: 'Sri Lanka',
    es: 'Sri Lanka',
    ch: '斯里兰卡'
  },
  SDN: { 'pt-BR': 'Sudão', en: 'Sudan', es: 'Sudán', ch: '苏丹' },
  SUR: { 'pt-BR': 'Suriname', en: 'Suriname', es: 'Surinam', ch: '苏里南' },
  SWE: { 'pt-BR': 'Suécia', en: 'Sweden', es: 'Suecia', ch: '瑞典' },
  CHE: { 'pt-BR': 'Suíça', en: 'Switzerland', es: 'Suiza', ch: '瑞士' },
  SYR: { 'pt-BR': 'Síria', en: 'Syria', es: 'Siria', ch: '叙利亚' },
  TJK: {
    'pt-BR': 'Tajiquistão',
    en: 'Tajikistan',
    es: 'Tayikistán',
    ch: '塔吉克斯坦'
  },
  THA: { 'pt-BR': 'Tailândia', en: 'Thailand', es: 'Tailandia', ch: '泰国' },
  TLS: {
    'pt-BR': 'Timor-Leste',
    en: 'Timor-Leste',
    es: 'Timor Oriental',
    ch: '东帝汶'
  },
  TGO: { 'pt-BR': 'Togo', en: 'Togo', es: 'Togo', ch: '多哥' },
  TON: { 'pt-BR': 'Tonga', en: 'Tonga', es: 'Tonga', ch: '汤加' },
  TTO: {
    'pt-BR': 'Trinidad e Tobago',
    en: 'Trinidad and Tobago',
    es: 'Trinidad y Tobago',
    ch: '特立尼达和多巴哥'
  },
  TUN: { 'pt-BR': 'Tunísia', en: 'Tunisia', es: 'Túnez', ch: '突尼斯' },
  TUR: { 'pt-BR': 'Turquia', en: 'Turkey', es: 'Turquía', ch: '土耳其' },
  TKM: {
    'pt-BR': 'Turcomenistão',
    en: 'Turkmenistan',
    es: 'Turkmenistán',
    ch: '土库曼斯坦'
  },
  TUV: { 'pt-BR': 'Tuvalu', en: 'Tuvalu', es: 'Tuvalu', ch: '图瓦卢' },
  UGA: { 'pt-BR': 'Uganda', en: 'Uganda', es: 'Uganda', ch: '乌干达' },
  UKR: { 'pt-BR': 'Ucrânia', en: 'Ukraine', es: 'Ucrania', ch: '乌克兰' },
  ARE: {
    'pt-BR': 'Emirados Árabes Unidos',
    en: 'United Arab Emirates',
    es: 'Emiratos Árabes Unidos',
    ch: '阿拉伯联合酋长国'
  },
  GBR: {
    'pt-BR': 'Reino Unido',
    en: 'United Kingdom',
    es: 'Reino Unido',
    ch: '英国'
  },
  USA: {
    'pt-BR': 'Estados Unidos',
    en: 'United States',
    es: 'Estados Unidos',
    ch: '美国'
  },
  URY: { 'pt-BR': 'Uruguai', en: 'Uruguay', es: 'Uruguay', ch: '乌拉圭' },
  UZB: {
    'pt-BR': 'Uzbequistão',
    en: 'Uzbekistan',
    es: 'Uzbekistán',
    ch: '乌兹别克斯坦'
  },
  VUT: { 'pt-BR': 'Vanuatu', en: 'Vanuatu', es: 'Vanuatu', ch: '瓦努阿图' },
  VEN: {
    'pt-BR': 'Venezuela',
    en: 'Venezuela',
    es: 'Venezuela',
    ch: '委内瑞拉'
  },
  VNM: { 'pt-BR': 'Vietnã', en: 'Vietnam', es: 'Vietnam', ch: '越南' },
  YEM: { 'pt-BR': 'Iêmen', en: 'Yemen', es: 'Yemen', ch: '也门' },
  ZMB: { 'pt-BR': 'Zâmbia', en: 'Zambia', es: 'Zambia', ch: '赞比亚' },
  ZWE: { 'pt-BR': 'Zimbábue', en: 'Zimbabwe', es: 'Zimbabue', ch: '津巴布韦' }
}

export function getCountriesListByLanguage(language: string = i18n.language) {
  return Object.keys(countries).map(code => {
    const name = countries[code][language] || countries[code].en
    return { label: name, value: code }
  })
}

export function getCountryName(code: string, language: string = i18n.language) {
  return countries[code][language] || countries[code].en
}
