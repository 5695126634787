import { ColumnsProps } from 'components/Table/Table'
import i18n from 'i18n'

export const columns = (): ColumnsProps[] => [
  {
    align: 'right',
    fixed: true,
    name: 'order',
    title: i18n.t('tables:orderNumber')
  },
  {
    align: 'right',
    fixed: true,
    name: 'cargo',
    title: i18n.t('tables:charge')
  },
  {
    align: 'left',
    fixed: true,
    name: 'status',
    title: i18n.t('common:traceability')
  },
  {
    align: 'right',
    fixed: true,
    name: 'barcode',
    title: i18n.t('tables:barCode')
  },
  {
    align: 'right',
    fixed: true,
    name: 'productcode',
    title: i18n.t('tables:productCode')
  },
  {
    fixed: true,
    name: 'productdescription',
    title: i18n.t('tables:productDescription')
  },
  {
    align: 'right',
    name: 'slaughterindustrycnpj',
    title: i18n.t('tables:CNPJIndustrySlaughter')
  },
  {
    align: 'right',
    name: 'boningoutindustrycnpj',
    title: i18n.t('tables:CNPJDeboningIndustry')
  },
  {
    align: 'right',
    name: 'slaughterdat',
    title: i18n.t('tables:slaughterDate')
  },
  {
    align: 'right',
    name: 'producedat',
    title: i18n.t('common:productionDate')
  },
  {
    align: 'right',
    name: 'expiresat',
    title: i18n.t('common:expirationDate')
  },
  {
    align: 'right',
    name: 'rawweight',
    title: i18n.t('tables:grossWeight')
  },
  {
    align: 'right',
    name: 'clearweight',
    title: i18n.t('tables:netWeight')
  },
  {
    align: 'right',
    name: 'qtt',
    title: i18n.t('common:qtyPieces')
  },
  {
    align: 'right',
    name: 'slaughtersif',
    title: i18n.t('tables:slaughterSIF')
  },
  {
    align: 'right',
    name: 'processsif',
    title: i18n.t('tables:processSIF')
  },
  {
    align: 'right',
    name: 'boningoutsif',
    title: i18n.t('tables:boningSIF')
  },
  {
    align: 'right',
    name: 'pallet',
    title: i18n.t('common:palletCode')
  }
]
