/* eslint-disable no-unused-vars */
import jwt from 'jwt-decode'
import { GoTo } from 'utils/goto'

type UserData = {
  id: string
  user: Record<string, any>
  profile: { avatar: string; name: string }
  tag: string
  path: string
}

const getDataFromLocalStorage = () => {
  const userFromLocalStorage = localStorage.getItem('@user')
  if (userFromLocalStorage) {
    const user = JSON.parse(userFromLocalStorage)
    const userJWT: Object = jwt(user.token)
    const profile = { name: user.profile.name, avatar: user.avatar }
    return { ...userJWT, user, profile } as UserData
  }
  return null
}

/**
 * verificação das opções de seleção de módulos
 * verifica a quantidade de módulos e submódulos do usuário
 * caso tenha apenas um módulo verifica se o módulo possui submódulos
 * caso tenha apenas um submódulo retorna o link do submódulo
 * se houver mais de um submódulo retorna o link do módulo
 */
const getViewModule = () => {
  const userFromLocalStorage = localStorage.getItem('@user')
  let link = ''
  let componentModule = ''
  if (userFromLocalStorage) {
    const user = JSON.parse(userFromLocalStorage)
    const module = getModule(user.modules)
    const subModule = module ? getModule(module.subModules) : false
    if (subModule) {
      link = subModule.link
      return { link, componentModule }
    } else if (module) {
      componentModule = module
      link = module.link
      return { link, componentModule }
    }
    return false
  }
}

const cleanUserData = () => {
  localStorage.removeItem('@user')
  localStorage.removeItem('@viewModule')
  localStorage.removeItem('@viewModuleTheme')
}

function getModule(array) {
  const LENGTH = 1
  if (Array.isArray(array) && array.length === LENGTH) {
    return array.pop() // Utiliza o método pop() para obter o elemento do array
  }
  return false
}

function getUserCertification() {
  const userFromLocalStorage = localStorage.getItem('@user')
  if (userFromLocalStorage) {
    const user = JSON.parse(userFromLocalStorage)
    return user.certifications?.length > 0
  }
  return false
}
export const UserStorageService = {
  isAuthenticated: () => !!localStorage.getItem('@user'),
  isViewer: user => user.tag === 'viewer',
  signIn: user => localStorage.setItem('@user', user),
  signOut: () => cleanUserData(),
  getToken: () => localStorage.getItem('@user'),
  getUserDateForLangDropdown: () => getDataFromLocalStorage(),
  getViewModule: () => getViewModule(),
  setViewModule: moduleId => localStorage.setItem('@viewModule', moduleId),
  getModule: () => localStorage.getItem('@viewModule'),
  setViewModuleTheme: theme => localStorage.setItem('@viewModuleTheme', theme),
  getViewModuleTheme: () => localStorage.getItem('@viewModuleTheme'),
  setViewModuleType: type => localStorage.setItem('@viewModuleType', type),
  getViewModuleType: () => localStorage.getItem('@viewModuleType'),
  getCertification: () => getUserCertification(),
  getUserData: (): UserData => {
    const isHaveUserDate = getDataFromLocalStorage()
    if (isHaveUserDate) return isHaveUserDate
    GoTo.login()
  }
}
