// const urlAtual = window.location.href

// const URL_JBS = 'b2b-jbs.foodsafe.info'
// const URL_SWIFT_PROD = 'b2b-swift.foodsafe.info'
// const URL_SWIFT_TSI = 'b2b-swift.ecotrace.technology'

// const urlList = {
//   [URL_JBS]: 'jbsFriboi',
//   [URL_SWIFT_TSI]: 'swift',
//   [URL_SWIFT_PROD]: 'swift'
// }

export const THEMES_NAME = {
  jbsFriboi: 'jbsFriboi',
  swift: 'swift',
  sampco: 'sampco',
  standard: 'standard',
  friboiProcessados: 'friboiProcessados',
  friboiInnatura: 'friboiInnatura'
}

// const checkUrlForTheme = () => {
// for (const url in urlList) {
//   if (urlAtual.includes(url)) return urlList[url]
// }
// return 'jbsFriboi'
// }

export const LINE_TYPES = {
  INNATURA: 'innatura',
  PROCESSED: 'processed',
  OFFAL: 'offal'
}

export const LINE_TYPE_BY_THEME = {
  [THEMES_NAME.friboiInnatura]: LINE_TYPES.INNATURA,
  [THEMES_NAME.friboiProcessados]: LINE_TYPES.PROCESSED,
  [THEMES_NAME.jbsFriboi]: LINE_TYPES.INNATURA,
  [THEMES_NAME.swift]: LINE_TYPES.PROCESSED,
  [THEMES_NAME.sampco]: LINE_TYPES.PROCESSED,
  [THEMES_NAME.standard]: LINE_TYPES.INNATURA
}

export const getLineTypeByTheme = (theme: string) => {
  return LINE_TYPE_BY_THEME[theme]
}

export default {
  // appTheme: checkUrlForTheme()
  appTheme: 'jbsFriboi'
}
