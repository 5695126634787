import { useState, ChangeEvent, useEffect } from 'react'
import TabContext from '@material-ui/lab/TabContext'
import ReactTooltip from 'react-tooltip'
import { all as allOrders } from 'store/Order/Order.selector'
import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { useTranslation } from 'react-i18next'

import { USER_ROLES } from 'constants/user.constants'
import { UserStorageService } from 'services'

import {
  BoxesList,
  FoodSafety,
  OrderTable,
  PropertiesTable,
  RawMaterialList
} from 'pages/TraceabilityTables'

import * as S from './styled'
import { useDispatch, useSelector } from 'react-redux'
import { HeaderBlock, RadioButton } from 'components'
import {
  INITIAL_STATE_ERRORS,
  productionTypeRadio
} from 'constants/traceability.constants'
import { traceabilityTablesActions } from 'store/TraceabilityTables'
// import { setFilters } from 'store/TraceabilityTables/TraceabilityTables.actions'

export function Tabs() {
  const { t } = useTranslation()
  const { ordersKeys } = useSelector(allOrders)
  const { tableLoading } = useSelector(all)

  const { filters } = useSelector(all)

  const [filter, setFilter] = useState({ ...filters })
  const [errors, setErrors] = useState({ ...INITIAL_STATE_ERRORS })

  const [value, setValue] = useState('orders')

  const [ordersKeysState, setOrdersKeysState] = useState(ordersKeys)

  const dispatch = useDispatch()

  useEffect(() => {
    setOrdersKeysState(ordersKeys)
    if (!ordersKeys.length) setValue('orders')
  }, [ordersKeys])

  const handleChange = (event: ChangeEvent<{}>, newValue: string) => {
    if (ordersKeysState.length && !tableLoading) setValue(newValue)
  }

  function handleSelect(value: any, name: string) {
    setErrors({ ...errors, [name]: '' })
    if (value.value) {
      setFilter({ ...filters, [name]: value.value })
    } else {
      setFilter({ ...filter, [name]: value })
    }
    dispatch(
      traceabilityTablesActions.setFilters({
        ...filters,
        productionType: value
      })
    )
    // dispatch(
    //   setFilters({
    //     productionType: value,
    //     ...filters
    //   })
    // )
  }

  const { user } = UserStorageService.getUserData()
  const isAdmin = user.tag === USER_ROLES.admin

  // useEffect(() => {
  //   dispatch(traceabilityTablesActions.setFilters(filters))
  // }, [dispatch, filters])

  useEffect(() => {
    setFilter(filters)
  }, [filters])

  return (
    <S.Wrapper item xs={12} data-testid="tabs">
      <S.HeaderContainer>
        <HeaderBlock title={t('common:productionType')} />
      </S.HeaderContainer>
      <S.GridFilter container alignItems="center" style={{ marginBottom: 32 }}>
        <S.GridInput item xs={12} sm={4} md={6}>
          <RadioButton
            error={Boolean(errors.productionType)}
            helperText={errors.productionType}
            options={productionTypeRadio()}
            label=""
            onSelected={value => handleSelect(value, 'productionType')}
            value={filters.productionType}
          />
        </S.GridInput>
      </S.GridFilter>
      <TabContext value={value}>
        <S.TabList onChange={handleChange} aria-label="simple tabs example">
          <S.Tab
            data-tip="tooltip_loading"
            label={t('common:invoice')}
            value="orders"
            isDisable={tableLoading}
          />
          <S.Tab
            data-tip="tooltip"
            label={t('tables:itensInvoice')}
            value="boxesList"
            isDisable={!ordersKeysState.length || tableLoading}
          />
          <S.Tab
            data-tip="tooltip"
            show={isAdmin}
            label={t('tables:rawMaterial')}
            value="rawMaterialList"
            isDisable={!ordersKeysState.length || tableLoading}
          />
          <S.Tab
            data-tip="tooltip"
            label={t('tables:foodSafety')}
            value="foodSafety"
            isDisable={!ordersKeysState.length || tableLoading}
          />
          <S.Tab
            data-tip="tooltip"
            label={t('common:properties')}
            value="properties"
            isDisable={!ordersKeysState.length || tableLoading}
          />
          <ReactTooltip
            type="light"
            effect="solid"
            id="tooltip"
            disable={ordersKeysState.length > 0 || tableLoading}
          >
            <span>{t('common:selectAnInvoice')}</span>
          </ReactTooltip>
          <ReactTooltip
            type="light"
            effect="solid"
            id="tooltip"
            disable={!tableLoading}
          >
            <span>{t('common:waitDataLoad')}</span>
          </ReactTooltip>
        </S.TabList>
        <S.Panel value="orders">
          <OrderTable />
        </S.Panel>
        <S.Panel value="boxesList">
          <BoxesList />
        </S.Panel>
        <S.Panel value="rawMaterialList">
          <RawMaterialList />
        </S.Panel>
        <S.Panel value="foodSafety">
          <FoodSafety />
        </S.Panel>
        <S.Panel value="properties">
          <PropertiesTable />
        </S.Panel>
      </TabContext>
    </S.Wrapper>
  )
}
