import styled, { css } from 'styled-components'

type BackProps = {
  isOpen: boolean
}

type ModalProps = {
  isOpen: boolean
  width?: number | string
  height?: number | string
}
export const Background = styled.div<BackProps>`
  ${({ isOpen }) => css`
    z-index: 202 !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: ${isOpen ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    ${isOpen && modalModifiers.open()}
    ${!isOpen && modalModifiers.close()}
  `}
`
export const Wrapper = styled.div<ModalProps>`
  ${({ isOpen }) => css`
    display: ${isOpen ? 'block' : 'none'};
    width: content;
    position: relative;
    background: white;
  `}
`

const modalModifiers = {
  open: () => css`
    opacity: 1;
  `,

  close: () => css`
    opacity: 0;
    pointer-events: none;
  `
}

export const Modal = styled.div<ModalProps>`
  ${({ isOpen, height, width }) => css`
    background: white;
    border: none;
    border-radius: 2rem;
    box-shadow: 0px 30px 63px #00000052;
    display: flex;
    transition: opacity '0.3s ease-in-out';
    width: ${width ? `${width}rem` : 'auto'};
    height: ${height ? `${height}rem` : 'auto'};

    z-index: 201;
    .title {
      color: ${({ theme }) => theme.colors.primary};
      font-size: 1.8rem;
      font-weight: 600;
    }
    ${isOpen && modalModifiers.open()}
    ${!isOpen && modalModifiers.close()}
  `}
`

export const Close = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    position: absolute;
    right: 0;
    top: 0;
    padding: 2.4rem;
    cursor: pointer;
    text-align: end;
  `}
`

export const Template = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const Content = styled.div.attrs(() => {})`
  padding: 2.4rem;
`

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3.2rem 1.6rem 0;
`

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  align-self: flex-end;
  button {
    margin-left: 1.6rem;
  }
`

export const Line = styled.div`
  margin: 0 0.8rem;
  width: auto;
  height: 0.2rem;
  background-color: #f2f3f7;
`
