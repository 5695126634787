import { ColumnsProps } from 'components/Table/Table'
import i18n from 'i18n'

export const columnsInvoice = (): ColumnsProps[] => [
  {
    align: 'right',
    fixed: true,
    name: 'order',
    title: i18n.t('tables:orderNumber')
  },
  {
    align: 'right',
    fixed: true,
    name: 'cargo',
    title: i18n.t('tables:charge')
  },
  {
    align: 'right',
    fixed: true,
    name: 'boughtat',
    title: i18n.t('tables:purchaseDate')
  },
  {
    align: 'right',
    name: 'slaughtedat',
    title: i18n.t('tables:slaughterDate')
  },
  {
    align: 'right',
    name: 'slaughterindustrycnpj',
    title: i18n.t('tables:CNPJIndustrySlaughter')
  },
  {
    align: 'right',
    name: 'producerdoc',
    title: i18n.t('tables:producerDocument')
  },
  {
    align: 'right',
    name: 'producerie',
    title: i18n.t('tables:IE')
  },
  {
    name: 'producername',
    title: i18n.t('common:productor')
  },
  {
    name: 'propname',
    title: i18n.t('tables:corporateNameOwnership')
  },
  {
    align: 'right',
    name: 'propcar',
    title: i18n.t('tables:carNumber')
  },
  {
    align: 'right',
    name: 'proplat',
    title: i18n.t('common:latitude')
  },
  {
    align: 'right',
    name: 'proplng',
    title: i18n.t('common:longitude')
  },
  {
    name: 'propcidade',
    title: i18n.t('common:city')
  },
  {
    name: 'propestado',
    title: i18n.t('common:state')
  },
  {
    align: 'center',
    fixed: false,
    name: 'flagCfv',
    title: i18n.t('common:cfv')
  },
  {
    align: 'center',
    fixed: false,
    name: 'analyze',
    title: i18n.t('common:analyze')
  }
]
