/* eslint-disable react-hooks/exhaustive-deps */
import { CheckButton } from 'components/CheckButton'
import { MoreFilter } from 'components/MoreFilter'
import { USER_ROLES } from 'constants/user.constants'
import * as S from './styled'
import { useTranslation } from 'react-i18next'
import { UserStorageService } from 'services'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserYearsForFilter } from 'utils/GetUserYearFilter'
import { traceabilityTablesActions } from 'store/TraceabilityTables'
import { all as allTraceability } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { isEqual } from 'lodash'

interface IYearButtonFilter {
  toggleMoreFilter(): void
}

export function InlineFilter({ toggleMoreFilter }: IYearButtonFilter) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { user } = UserStorageService.getUserData()
  const { filters } = useSelector(allTraceability)
  const INITIAL_YEARS_STATE = filters.years.length
    ? filters.years
    : [new Date().getFullYear()]
  const [yearsState, setYearsState] = useState(INITIAL_YEARS_STATE)

  const years = getUserYearsForFilter(user)

  function toggleValues(value: number) {
    setYearsState([value])
  }

  function setBeginEndDate() {
    const begin = new Date(yearsState[0], 0, 1)
    const atualYear = new Date().getFullYear()
    const end = yearsState.includes(atualYear)
      ? new Date()
      : new Date(yearsState[yearsState.length - 1], 11, 31, 23, 59, 59)
    return { begin, end }
  }

  useEffect(() => {
    if (!isEqual(filters.years, yearsState)) {
      const { begin, end } = setBeginEndDate()
      dispatch(
        traceabilityTablesActions.setFilters({
          ...filters,
          begin,
          end,
          years: yearsState
        })
      )
    }
  }, [yearsState])

  return (
    <S.Section>
      <S.Box>
        <S.Title>{t('dashboard:year')}</S.Title>
        <S.Group>
          {years.map(({ labelAdmin, labelView, valueAdmin, valueView }) => {
            const yearValue =
              user.tag === USER_ROLES.admin ? valueAdmin : valueView
            return (
              <CheckButton
                data-testid="yearButton"
                defaultValue={
                  user.tag === USER_ROLES.admin
                    ? yearsState.includes(valueAdmin)
                    : yearsState.includes(valueView)
                }
                onToggle={() => toggleValues(yearValue)}
                disableChange={
                  yearsState.length === 1 && yearsState.includes(valueAdmin)
                }
                key={valueAdmin}
              >
                <span className="years-btn">
                  {user.tag === USER_ROLES.admin ? labelAdmin : labelView}
                </span>
              </CheckButton>
            )
          })}
        </S.Group>
      </S.Box>
      <MoreFilter border="not" toggle={toggleMoreFilter} />
    </S.Section>
  )
}
