import { useSelector } from 'react-redux'
import { isVisible } from 'store/Spinner/Spinner.selector'
import { Modal } from 'components/Modal'
import { ModalContent } from './ModalContent'

export type ModalProps = {
  order: any
  onExitHandler?: () => void
}

export function ModalRawMaterialBoxes({ order, onExitHandler }: ModalProps) {
  const spinnerIsVisible = useSelector(isVisible)

  const content = (
    <ModalContent order={order} spinnerIsVisible={spinnerIsVisible} />
  )

  return (
    <Modal
      content={content}
      isActionsEnabled={false}
      width={150}
      onExit={onExitHandler}
    />
  )
}
