import { TraceabilityFilter } from 'types/Traceability.types'

export const types = Object.freeze({
  GET_RAW_MATERIAL_BOXES: '@rawMaterialBox/getRawMaterialBoxes',
  GET_RAW_MATERIAL_BOXES_EXPORT: '@rawMaterialBox/getRawMaterialBoxesExport',
  SET_FILTERS_RAW_MATERIAL_BOXES: '@rawMaterialBox/setFiltersRawMaterialBox',
  SET_RAW_MATERIAL_BOXES: '@rawMaterialBox/setRawMaterialBoxes',
  CLEAN_RAW_MATERIAL_BOXES: '@rawMaterialBox/cleanRawMaterialBoxes'
})

export function getRawMaterialBoxes(
  ordersKeys: string,
  orderSku: string,
  filterRawMaterialBox?: TraceabilityFilter
) {
  return {
    type: types.GET_RAW_MATERIAL_BOXES,
    payload: { ordersKeys, orderSku, filterRawMaterialBox }
  }
}

export function getRawMaterialBoxesExport(
  ordersKeys: string,
  orderSku: string,
  filterRawMaterialBox?: TraceabilityFilter
) {
  return {
    type: types.GET_RAW_MATERIAL_BOXES_EXPORT,
    payload: { ordersKeys, orderSku, filterRawMaterialBox }
  }
}

export function setFilterRawMaterial(filterRawMaterialBox: TraceabilityFilter) {
  return {
    type: types.SET_FILTERS_RAW_MATERIAL_BOXES,
    payload: { filterRawMaterialBox }
  }
}

export function cleanRawMaterialBoxes({
  rawMaterialBox = [],
  success = true
} = {}) {
  return {
    type: types.CLEAN_RAW_MATERIAL_BOXES,
    payload: { rawMaterialBox, success }
  }
}

export function setRawMaterialBoxes({ rawMaterialBox = {} } = {}) {
  return { type: types.SET_RAW_MATERIAL_BOXES, payload: { rawMaterialBox } }
}
