import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const GridHeader = styled(Grid)`
  display: inline-flexbox;
  padding: 0.5rem 0 0.7rem 0;
`

export const GridTitle = styled(Grid)`
  display: block;
  padding: 0.5rem 0 1rem 0;
  max-width: fit-content;
`

export const BoxDescription = styled(Grid)`
  display: block;
  padding: 0.5rem 0 1rem 4rem;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const NfNr = styled.div`
  margin: 0 0 0.5rem 0;
  font-weight: 600;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.gray};
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 1rem;
  width: 15rem;
`
export const BoxLine = styled.p`
  width: 7.7rem;
  border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
`

export const GridFilter = styled(Grid)`
  display: flex;
  margin: 0 -0.8rem;
  width: calc(100% + 16px);
  flex-direction: row;
`

export const FullGrid = styled(Grid)`
  padding: 2.4rem;
`

export const GridButtons = styled(Grid)`
  align-self: center;
  text-align: end;
  button + button {
    margin-left: 0.8rem;
  }
`

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ButtonText = styled.span`
  margin-left: 12px;
`

export const GridInput = styled(Grid)``

export const GridTable = styled.div``

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: normal;
`
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 0.4rem;
  padding-top: 3.3rem;
  padding-bottom: 2rem;
`

export const GridSubtitle = styled.div``
export const BoxSubtitle = styled.h2`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary};
  padding-bottom: 1.4rem;
`

export const Line = styled.div`
  width: auto;
  height: 0.2rem;
  background-color: #f2f3f7;
  margin-bottom: 12px;
`

export const NoContent = styled.div`
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
`
