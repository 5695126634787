import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const Wrapper = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 25px #0000000d;
  border-radius: 1.2rem;
  padding: 2.4rem;
  margin: 0rem 0;
  width: 100%;
`

export const GridFilter = styled(Grid)`
  display: flex;
  flex-direction: row;
`

export const GridInput = styled(Grid)``

export const GridButtons = styled(Grid)`
  align-self: center;
  text-align: end;
  button + button {
    margin-left: 0.8rem;
  }
`

export const GridToggleButton = styled(Grid)`
  align-self: center;
  width: 100%;
  text-align: end;
  justify-content: flex-end;
`
