import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { omit } from 'lodash'

import { viewMode } from 'config'

import { Input } from 'components/Input'
import { InputAutocomplete } from 'components/InputAutocomplete'
import { InputCpfCnpj } from 'components/InputCpfCnpj'
import { InputPhone } from 'components/InputPhone'
import { InputSelect } from 'components/InputSelect'
import { Button } from 'components/Button'

import { YupValidator } from 'services/yupValidator.service'
import {
  CreateUserFormSchema,
  EditUserFormSchema
} from 'store/User/User.validator'

import { clientGroupActions } from 'store/ClientGroup'
import { industryGroupActions } from 'store/IndustryGroup'
import { userActions } from 'store/User'
import { viewModuleActions } from 'store/ViewModule'
import { protocolsActions } from 'store/Protocols'

import { all as userSelector } from 'store/User/User.selector'
import { all as clientGroupSelector } from 'store/ClientGroup/ClientGroup.selector'
import { all as industryGroupSelector } from 'store/IndustryGroup/IndustryGroup.selector'
import { all as userRoleSelector } from 'store/UserRole/UserRole.selector'
import { all as viewModuleSelector } from 'store/ViewModule/ViewModule.selector'
import { all as certificationsSelector } from 'store/Protocols/Protocols.selector'

import { userRoleActions } from 'store/UserRole'

import {
  ERRORS_INITIAL_STATE,
  INITIAL_STATE,
  USER_ROLES
} from 'constants/user.constants'
import { AVAILABLE_LANGUAGES } from 'constants/languages.constants'
import { useTranslation } from 'react-i18next'

import * as S from './styled'
import { getCountriesListByLanguage } from 'utils/Countries'

interface ParamTypes {
  id: string
}

export function UserForm() {
  const { t } = useTranslation()
  const { id } = useParams<ParamTypes>()

  const [form, setForm] = useState({ ...INITIAL_STATE })
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })
  const [roleTag, setRoleTag] = useState('')
  const [selectedCountry, setselectedCountry] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [typeNotification, setTypeNotification] = useState('')

  const { user, userEdit, userCreate } = useSelector(userSelector)
  const { options: clientGroupOptions } = useSelector(clientGroupSelector)
  const { options: industryGroupOptions } = useSelector(industryGroupSelector)
  const { options: viewModuleOptions } = useSelector(viewModuleSelector)
  const { options: certificationOptions } = useSelector(certificationsSelector)

  const { userRole } = useSelector(userRoleSelector)

  const dispatch = useDispatch()
  const { goBack } = useHistory()
  useEffect(() => {
    if (id) {
      dispatch(userActions.fetchOne(id))
    }
  }, [dispatch, id])
  useEffect(() => {
    if (viewMode.industry) {
      dispatch(clientGroupActions.getClientGroupOptions())
      dispatch(industryGroupActions.getIdustryGroupOptions())
      dispatch(viewModuleActions.getViewModulesOptions())
      dispatch(protocolsActions.getProtocolsOptions())
    }
  }, [dispatch])
  useEffect(() => {
    if (userCreate.id || userEdit.id) {
      goBack()
      dispatch(userActions.cleanUser())
    }
  }, [userCreate, userEdit, goBack, dispatch])
  useEffect(() => {
    dispatch(userRoleActions.fetchAll())
  }, [dispatch])

  useEffect(() => {
    if (Object.keys(user).length) {
      const _form = {
        ...omit(user, ['groups', 'role', 'roleTag'])
      } as typeof INITIAL_STATE
      setRoleTag(user.roleTag)
      setselectedCountry(user.country)

      _form.companies = []
      _form.retails = []
      _form.modules = []
      _form.certifications = []

      if (viewMode.industry) {
        user.groups.forEach(group => {
          _form.retails.push({ label: group.name, value: group.id })
        })
        user.groupsIndustry.forEach(group => {
          _form.companies.push({ label: group.name, value: group.id })
        })
        user.modules.forEach(module => {
          _form.modules.push({ label: module.description, value: module.id })
        })
        user.certifications.forEach(certification => {
          _form.certifications.push({
            label: certification.name,
            value: certification.id
          })
        })
      }

      setErrors({ ...ERRORS_INITIAL_STATE })
      setForm({ ..._form })
    } else {
      // setForm({  })
    }
  }, [user])

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  function handleAutocomplete(label, value) {
    setForm({ ...form, [label]: value })
  }
  function handleSelected(name: string) {
    return function handle(value) {
      setErrors({ ...errors, [name]: '' })
      setForm({ ...form, [name]: value.value })
    }
  }
  function handleSelectedCountry(name: string) {
    return function handle(value) {
      setselectedCountry(value.value)
      setErrors({ ...errors, [name]: '' })
      setForm({ ...form, [name]: value.value })
    }
  }

  function handleSelectedRole(name: string) {
    return function handle(value) {
      setRoleTag(value.tag)
      setErrors({ ...errors, [name]: '' })
      setForm({ ...form, [name]: value.value })
    }
  }
  async function submit() {
    setErrors({ ...ERRORS_INITIAL_STATE })
    const [isValid, validationError] = await new YupValidator(
      id ? EditUserFormSchema() : CreateUserFormSchema()
    ).validate({ ...form, roleTag })
    if (!isValid) {
      return setErrors(validationError as typeof ERRORS_INITIAL_STATE)
    }
    const payload = { ...form }

    const _companies = []
    const _retails = []
    const _modules = []
    const _certifications = []

    if (roleTag === USER_ROLES.viewer) {
      payload.companies.forEach(company => _companies.push(company.value))
      payload.retails.forEach(retail => _retails.push(retail.value))
      payload.modules.forEach(module => _modules.push(module.value))
      payload.certifications.forEach(certification =>
        _certifications.push(certification.value)
      )
    }

    payload.companies = _companies
    payload.retails = _retails
    payload.modules = _modules
    payload.certifications = _certifications

    if (viewMode.retail) {
      delete payload.companies
      delete payload.retails
    }

    dispatch(
      id
        ? userActions.editUser({ ...payload, id })
        : userActions.createUser(payload)
    )
  }

  function onExit() {
    goBack()
    dispatch(userActions.cleanUser())
  }

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <div style={{ marginBottom: 16 }}>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>{t('users:basicInformations')}</S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>
          <S.GridFilter container spacing={2}>
            <S.GridInput item sm={12} md={4}>
              <InputSelect
                error={Boolean(errors.country)}
                fullWidth
                helperText={errors.country}
                label={t('common:country')}
                onSelected={handleSelectedCountry('country')}
                optionLabel="label"
                options={getCountriesListByLanguage()}
                optionValue="value"
                value={form.country}
              />
            </S.GridInput>

            <S.GridInput item sm={12} md={4}>
              <Input
                error={Boolean(errors.firstName)}
                fullWidth
                helperText={errors.firstName}
                label={t('profile:firstName')}
                onInput={value => handleInput(value, 'firstName')}
                value={form.firstName}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              <Input
                error={Boolean(errors.lastName)}
                fullWidth
                helperText={errors.lastName}
                label={t('profile:lastName')}
                onInput={value => handleInput(value, 'lastName')}
                value={form.lastName}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              {selectedCountry === 'BRA' && (
                <InputCpfCnpj
                  error={Boolean(errors.CPF)}
                  fullWidth
                  label={t('profile:identifyNumber')}
                  helperText={errors.CPF}
                  onInput={value => handleInput(value, 'CPF')}
                  type="cpf"
                  value={form.CPF}
                />
              )}
              {selectedCountry !== 'BRA' && (
                <Input
                  error={Boolean(errors.CPF)}
                  fullWidth
                  label={t('profile:identifyNumber')}
                  helperText={errors.CPF}
                  onInput={value => handleInput(value, 'CPF')}
                  value={form.CPF}
                />
              )}
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              <Input
                error={Boolean(errors.email)}
                fullWidth
                helperText={errors.email}
                label={t('common:email')}
                onInput={value => handleInput(value, 'email')}
                value={form.email}
              />
            </S.GridInput>
          </S.GridFilter>
        </div>
        <div style={{ marginBottom: 16 }}>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>{t('users:extraInformation')}</S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>
          <S.GridFilter container spacing={2}>
            <S.GridInput item sm={12} md={4}>
              {selectedCountry === 'BRA' && (
                <InputPhone
                  error={Boolean(errors.phone)}
                  fullWidth
                  helperText={errors.phone}
                  label={t('profile:completePhoneNumber')}
                  onInput={value => handleInput(value, 'phone')}
                  value={form.phone}
                />
              )}
              {selectedCountry !== 'BRA' && (
                <Input
                  error={Boolean(errors.phone)}
                  fullWidth
                  helperText={errors.phone}
                  label={t('profile:completePhoneNumber')}
                  onInput={value => handleInput(value, 'phone')}
                  value={form.phone}
                />
              )}
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              {selectedCountry === 'BRA' && (
                <InputPhone
                  error={Boolean(errors.phoneRecovery)}
                  fullWidth
                  helperText={errors.phoneRecovery}
                  label={t('profile:contactPhone')}
                  onInput={value => handleInput(value, 'phoneRecovery')}
                  value={form.phoneRecovery}
                />
              )}
              {selectedCountry !== 'BRA' && (
                <Input
                  error={Boolean(errors.phoneRecovery)}
                  fullWidth
                  helperText={errors.phoneRecovery}
                  label={t('profile:contactPhone')}
                  onInput={value => handleInput(value, 'phoneRecovery')}
                  value={form.phoneRecovery}
                />
              )}
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              <InputSelect
                error={Boolean(errors.lang)}
                fullWidth
                helperText={errors.lang}
                label={t('profile:language')}
                onSelected={handleSelected('lang')}
                optionLabel="title"
                options={AVAILABLE_LANGUAGES}
                optionValue="value"
                value={form.lang}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              <Input
                error={Boolean(errors.department)}
                fullWidth
                helperText={errors.department}
                label={t('profile:department')}
                onInput={value => handleInput(value, 'department')}
                value={form.department}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              {userRole.length ? (
                <InputSelect
                  error={Boolean(errors.roleId)}
                  fullWidth
                  helperText={errors.roleId}
                  label={t('common:userType')}
                  onSelected={handleSelectedRole('roleId')}
                  optionLabel="label"
                  options={userRole}
                  optionValue="value"
                  value={form.roleId}
                />
              ) : null}
            </S.GridInput>
          </S.GridFilter>
        </div>

        {viewMode.industry && roleTag === USER_ROLES.viewer && (
          <>
            <div style={{ marginBottom: 16 }}>
              <S.GridHeader>
                <S.GridTitle item container xs={12}>
                  <S.BoxTitle>{t('users:viewModuleAccess')}</S.BoxTitle>
                </S.GridTitle>
              </S.GridHeader>
              <S.GridFilter container spacing={2}>
                {viewModuleOptions.length ? (
                  <InputAutocomplete
                    error={Boolean(errors.modules)}
                    defaultValue={form.modules}
                    fullWidth
                    helperText={errors.modules}
                    onSelected={val => handleAutocomplete('modules', val)}
                    optionLabel="label"
                    options={viewModuleOptions}
                  />
                ) : null}
              </S.GridFilter>
            </div>
            <div style={{ marginBottom: 16 }}>
              <S.GridHeader>
                <S.GridTitle item container xs={12}>
                  <S.BoxTitle>{t('users:industryGroupAccess')}</S.BoxTitle>
                </S.GridTitle>
              </S.GridHeader>
              <S.GridFilter container spacing={2}>
                {industryGroupOptions.length ? (
                  <InputAutocomplete
                    error={Boolean(errors.companies)}
                    defaultValue={form.companies}
                    fullWidth
                    helperText={errors.companies}
                    onSelected={val => handleAutocomplete('companies', val)}
                    optionLabel="label"
                    options={industryGroupOptions}
                  />
                ) : null}
              </S.GridFilter>
            </div>
            <div style={{ marginBottom: 16 }}>
              <S.GridHeader>
                <S.GridTitle item container xs={12}>
                  <S.BoxTitle>{t('users:customerGroupAccess')}</S.BoxTitle>
                </S.GridTitle>
              </S.GridHeader>
              <S.GridFilter container spacing={2}>
                {clientGroupOptions.length ? (
                  <InputAutocomplete
                    error={Boolean(errors.retails)}
                    defaultValue={form.retails}
                    fullWidth
                    helperText={errors.retails}
                    onSelected={val => handleAutocomplete('retails', val)}
                    optionLabel="label"
                    options={clientGroupOptions}
                  />
                ) : null}
              </S.GridFilter>
            </div>
            <div style={{ marginBottom: 16 }}>
              <S.GridHeader>
                <S.GridTitle item container xs={12}>
                  <S.BoxTitle>{t('users:certificationAccess')}</S.BoxTitle>
                </S.GridTitle>
              </S.GridHeader>
              <S.GridFilter container spacing={2}>
                {certificationOptions.length ? (
                  <InputAutocomplete
                    error={Boolean(errors.certifications)}
                    defaultValue={form.certifications}
                    fullWidth
                    helperText={errors.certifications}
                    onSelected={val =>
                      handleAutocomplete('certifications', val)
                    }
                    optionLabel="label"
                    options={certificationOptions}
                  />
                ) : null}
              </S.GridFilter>
            </div>
          </>
        )}
        {/* {id && (
          <>
            <S.GridHeader>
              <S.GridTitle item container xs={12}>
                <S.BoxTitle>{t('users:typeNotification:title')}</S.BoxTitle>
              </S.GridTitle>
            </S.GridHeader>
            <S.GridNotification container>
              <S.GridCheck item sm={12} md={4}>
                <InputSelect
                  error={Boolean(errors.typeNotification)}
                  fullWidth
                  helperText={errors.typeNotification}
                  label={t('profile:typeNotify')}
                  onSelected={handleSelected('typeNotification')}
                  optionLabel="title"
                  options={TYPES_NOTIFICATION}
                  optionValue="value"
                  value={form.typeNotification}
                />
              </S.GridCheck>
            </S.GridNotification>
          </>
        )} */}
      </S.FullGrid>

      <S.GridButtons item xs={12}>
        <Button variant="default" onClick={onExit} size="medium">
          <p>{t('common:cancel')}</p>
        </Button>
        <Button variant="secondary" onClick={submit} size="medium">
          <p>{t('common:save')}</p>
        </Button>
      </S.GridButtons>
    </S.Wrapper>
  )
}
