import { Icon } from '../Icon'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { all } from 'store/Breadcrumbs'

import * as S from './styled'

export type BreadcrumbsProps = {
  crumbs: any[] | null
}

const replacebleLinkks = {
  '/panel/friboi': 'Friboi',
  '/panel/swift': 'Swift',
  '/panel/sampco': 'Sampco',
  '/panel/friboi/in-natura': 'In Natura - ',
  '/panel/friboi/processados': 'Processados - '
}
function replaceLabelPrefix(link: string) {
  return replacebleLinkks[link] || ''
}

export function Breadcrumbs({ crumbs }: BreadcrumbsProps) {
  const { suffix } = useSelector(all)

  if (crumbs.length === 0) return null

  if (crumbs.length === 1) {
    return (
      <S.Wrapper>
        <S.Container>
          <Icon icon={crumbs[0].icon} width={16} height={16} />
          <S.Label>{crumbs[0].labelPage}</S.Label>
        </S.Container>
      </S.Wrapper>
    )
  } else {
    return (
      <S.Wrapper>
        {crumbs.map(({ icon, labelPage, link }, key) => {
          const keyValidator = 2
          if (key === 0) return null
          if (key + 1 === crumbs.length) {
            return (
              <S.Container key={key}>
                {key >= keyValidator && <S.Divider>/</S.Divider>}
                {icon && key === keyValidator - 1 && (
                  <Icon icon={icon} width={16} height={16} />
                )}
                <S.Label>
                  {replaceLabelPrefix(link)}
                  {labelPage}
                </S.Label>
              </S.Container>
            )
          } else {
            return (
              <Link key={key} to={link}>
                <S.Container>
                  {key >= keyValidator && <S.Divider>/</S.Divider>}
                  {icon && key === keyValidator - 1 && (
                    <Icon icon={icon} width={16} height={16} />
                  )}
                  <S.Label>
                    {replaceLabelPrefix(link)}
                    {labelPage}
                  </S.Label>
                </S.Container>
              </Link>
            )
          }
        })}
        {Boolean(suffix.length) && (
          <>
            <S.SuffixSeparator> - </S.SuffixSeparator>
            {suffix.map(({ label, value }) => (
              <S.Suffix key={label}>{`${label}: ${value}`}</S.Suffix>
            ))}
          </>
        )}
      </S.Wrapper>
    )
  }
}
