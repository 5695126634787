import { Icon } from 'components/Icon'
import * as S from './styled'

export type CardProps = {
  title: string
  image: string
  redirect: string
  variant:
    | 'blue'
    | 'lightBlue'
    | 'gray'
    | 'darkBlue'
    | 'red'
    | 'darkYellow'
    | 'grayLight'
  minWidth?: number
  className?: string
  isLoading: boolean
  icon: string
}

export function ModuleCard({
  title,
  variant,
  minWidth = 1,
  className,
  isLoading = false,
  icon,
  image
}: CardProps) {
  return (
    // <Link to={redirect}>
    <S.Card isLoading={isLoading}>
      <S.Quantity
        minWidth={minWidth}
        className={`${variant}  ${className}`}
        textLength={String(title).length}
      >
        {image ? <S.Image src={image} alt={title} /> : title}
        {/* {isLoading ? <Loading loadingColorWhite /> : title} */}
      </S.Quantity>
      <S.Section>
        <S.Service>{/* <S.Title>{title}</S.Title> */}</S.Service>
        <S.Icon>
          <Icon icon={icon} height={46} width={16}></Icon>
        </S.Icon>
      </S.Section>
    </S.Card>
    // </Link>
  )
}
