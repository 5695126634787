import { Base } from 'layouts'
import { Tabs } from '.'
import { InvoicesChart } from './CardCharts'
import { InlineInvoiceFilter } from './InlineFilter'
import { GeneralFilters } from 'components/GeneralFilters'
import * as S from './styled'

export function Invoices() {
  return (
    <Base>
      <GeneralFilters
        InlineFilter={InlineInvoiceFilter}
        showInlineFilters={false}
      />
      <S.ChartsContainer>
        <InvoicesChart />
      </S.ChartsContainer>
      <Tabs />
    </Base>
  )
}
