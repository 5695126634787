import { call, put } from 'redux-saga/effects'
import * as actions from './Logs.actions'
import { LogsService } from '../../services'
import { toasts } from 'utils/toasts'

export function* fetchById(action) {
  try {
    const { id } = action.payload
    yield put(actions.setLoading({ loading: true }))

    const $logs = new LogsService()
    const { data, success } = yield call($logs.fetchById.bind($logs), id)

    if (success) {
      return yield put(
        actions.setLogs({
          logs: data
        })
      )
    } else {
      toasts.generalFail()
    }
  } catch (error) {
    console.error(error)
    // Tratar o erro de acordo com a necessidade
  } finally {
    yield put(actions.setLoading({ loading: false }))
  }
}

export function* fetchAll(action) {
  const { params } = action.payload

  yield put(actions.setLogs({ logs: {} }))

  const $logs = new LogsService()
  const fetchAll = $logs.fetchAll.bind($logs)

  const { success, status, data: logs } = yield call(fetchAll, { params })
  if (status === 404) {
    return yield put(
      actions.setLogs({
        logs: { items: [], pagination: { totalItems: 0 } }
      })
    )
  } else if (success) yield put(actions.setLogs({ logs }))
  else toasts.generalFail()
}
