import { CheckButton } from 'components/CheckButton'
import { GroupButtonSearch } from 'components/GroupButtonSearch'
import { Input } from 'components/Input'
import { MoreFilter } from 'components/MoreFilter'
import { INITIAL_STATE } from 'constants/traceability.constants'
import { USER_ROLES } from 'constants/user.constants'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { UserStorageService } from 'services'
import { traceabilityTablesActions } from 'store/TraceabilityTables'
import { all as allTraceability } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { getUserYearsForFilter } from 'utils/GetUserYearFilter'
import * as S from './styled'

interface IYearButtonFilter {
  toggleMoreFilter(): void
}

export function InlinePropertyFilter({ toggleMoreFilter }: IYearButtonFilter) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { filters } = useSelector(allTraceability)
  const LOCAL_INPUT_INITIAL_STATE = {
    producerdoc: filters.producerdoc || '',
    propname: filters.propname || '',
    producername: filters.producername || ''
  }
  const INITIAL_YEARS_STATE = filters.years.length
    ? filters.years
    : [new Date().getFullYear()]
  const [yearsState, setYearsState] = useState(INITIAL_YEARS_STATE)
  const [localInput, setLocalInput] = useState(LOCAL_INPUT_INITIAL_STATE)

  const { user } = UserStorageService.getUserData()

  const years = getUserYearsForFilter(user)

  function toggleValues(value: number) {
    setYearsState([value])
  }

  async function onSubmit() {
    try {
      dispatch(
        traceabilityTablesActions.setFilters({
          ...filters,
          ...localInput,
          years: yearsState
        })
      )
    } catch (ex) {
      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      console.log('ERROR: ', JSON.stringify(pairs))
    }
  }

  function handleInputData(
    value: string,
    name: 'producername' | 'producerdoc' | 'propname'
  ) {
    setLocalInput({ ...localInput, [name]: value })
  }

  function setBeginEndDate(isClear: boolean) {
    const atualYear = new Date().getFullYear()
    const begin = new Date(isClear ? atualYear : yearsState[0], 0, 1)
    const end =
      isClear || yearsState.includes(atualYear)
        ? new Date()
        : new Date(yearsState[yearsState.length - 1], 11, 31, 23, 59, 59)
    return { begin, end, atualYear }
  }

  function clearFilter() {
    setYearsState(() => [new Date().getFullYear()])
    const { begin, end, atualYear } = setBeginEndDate(true)
    setLocalInput({
      producerdoc: '',
      propname: '',
      producername: ''
    })
    dispatch(
      traceabilityTablesActions.setFilters({
        ...INITIAL_STATE,
        begin,
        end,
        years: [atualYear]
      })
    )
  }

  useEffect(() => {
    if (!isEqual(filters.years, yearsState)) {
      const { begin, end } = setBeginEndDate(false)
      dispatch(
        traceabilityTablesActions.setFilters({
          ...filters,
          begin,
          end,
          years: yearsState
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearsState])

  return (
    <S.Wrapper container>
      <S.GridYear item xs={12} md={2}>
        <S.Title>{t('dashboard:year')}</S.Title>
        <S.Group>
          {years.map(({ labelAdmin, labelView, valueAdmin, valueView }) => {
            const yearValue =
              user.tag === USER_ROLES.admin ? valueAdmin : valueView
            return (
              <CheckButton
                data-testid="yearButton"
                defaultValue={
                  user.tag === USER_ROLES.admin
                    ? yearsState.includes(valueAdmin)
                    : yearsState.includes(valueView)
                }
                onToggle={() => toggleValues(yearValue)}
                disableChange={
                  yearsState.length === 1 && yearsState.includes(valueAdmin)
                }
                key={valueAdmin}
              >
                <span className="years-btn">
                  {user.tag === USER_ROLES.admin ? labelAdmin : labelView}
                </span>
              </CheckButton>
            )
          })}
        </S.Group>
      </S.GridYear>
      <S.GridInput container xs={12} md={6}>
        <S.Input md={4}>
          <Input
            label={t('common:corporateNameOwnership')}
            fullWidth
            value={localInput.propname}
            onInput={value => handleInputData(value, 'propname')}
          />
        </S.Input>

        <S.Input md={4}>
          <Input
            label={t('common:producerName')}
            fullWidth
            value={localInput.producername}
            onInput={value => handleInputData(value, 'producername')}
          />
        </S.Input>
        <S.Input md={4}>
          <Input
            label={t('common:producerDocument')}
            fullWidth
            value={localInput.producerdoc}
            onInput={value => handleInputData(value, 'producerdoc')}
          />
        </S.Input>
      </S.GridInput>
      <S.GridButtons item xs={12} md={3}>
        <GroupButtonSearch
          onExit={clearFilter}
          onSubmit={onSubmit}
          type="filter"
        />
      </S.GridButtons>
      <MoreFilter border="not" toggle={toggleMoreFilter} />
    </S.Wrapper>
  )
}
