import { types } from './Logs.actions'
import { get } from 'lodash'

type Logs = {
  logs: {
    items: any[]
    pagination: any
  }
  loading: boolean
}

const INITIAL_STATE = {
  logs: {
    items: [],
    pagination: null
  },
  loading: false
}

function setState(state: Logs) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET_LOGS]: setState(state),
    [types.FETCH_ALL]: setState(state),
    [types.FETCH_BY_ID]: setState(state),
    [types.SET_LOADING]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}
