import { standard } from './standard.theme'
import { swift } from './swift.theme'
import { friboiProcessados } from './friboiProcessados.theme'
import { friboiInnatura } from './friboiInnatura.theme'
import { jbsFriboi } from './jbsFriboi.theme'
import { sampco } from './sampco.theme'

export const THEMES = {
  standard,
  swift,
  friboiProcessados,
  friboiInnatura,
  jbsFriboi,
  sampco
}
