// import { ViewModuleType } from 'types/ViewModule.types'

export const types = Object.freeze({
  FETCH_ALL: '@view_Module/fetchAll',
  FETCH_ONE: '@view_Module/fetchOne',
  FILTER_VIEW_MODULES: '@view_Module/filterViewModules',
  GET_VIEW_MODULES_OPTIONS: '@view_Module/getViewModuleOptions',
  GET_VIEW_MODULES_NAVIGATION: '@view_Module/getViewModuleNavigation',
  SET_VIEW_MODULES_OPTIONS: '@view_Module/setViewModuleOptions',
  SET_LOADING: '@view_Module/setLoading',
  SET_VIEW_MODULES: '@view_Module/setViewModules',
  SET_SELECTED_MODULE: '@view_Module/setSelectedModule'
})

export function setLoading({ loading = false } = {}) {
  return { type: types.SET_LOADING, payload: { loading } }
}

export function fetchAll(filters = {}) {
  return { type: types.FETCH_ALL, payload: { filters } }
}

export function setViewModules({ viewModules = [] } = {}) {
  return {
    type: types.SET_VIEW_MODULES,
    payload: { viewModules, loading: false }
  }
}

export function setSelectedModule({ selectedModule = null } = {}) {
  return {
    type: types.SET_VIEW_MODULES,
    payload: { selectedModule }
  }
}

export function fetchOne(id: string) {
  return { type: types.FETCH_ONE, payload: { id } }
}

export function filterViewModules({ viewmoduleId, params }) {
  return { type: types.FILTER_VIEW_MODULES, payload: { viewmoduleId, params } }
}

export function getViewModulesOptions() {
  return { type: types.GET_VIEW_MODULES_OPTIONS }
}

export function setViewModulesOptions({ options = [] } = {}) {
  return {
    type: types.SET_VIEW_MODULES_OPTIONS,
    payload: { options }
  }
}

export function fetchNavigation(id: string) {
  return {
    type: types.FETCH_ONE,
    payload: { id }
  }
}

// use this function to get the navigation by usedId
export function getViewModulesNavigation(id: string) {
  return { type: types.GET_VIEW_MODULES_NAVIGATION, payload: { id } }
}
