import { MouseEventHandler, ReactNode } from 'react'
import { debounce } from 'lodash'

import * as S from './styled'

export type ButtonProps = {
  children: ReactNode
  className?: string
  minWidth?: number
  size?: 'large' | 'medium' | 'small' | 'xsmall' | 'sizeFiler'
  variant?:
    | 'primary'
    | 'default'
    | 'secondary'
    | 'loggedOut'
    | 'warn'
    | 'alternative'
    | 'permission'
    | 'module'
    | 'certification'
    | 'map'
    | 'filter'
    | 'fixed'
  onClick: MouseEventHandler
  border?: 'yes' | 'not'
}

export function Button({
  children,
  className = '',
  minWidth = 6,
  size = 'medium',
  variant = 'primary',
  onClick,
  border = 'not'
}: ButtonProps) {
  return (
    <S.Button
      className={`${variant} ${size} ${className} ${border}`}
      minWidth={minWidth}
      onClick={debounce(onClick, 600)}
    >
      {children}
    </S.Button>
  )
}
