import { Header } from '../Header'
import { NewPagination, Table, Button } from 'components'

import * as S from './styled'

import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { INITIAL_STATE } from 'constants/traceability.constants'
import { useTranslation } from 'react-i18next'
import { columns } from 'constants/rawMaterialBox.constants'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { all as allRawMaterialBox } from 'store/RawMaterialBox/RawMaterialBox.selector'
import { RawMaterialBoxActions } from 'store/RawMaterialBox'
import { ModalSendEmail } from 'components/ModalSendMail'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

type PropTypes = {
  order: any
  spinnerIsVisible?: boolean
}

export function ModalContent({ order, spinnerIsVisible }: PropTypes) {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const orderKey = order?.key
  const orderSku = order?.skuCodigo

  const { filters } = useSelector(all)
  const [filter, setFilter] = useState({ ...INITIAL_STATE })
  const [modalExport, setModalExport] = useState(false)
  const { filterRawMaterialBox, rawMaterialBox = [] } =
    useSelector(allRawMaterialBox)
  const { pagination } = rawMaterialBox
  const [isNewPage, setNewPage] = useState({ ...pagination })

  const fetch = useCallback(() => {
    setFilter(filters)
    if (!rawMaterialBox.items) {
      dispatch(
        RawMaterialBoxActions.getRawMaterialBoxes(orderKey, orderSku, filters)
      )
    }
  }, [dispatch, filters, orderKey, orderSku, rawMaterialBox.items])

  useEffect(() => {
    if (orderKey) fetch()
  }, [fetch, orderKey])

  async function handleChangePage(_event: null, newPage: number) {
    const newFilter = {
      ...filterRawMaterialBox,
      ...filters
    }
    newFilter.page = newPage
    dispatch(RawMaterialBoxActions.setFilterRawMaterial(newFilter))
    dispatch(
      RawMaterialBoxActions.getRawMaterialBoxes(orderKey, orderSku, newFilter)
    )
  }

  function onExport() {
    const newFilter = {
      ...filterRawMaterialBox,
      ...filters
    }
    dispatch(
      RawMaterialBoxActions.getRawMaterialBoxesExport(
        orderKey,
        orderSku,
        newFilter
      )
    )
    setModalExport(true)
  }

  function handleNewPage() {
    const newPage = isNewPage.page
    if (newPage > pagination.totalPages) {
      const newFilter = {
        ...filterRawMaterialBox,
        ...filters
      }
      newFilter.page = pagination.totalPages
      dispatch(RawMaterialBoxActions.setFilterRawMaterial(newFilter))
      dispatch(
        RawMaterialBoxActions.getRawMaterialBoxes(orderKey, orderSku, newFilter)
      )
      setNewPage({ ...pagination, page: newPage })
    } else {
      const newFilter = {
        ...filterRawMaterialBox,
        ...filters
      }
      newFilter.page = newPage
      dispatch(RawMaterialBoxActions.setFilterRawMaterial(newFilter))
      dispatch(
        RawMaterialBoxActions.getRawMaterialBoxes(orderKey, orderSku, newFilter)
      )
      setNewPage({ ...pagination, page: newPage })
    }
  }

  function handleChangeRowsPerPage(e) {
    const newFilter = {
      ...filterRawMaterialBox,
      ...filters
    }
    newFilter.limit = e.target.value
    dispatch(RawMaterialBoxActions.setFilterRawMaterial(newFilter))
    dispatch(
      RawMaterialBoxActions.getRawMaterialBoxes(orderKey, orderSku, newFilter)
    )
  }

  function handleInputPagination(name: string, value: number) {
    setNewPage({ ...pagination, [name]: value })
  }

  return (
    <S.Wrapper>
      <S.HeaderContainer>
        <Header title={t('common:rawMaterialDetails')} />
        <S.NfNr>{t('common:skuCode') + ': ' + orderSku ?? ''}</S.NfNr>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p
            style={{
              fontSize: '1.4rem',
              color: 'gray',
              marginRight: '1.5rem'
            }}
          >
            {rawMaterialBox.pagination?.totalItems} {t('common:register')}
          </p>
          <Button variant="primary" size="medium" onClick={onExport}>
            <S.ButtonContent>
              <CloudDownloadIcon />
              <S.ButtonText>{t('common:export')}</S.ButtonText>
            </S.ButtonContent>
          </Button>
        </div>
      </S.HeaderContainer>

      <S.Line />

      {rawMaterialBox?.items?.length > 0 ? (
        <>
          <S.FullGrid item xs={12}>
            <S.GridTable>
              <Table
                columns={columns()}
                rows={rawMaterialBox.items || []}
                sortColumn={{ column: filter.column, sort: filter.direction }}
                isLoading={spinnerIsVisible}
                isScroll={rawMaterialBox.items?.length > 10}
              />
              {modalExport && (
                <ModalSendEmail
                  title={t('modalExportOrders:titleRawMaterial')}
                  description={t('modalExportOrders:message')}
                  onExit={() => setModalExport(false)}
                />
              )}
              {pagination?.totalItems > 0 && (
                <NewPagination
                  count={pagination?.totalPages}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  handleInput={handleInputPagination}
                  handleNewPage={handleNewPage}
                  page={pagination?.page}
                  limit={pagination.limit}
                />
              )}
            </S.GridTable>
          </S.FullGrid>
        </>
      ) : (
        <>
          {!spinnerIsVisible && <S.NoContent>{t('common:noData')}</S.NoContent>}
        </>
      )}
    </S.Wrapper>
  )
}
