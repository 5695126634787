import { HTTP } from 'providers'

export class LogsService extends HTTP {
  private endpoint = '/logs'

  public fetchById(id) {
    return this.get({ endpoint: `${this.endpoint}/${id}` })
  }

  public fetchAll({ params = {} } = {}) {
    return this.get({ endpoint: this.endpoint, params })
  }
}
