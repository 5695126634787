import { ColumnsProps } from 'components/Table/Table'
import i18n from 'i18n'

export const columns = (): ColumnsProps[] => [
  {
    align: 'center',
    fixed: true,
    name: 'skuCodigo',
    title: i18n.t('tables:productCode')
  },
  {
    align: 'left',
    fixed: true,
    name: 'skuDescricao',
    title: i18n.t('tables:productDescription')
  },
  {
    align: 'center',
    fixed: true,
    name: 'pesoBruto',
    title: i18n.t('tables:grossWeight')
  },
  {
    align: 'center',
    fixed: true,
    name: 'pesoLiquido',
    title: i18n.t('tables:netWeight')
  },
  {
    align: 'center',
    fixed: true,
    name: 'qtyCaixas',
    title: i18n.t('common:totalBoxVolume')
  }
]
