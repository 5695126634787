import { Modal } from 'components/Modal'

import { CertificationsListTable } from './CertificationsListTable'
import { ProtocolsListType } from 'types/Protocols.types'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ModalCertificationsListProps = {
  certifications: ProtocolsListType[]
  onExit?: () => void
}

export function ModalCertificationsList({
  certifications,
  onExit
}: ModalCertificationsListProps) {
  const { t } = useTranslation()
  const content = (
    <S.Wrapper>
      <S.GridHeader>
        <S.GridTitle item container xs={12}>
          <S.BoxTitle>{t('common:protocol')}</S.BoxTitle>
        </S.GridTitle>
      </S.GridHeader>

      <S.Line />

      <S.GridTable>
        {certifications.length > 0 && (
          <CertificationsListTable payload={certifications} />
        )}
      </S.GridTable>
    </S.Wrapper>
  )

  return (
    <Modal
      content={content}
      isActionsEnabled={false}
      width={70}
      onExit={onExit}
    />
  )
}
