import { Button, Checkbox, Modal } from 'components'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ModalTermOfUseProps = {
  onExit: () => void
  onSubmit: (_value) => void
  positionScroll: () => void
  scrolled: any
  onCheck: (_val: boolean) => void
  value: string
}

export function ModalTermOfUse({
  onExit,
  onSubmit,
  positionScroll,
  scrolled,
  onCheck,
  value
}: ModalTermOfUseProps) {
  const { t } = useTranslation()
  const button = (
    <Button
      variant={scrolled ? 'secondary' : 'default'}
      className="confirm"
      onClick={onSubmit}
      size="medium"
    >
      <p>{t('termOfUse:accept')}</p>
    </Button>
  )

  const content = (
    <S.Modal onScroll={positionScroll}>
      <S.Title>{t('termOfUse:title')}</S.Title>
      <S.Line />
      <S.Section>
        <S.Info>
          {t('termOfUse:firstParagraph')}{' '}
          <S.Link href="https://jbs.com.br/contato/portal-de-privacidade/">
            jbs.com.br
          </S.Link>
        </S.Info>
        <S.Info>{t('termOfUse:secondParagraph')}</S.Info>
        <p id="positionScroll"></p>
      </S.Section>
      <S.Line />
      <S.Accept>
        <Checkbox
          label={t('termOfUse:declaration')}
          labelFor="termOfUse"
          onCheck={onCheck}
          value={value}
        />
      </S.Accept>
    </S.Modal>
  )

  return <Modal content={content} button={button} onExit={onExit} />
}
