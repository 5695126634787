import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { FormPwd, SvgSignin } from 'components'

import { YupValidator } from 'services/yupValidator.service'
import { UserService } from 'services/user.service'

import { RecoverySchema } from 'store/User/User.validator'

import { RequestResetPwdForm } from './RequestForm'
import { ResetPwdForm } from './ResetForm'
import { SuccessRequestMessage } from './SuccessRequestMessage'
import { SuccessResetMessage } from './SuccessResetMessage'

import { useTranslation } from 'react-i18next'

import * as S from './styled'
import { theme } from 'config'
import { ErrorMessageToken } from './ErrorMessageToken'

const INITIAL_STATE = { email: '' }
const VISIBLE_SECTION = {
  requestForm: 0,
  requestMessage: 1,
  resetForm: 2,
  resetMessage: 3,
  errorMessage: 4
}

export function RecoveryPwd() {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const [form, setForm] = useState(INITIAL_STATE)
  const [visibleSec, setVisibleSection] = useState(VISIBLE_SECTION.requestForm)
  const [errors, setErrors] = useState(INITIAL_STATE)
  const [query, setQuery] = useState({})
  const lang = new URLSearchParams(window.location.search.substring(1)).get(
    'language'
  )
  function handleInput(name: string, value: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  async function handleClick() {
    setErrors({ ...INITIAL_STATE })

    const [isValid, validationError] = await new YupValidator(
      RecoverySchema()
    ).validate(form)

    if (!isValid) return setErrors(validationError as any)

    await new UserService().requestPwdReset(form.email)
    setVisibleSection(VISIBLE_SECTION.requestMessage)
  }

  async function onResetSubmit(payload) {
    const success = await new UserService().resetPwd({ ...payload, ...query })
    if (success.data.code === 'NOT_FOUND') {
      setVisibleSection(VISIBLE_SECTION.errorMessage)
    } else {
      setVisibleSection(VISIBLE_SECTION.resetMessage)
    }
  }

  useEffect(() => {
    const raw = new URLSearchParams(window.location.search.substring(1))
    const email = raw.get('email')
    const token = raw.get('token')

    const _query = {}
    if (email) Object.assign(_query, { email })
    if (token) Object.assign(_query, { token })
    if (lang) {
      Object.assign(_query, { lang })
      i18n.changeLanguage(lang)
    }
    if (!Object.keys(query).length && Object.keys(_query).length) {
      setQuery(_query)
      setVisibleSection(VISIBLE_SECTION.resetForm)
    }
  }, [query, location.search, lang, i18n])
  function GetThemeMode() {
    const THEMES_NAME = {
      jbsFriboi: (
        <>
          <S.LogoFriboi />
          <S.Title>{t('authPages:ecoB2BFrigol')}</S.Title>
          <p
            style={{
              color: '#3A599C',
              fontSize: '2.5rem',
              fontWeight: 'bold',
              marginBottom: '1.4rem'
            }}
          ></p>
        </>
      ),
      standard: (
        <>
          <S.LogoEco />
          <S.Title>{t('authPages:ecoB2BEco')}</S.Title>
        </>
      )
    }

    return THEMES_NAME[theme.appTheme]
  }
  return (
    <FormPwd>
      <S.SideBarCorner>
        <SvgSignin />
      </S.SideBarCorner>
      <S.FormContainer>
        <GetThemeMode />
        <S.Title>{t('authPages:recoverPassword')}</S.Title>

        {visibleSec === VISIBLE_SECTION.requestMessage && (
          <SuccessRequestMessage email={form.email} />
        )}
        {visibleSec === VISIBLE_SECTION.requestForm && (
          <RequestResetPwdForm
            onInput={handleInput}
            onSubmit={handleClick}
            error={errors.email}
            value={form.email}
          />
        )}
        {visibleSec === VISIBLE_SECTION.resetForm && (
          <ResetPwdForm onSubmit={onResetSubmit} />
        )}
        {visibleSec === VISIBLE_SECTION.resetMessage && <SuccessResetMessage />}
        {visibleSec === VISIBLE_SECTION.errorMessage && <ErrorMessageToken />}
        {visibleSec !== VISIBLE_SECTION.resetMessage &&
          visibleSec !== VISIBLE_SECTION.errorMessage && (
            <S.ForgetPassword to={'/login'}>
              {t('authPages:backToLogin')}
            </S.ForgetPassword>
          )}
      </S.FormContainer>
    </FormPwd>
  )
}
