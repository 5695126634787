/* eslint-disable react-hooks/exhaustive-deps */
import { BarChart } from 'components'
import { THEMES } from 'themes'
import { ThemeContext } from 'App'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { all as allDrawer } from 'store/Sidebar/Sidebar.selector'
import { traceabilityTablesActions } from 'store/TraceabilityTables'
import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'

import * as S from './styled'

export function InvoicesChart() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { currentTheme } = useContext(ThemeContext)

  const monthsTranslation = t('common:months')
  const amountTranslation = t('common:amount')
  const invoicesTranslation = t('common:invoices')

  const { invoicesByMonth, chartsLoading, filters } = useSelector(all)
  const { persistent } = useSelector(allDrawer)

  const invoiceSeries = {
    name: monthsTranslation,
    data: invoicesByMonth
  }

  useEffect(() => {
    if (filters.begin && filters.end && !chartsLoading) {
      dispatch(
        traceabilityTablesActions.getKpisCharts({
          ...filters,
          years: filters.years.length
            ? filters.years
            : [new Date().getFullYear()]
        })
      )
    }
  }, [dispatch, filters])

  return (
    <S.Wrapper>
      <S.GridHeader>
        <S.BoxTitle>
          <S.Title>{invoicesTranslation}</S.Title>
        </S.BoxTitle>
      </S.GridHeader>

      <BarChart
        color={THEMES[currentTheme].colors.chartBarColor}
        type="column"
        title=""
        subTitle=""
        series={invoiceSeries}
        tooltipSuffix={invoicesTranslation}
        xAxisTitle={monthsTranslation}
        yAxisTitle={amountTranslation}
        height={275}
        width={persistent === false ? 1207 : 1005}
        isLoading={chartsLoading}
      />
    </S.Wrapper>
  )
}
