import { get } from 'lodash'
import { TraceabilityTableType } from 'types/Traceability.types'
import { INITIAL_STATE as INITIAL_STATE_FILTER } from 'constants/traceability.constants'

import { types } from './TraceabilityTables.actions'

const INITIAL_STATE = {
  filters: INITIAL_STATE_FILTER,
  volumeDelivered: null,
  properties: null,
  invoices: null,
  fazCode: null,
  suitableProperties: null,
  sifProduction: null,
  trackedTons: null,
  invoicesByMonth: [],
  trackedTonsByMonth: [],
  conformityByMonth: [],
  sifByMonth: [],
  propertiesByMonth: [],
  volumeDeliveredByMonth: [],
  accessGroupsFilter: [],
  industryGroupsFilter: [],
  qualifications: [],
  cardsLoading: false,
  chartsLoading: false,
  tableLoading: false,
  analyze: false,
  protocols: 'all',
  nrProtocols: '',
  sku: ''
}

function setState(state: TraceabilityTableType) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.CLEAN_KPIS]: setState(state),
    [types.SET_KPIS]: setState(state),
    [types.SET_CHARTS]: setState(state),
    [types.SET_FILTERS]: setState(state),
    [types.SET_CARDS_LOADING]: setState(state),
    [types.SET_CHARTS_LOADING]: setState(state),
    [types.SET_TABLE_LOADING]: setState(state),
    [types.SET_ACCESS_GROUPS_FILTER]: setState(state),
    [types.SET_INDUSTRY_GROUPS_FILTER]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}
