import { Grid } from '@material-ui/core'
import styled from 'styled-components'
export const GridContainer = styled(Grid)``
export const SideBarCorner = styled.div`
  align-self: center;
  margin-left: -9.35rem;
  margin-top: -15rem;
  .chevron {
    color: white;
    display: block;
    font-size: 4.8rem;
    margin-left: 9rem;
    margin-top: -16rem;
    transform: translateY(-110%);
  }
`
export const FormContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`
export const Logo = styled.img.attrs({
  alt: 'Lupa com uma folha com Ecotrace escrito ao lado',
  src: '/images/logo.png'
})`
  height: auto;
  margin: 0 0 2.4rem 0;
  object-fit: contain;
  object-position: center;
  width: 17rem;
`
export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.8rem;
  font-weight: 600;
  text-align: center;
`

export const LogoEco = styled.img.attrs({
  alt: 'Lupa com uma folha com Ecotrace escrito ao lado',
  src: '/images/logo.png'
})`
  height: auto;
  object-fit: contain;
  object-position: center;
  margin: 0 0 3rem 0;
  width: 17rem;
`

export const LogoFriboi = styled.img.attrs({
  alt: 'Logo JBS',
  src: '/images/logo_jbs_login.png'
})`
  height: auto;
  object-fit: contain;
  object-position: center;
  margin: 0 0 3rem 0;
  width: 23rem;
`
