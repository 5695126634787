import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const Wrapper = styled(Grid)``

export const GridHorizontalDate = styled(Grid)``
export const GridHeader = styled.div`
  padding-top: 2.4rem;
  padding-left: 2.4rem;
  padding-bottom: 3.1rem;
`
export const Title = styled.div`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 600;
  padding-left: 2.4rem;
`
export const BoxTitle = styled.h2``
