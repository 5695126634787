import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const GridContainer = styled(Grid)`
  padding-top: 3.5rem;
`

export const GridIconCard = styled(Grid)`
  margin: 0 auto !important;
`
export const GridCard = styled(Grid)`
  border-radius: 0.8rem;
  box-shadow: 0px 11px 29px #00000021;
  margin-bottom: 40px;
  margin-right: 5rem;
`
export const GridCards = styled(Grid)`
  display: grid;
  grid-template-columns: 1fr 1fr;
`
