import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { submitOnEnter } from 'utils/submitOnEnter'
import { logsActions } from 'store/Logs'
import { all } from 'store/Logs/Logs.selector'

import * as S from './styled'
import { Table } from 'components/Table'
import { COLUMNS } from 'constants/logs.constants'
import { Pagination } from 'components/Pagination'
import { Input } from 'components/Input'
import { Button } from 'components/Button'

const INITIAL_STATE_FILTER = {
  firstName: '',
  page: 1,
  limit: 10
}

export function LogsList() {
  // eslint-disable-next-line no-unused-vars
  const [filter, setFilter] = useState({ ...INITIAL_STATE_FILTER })
  const dispatch = useDispatch()
  const columns = COLUMNS
  const { logs } = useSelector(all)
  useEffect(() => {
    dispatch(logsActions.fetchAll())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function formatRows(logs: Array<any>) {
    if (!logs) return []
    return logs.map(log => ({
      ...log,
      ip: log.ip || 'Não informado',
      filters: JSON.stringify(log.filters)
    }))
  }
  async function onFilter() {
    const params = { ...filter }
    try {
      dispatch(logsActions.fetchAll({ params }))
    } catch (ex) {
      console.log(ex)
    }
  }

  function onFilterPaginate(params = {}) {
    dispatch(logsActions.fetchAll({ params }))
  }
  function handleInput(value: string, name: string) {
    // setErrors({ ...errors, [name]: '' })
    setFilter({ ...filter, [name]: value })
  }

  function clearFilter() {
    const params = {
      ...INITIAL_STATE_FILTER,
      limit: logs.pagination.limit,
      page: logs.pagination.page
    }
    setFilter(params)
    dispatch(logsActions.fetchAll({ params }))
  }
  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>Filtrar de logs</S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              fullWidth
              label="Nome"
              value={filter.firstName}
              onKeyPress={submitOnEnter(onFilter)}
              onInput={value => handleInput(value, 'firstName')}
            />
          </S.GridInput>
          <S.GridButtons item xs={12} sm={12} md={9}>
            <Button variant="default" onClick={clearFilter} size="medium">
              <p>Limpar</p>
            </Button>
            <Button variant="primary" onClick={onFilter} size="medium">
              <p>Buscar</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
        <S.GridFilter container></S.GridFilter>
        <S.GridTable>
          <Table columns={columns()} rows={formatRows(logs.items)} />
          {logs.pagination?.totalItems > 0 && (
            <Pagination
              filters={{
                ...filter,
                limit: logs.pagination.limit,
                page: logs.pagination.page
              }}
              limit={logs.pagination.limit}
              page={logs.pagination.page}
              totalItems={logs.pagination.totalItems}
              setFilter={onFilterPaginate}
            />
          )}
        </S.GridTable>
      </S.FullGrid>
    </S.Wrapper>
  )
}
