import styled from 'styled-components'
export const Section = styled.div`
  background-color: white;
  border-radius: 0.8rem;
  width: 50%;
  margin: 0 auto;
  box-shadow: 0px 11px 29px #00000021;
  display: flex;
  justify-content: space-between;
`
export const SectionToggle = styled.div`
  border-radius: 0.8rem;
  margin: 0 auto;
  box-shadow: 0px 11px 29px #00000021;
  display: flex;
  justify-content: space-between;
`

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Group = styled.div`
  display: flex;
  margin: 0 -1.1rem;
  padding: 0.7rem 1.6rem;

  button {
    display: inline-block;
    margin: 0 0.8rem;
    background-color: #f2f3f7 !important;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.primary1} !important;
    border: 0 !important;
    border-radius: 0.8rem;
    &:hover,
    :checked {
      background: ${({ theme }) => theme.colors.secondary} !important;
      color: white !important;
    }
  }
`
export const Title = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  font-weight: 600;
  padding-left: 2.4rem;
  padding-right: 2.9rem;
  :first-child {
    padding-bottom: 0.8rem;
  }
`
export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  .icons-search {
    margin-bottom: -2px;
  }
`
export const ButtonText = styled.h1`
  font-size: 1.4rem;
  font-weight: 500;
  padding-left: 1.2rem;
  padding-right: 3.6rem;
`
export const InputGeneralFilters = styled.div`
  margin-top: 0rem;
`
