import chroma from 'chroma-js'
import { useTranslation } from 'react-i18next'

const Translate = ({ text }: { text: string }) => {
  const { t } = useTranslation()

  return <>{t(text)}</>
}

export const friboiInnatura = {
  colors: {
    background: chroma('#F4F8F7'),
    backgroundAutocomplete: chroma('#F7F9FD'),
    backgroundInactive: chroma('#f94646'),
    backgroundActive: chroma('#3636ab'),
    backgroundMessage: chroma('#EBEBEB'),
    black: chroma('#000000'),
    borderActive: chroma('#12128c'),
    borderInactive: chroma('#f94646'),
    darkGray: chroma('#464A53'),
    error: chroma('#de4f4f'),
    focusForm: chroma('#408ae4'),
    footer: chroma('#e4e4e4'),
    gray: chroma('#707070'),
    hoverForm: chroma('#0f0f0f'),
    lightGray: chroma('#abafb3'),
    primary: chroma('#3A599C'),
    red: chroma('#DE4F4F'),
    rowTable: chroma('#F4F4F8'),
    secondary: chroma('#dc4646'),
    success: chroma('#3636ab'),
    textActive: chroma('#0b0b59'),
    textInactive: chroma('#940f0f'),
    warn: chroma('#F39C12'),
    white: chroma('#ffffff'),
    backgroundLoggedOut: chroma('#4112a7'),
    buttonLoggedOut: chroma('#4112a7'),
    loggetOutColor: chroma('#ffffff'),
    fillIcon: chroma('#dc4646'),
    buttonSave: chroma('#dc4646'),
    buttonMap: chroma('#dc4646'),
    buttonFilter: chroma('#233762'),
    labelGoBack: chroma('#3A599C'),
    backgroundMenuItem: chroma('#3A599C'),
    spanDate: chroma('#dc4646'),
    backgroundPermission: chroma('#dc4646'),
    backgroundModule: chroma('#199C26'),
    backgroundCertification: chroma('#4677dc'),
    colorCardDark: chroma('#233762'),
    colorCardLight: chroma('#7E92BD'),
    colorCardBlue: chroma('#3A599C'),
    colorCardGray: chroma('#464A53'),
    colorCardRed: chroma('#ED1C24'),
    colorCardDarkYellow: chroma('#F1CE56'),
    colorCardGrayLight: chroma('#E2E2E2'),
    colorCardOrder: chroma('#233762'),
    colorCardNetWeight: chroma('#7E92BD'),
    colorCardProperty: chroma('#3A599C'),
    colorCardSif: chroma('#464A53'),
    primary1: chroma('#3A599C'),
    primary2: chroma('#233762'),
    chartBarColor: '#123760'
  },
  iconLargeNameSidebar: 'panel-logo-friboi',
  iconLargeSidebar: 120,
  iconSmallNameSidebar: 'panel-logo-friboi',
  iconSmallSidebar: 46,
  spacings: {
    xxxsmall: '0.4rem',
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem'
  },
  title: <Translate text="authPages:guaranteeOfOrigin" />,
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  },
  traceabilityPanel: <Translate text="cards:jbsTraceabilityPanel" />
}
