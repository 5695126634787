import { omit } from 'lodash'
import { call, put } from 'redux-saga/effects'

import { RawMaterialService } from 'services'
import FilteringTraceability from 'utils/FilteringTraceability'

import * as actions from './RawMaterial.actions'
import * as actionsKpis from '../TraceabilityTables/TraceabilityTables.actions'

export function* getRawMaterial(action) {
  yield put(actionsKpis.setTableLoading(true))
  const _filters = FilteringTraceability(action.payload.filterRawMaterial)

  const $rawMaterial = new RawMaterialService()

  const rawMaterial = yield call(
    $rawMaterial.getRawMaterial.bind($rawMaterial),
    {
      ..._filters,
      orderskeys: action.payload.ordersKeys.toString()
    }
  )

  if (rawMaterial.success) {
    yield put(
      actions.setRawMaterial({
        rawMaterial: omit(rawMaterial.data, ['kpis', 'charts'])
      })
    )
  } else {
    yield put(
      actions.setRawMaterial({
        rawMaterial: { items: [], pagination: { totalItems: 0 } }
      })
    )
    if (rawMaterial.status !== 404) {
      yield put(actionsKpis.cleanKpis())
    }
  }

  yield put(actionsKpis.setTableLoading(false))
}

export function* getRawMaterialExported(action) {
  const $order = new RawMaterialService()

  yield call($order.getRawMaterialExported.bind($order), {
    orderskeys: action.payload.ordersKeys.toString()
  })
}
