/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { all as allOrders } from 'store/Order/Order.selector'

import * as S from './styled'
import { useDispatch, useSelector } from 'react-redux'
import TabContext from '@material-ui/lab/TabContext'
import ReactTooltip from 'react-tooltip'

import { USER_ROLES } from 'constants/user.constants'
import { UserStorageService } from 'services'

import {
  OrderTable,
  BoxesList,
  RawMaterialList,
  FoodSafety,
  PropertiesTable
} from 'pages/TraceabilityTables'
import { OrderActions } from 'store/Order'
import { ThemeContext } from 'App'
import { LINE_TYPES, getLineTypeByTheme } from 'config/theme.config'

export function Tabs() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { currentTheme } = useContext(ThemeContext)
  const isProcessed = getLineTypeByTheme(currentTheme) === LINE_TYPES.PROCESSED

  const { filters, tableLoading } = useSelector(all)
  const { ordersKeys } = useSelector(allOrders)

  const [value, setValue] = useState('orders')
  const [ordersKeysState, setOrdersKeysState] = useState(ordersKeys)

  useEffect(() => {
    setOrdersKeysState(ordersKeys)
    if (!ordersKeys.length) setValue('orders')
  }, [ordersKeys])

  useEffect(() => {
    if (filters.begin && filters.end && !tableLoading) {
      dispatch(OrderActions.getOrders(filters))
      dispatch(OrderActions.clearOrdersKeys())
    }
  }, [filters])

  const { user } = UserStorageService.getUserData()
  const isAdmin = user.tag === USER_ROLES.admin

  const handleChange = (_event: ChangeEvent<{}>, newValue: string) => {
    if (ordersKeysState.length && !tableLoading) setValue(newValue)
  }

  return (
    <>
      <S.Wrapper>
        <TabContext value={value}>
          <S.TabList onChange={handleChange} aria-label="invoices">
            <S.Tab
              data-tip="tooltip_loading"
              label={t('common:invoice')}
              value="orders"
              isDisable={tableLoading}
            />
            <S.Tab
              data-tip="tooltip"
              label={t('tables:itensInvoice')}
              value="boxesList"
              isDisable={!ordersKeysState.length || tableLoading}
            />
            <S.Tab
              data-tip="tooltip"
              label={t('tables:foodSafety')}
              value="foodSafety"
              isDisable={!ordersKeysState.length || tableLoading}
            />
            <S.Tab
              data-tip="tooltip"
              show={isProcessed && isAdmin}
              label={t('tables:rawMaterial')}
              value="rawMaterialList"
              isDisable={!ordersKeysState.length || tableLoading}
            />
            <S.Tab
              data-tip="tooltip"
              label={t('common:properties')}
              value="properties"
              isDisable={!ordersKeysState.length || tableLoading}
            />
            <ReactTooltip
              type="light"
              effect="solid"
              id="tooltip"
              disable={ordersKeysState.length > 0 || tableLoading}
            >
              <span>{t('common:selectAnInvoice')}</span>
            </ReactTooltip>
            <ReactTooltip
              type="light"
              effect="solid"
              id="tooltip"
              disable={!tableLoading}
            >
              <span>{t('common:waitDataLoad')}</span>
            </ReactTooltip>
          </S.TabList>
          <S.Panel value="orders">
            <OrderTable />
          </S.Panel>
          <S.Panel value="boxesList">
            <BoxesList />
          </S.Panel>
          <S.Panel value="rawMaterialList">
            <RawMaterialList />
          </S.Panel>
          <S.Panel value="foodSafety">
            <FoodSafety />
          </S.Panel>
          <S.Panel value="properties">
            <PropertiesTable />
          </S.Panel>
        </TabContext>
      </S.Wrapper>
    </>
  )
}
