import { Base } from 'layouts'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { UserStorageService } from 'services'
// import { USER_ROLES } from 'constants/user.constants'
import * as S from './styled'

export function Help() {
  const { t } = useTranslation()
  const { user } = UserStorageService.getUserData()
  // Código foi comentado pois ainda não temos URLs diferentes,
  // const baseUrlJBS = 'https://ecotracebovinos-support.freshdesk.com/'
  // const finalUrlJBS = '/support/solutions/151000192903'
  // const finalUrlRetail = '/support/solutions/folders/151000483619'

  const urlTemHelpDesk =
    'https://garantiadeorigem.freshdesk.com/support/solutions'

  const lang = localStorage.getItem('i18nextLng')
  useEffect(() => {
    // Código foi comentado pois ainda não temos URLs diferentes,
    // if (user.tag === USER_ROLES.viewer) {
    //   if (lang === 'ch') {
    //     const urlHelpDeskRetailCN = `${baseUrlJBS}zh-CN${finalUrlRetail}`
    //     window.open(urlHelpDeskRetailCN, '_blank')
    //     window.focus()
    //     return window.history.back()
    //   } else if (lang === 'es') {
    //     const urlHelpDeskRetailAR = `${baseUrlJBS}es${finalUrlRetail}`
    //     window.open(urlHelpDeskRetailAR, '_blank')
    //     window.focus()
    //     return window.history.back()
    //   } else if (lang === 'en-US') {
    //     const urlHelpDeskRetailEN = `${baseUrlJBS}en${finalUrlRetail}`
    //     window.open(urlHelpDeskRetailEN, '_blank')
    //     window.focus()
    //     return window.history.back()
    //   } else {
    //     const urlHelpDeskRetailBR = `${baseUrlJBS}pt-br${finalUrlRetail}`
    //     window.open(urlHelpDeskRetailBR, '_blank')
    //     window.focus()
    //     return window.history.back()
    //   }
    // } else {
    //   if (lang === 'ch') {
    //     const urlHelpDeskJSBAdmCH = `${baseUrlJBS}zh-CN${finalUrlJBS}`
    //     window.open(urlHelpDeskJSBAdmCH, '_blank')
    //     window.focus()
    //     return window.history.back()
    //   } else if (lang === 'es') {
    //     const urlHelpDeskJSBAdmAR = `${baseUrlJBS}es-ar${finalUrlJBS}`
    //     window.open(urlHelpDeskJSBAdmAR, '_blank')
    //     window.focus()
    //     return window.history.back()
    //   } else if (lang === 'en-US') {
    //     const urlHelpDeskJSBAdmEN = `${baseUrlJBS}en-us${finalUrlJBS}`
    //     window.open(urlHelpDeskJSBAdmEN, '_blank')
    //     window.focus()
    //     return window.history.back()
    //   } else {
    //     const urlHelpDeskJSBAdmBR = `${baseUrlJBS}pt-br${finalUrlJBS}`
    //     window.open(urlHelpDeskJSBAdmBR, '_blank')
    //     window.focus()
    //     return window.history.back()
    //   }
    // }
    window.open(urlTemHelpDesk, '_blank')
    window.focus()
    return window.history.back()
  }, [lang, user.tag])
  return (
    <Base>
      <S.GridContainer container>
        <h1>{t('breadcrumb:helpDesk')}</h1>
      </S.GridContainer>
    </Base>
  )
}
