import chroma from 'chroma-js'
import { useTranslation } from 'react-i18next'

const Translate = ({ text }: { text: string }) => {
  const { t } = useTranslation()

  return <>{t(text)}</>
}

export const swift = {
  colors: {
    background: chroma('#F4F8F7'),
    darkGray: chroma('#464A53'),
    gray: chroma('#707070'),
    primary: chroma('#F39C12'),
    secondary: chroma('#DC4405'),
    warn: chroma('#F39C12'),
    error: chroma('#de4f4f'),
    white: chroma('#ffffff'),
    black: chroma('#000000'),
    backgroundLoggedOut: chroma('#3D3935'),
    labelGoBack: chroma('#3D3935'),
    backgroundMenuItem: chroma('#3D3935'),
    loggetOutColor: chroma('#000000'),
    hoverForm: chroma('#0f0f0f'),
    footer: chroma('#e4e4e4'),
    buttonLoggedOut: chroma('#EF5123'),
    backgroundAutocomplete: chroma('#F7F9FD'),
    backgroundInactive: chroma('#F99746'),
    backgroundActive: chroma('#3FAB36'),
    backgroundMessage: chroma('#EBEBEB'),
    borderActive: chroma('#128C49'),
    borderInactive: chroma('#F99746'),
    focusForm: chroma('#3D3935'),
    lightGray: chroma('#abafb3'),
    red: chroma('#DE4F4F'),
    rowTable: chroma('#F4F8F7'),
    success: chroma('#3FAB36'),
    textActive: chroma('#0B592F'),
    textInactive: chroma('#944B0F'),
    fillIcon: chroma('#EF5123'),
    buttonSave: chroma('#f39c12'),
    buttonMap: chroma('#EF5123'),
    spanDate: chroma('#EF5123'),
    buttonFilter: chroma('#F39C12'),
    backgroundPermission: chroma('#3D3935'),
    backgroundModule: chroma('#199C26'),
    backgroundCertification: chroma('#4677dc'),
    colorCardDark: chroma('#233762'),
    colorCardLight: chroma('#7E92BD'),
    colorCardBlue: chroma('#3A599C'),
    colorCardGray: chroma('#464A53'),
    colorCardRed: chroma('#ED1C24'),
    colorCardDarkYellow: chroma('#F1CE56'),
    colorCardGrayLight: chroma('#E2E2E2'),
    colorCardOrder: chroma('#FF7135'),
    colorCardNetWeight: chroma('#EDA045'),
    colorCardProperty: chroma('#C95625'),
    colorCardSif: chroma('#8B0000'),
    primary1: chroma('#3D3935'),
    primary2: chroma('#DC4405'),
    chartBarColor: '#3D3935'
  },
  iconLargeNameSidebar: 'panel-logo-swift',
  iconLargeSidebar: 180,
  iconSmallNameSidebar: 'panel-logo-swift',
  iconSmallSidebar: 36,
  spacings: {
    xxxsmall: '0.4rem',
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem'
  },
  title: 'GARANTIA DE ORIGEM',
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  },
  traceabilityPanel: <Translate text="cards:traceabilityPanel" />
}
