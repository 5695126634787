/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IconButton } from '@material-ui/core'
import { ThumbDown, ThumbUp } from '@material-ui/icons'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'

import { UserStorageService } from 'services'

import { calcOffsetForPagination } from 'utils/calcOffsetForPagination'

import { INITIAL_STATE } from 'constants/traceability.constants'
import { columnsInvoice } from 'constants/propertiesInvoice.constants'

import { PropertiesActions } from 'store/Properties'
import { all as allOrders } from 'store/Order/Order.selector'
import { all as userSelector } from 'store/User/User.selector'
import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { all as allProperties } from 'store/Properties/Properties.selector'

import {
  Icon,
  ModalMapMarker,
  Pagination,
  Table,
  ClusterMaps,
  NewPagination,
  Button,
  Modal
} from 'components'
import { GridHeader } from '../GridHeader'
import { ModalSendEmail } from 'components/ModalSendMail'

import * as S from './styled'
import { ColumnsProps } from 'components/Table/Table'

export function PropertiesTable() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { ordersKeys } = useSelector(allOrders)
  const { filters, tableLoading } = useSelector(all)
  const { filterProperties } = useSelector(allProperties)
  const { properties, propertiesLocation } = useSelector(allProperties)

  const columns = [...columnsInvoice()]
  const { pagination } = properties
  const haveCertifications = UserStorageService.getCertification()

  const [file, setFile] = useState(null)
  const [latLng, setLatLng] = useState(null)
  const [modalExport, setModalExport] = useState(false)
  const [filter, setFilter] = useState({ ...INITIAL_STATE })
  const [isNewPage, setNewPage] = useState({ ...pagination })
  const [showPropertiesMap, setShowPropertiesMap] = useState(false)
  const [openModalCertification, setOpenModalCertification] = useState(false)

  const fetch = useCallback(() => {
    setFilter(filters)
    if (!properties.items) {
      dispatch(PropertiesActions.getProperties(filters, ordersKeys))
    }
  }, [dispatch, filters, ordersKeys, properties.items])

  function openPDF(file) {
    if (!file) return
    setOpenModalCertification(!openModalCertification)
    setFile(file)
  }

  const applyIconForAnalysisAndCertificationsField = () => {
    return properties.items?.map(item => ({
      ...item,
      flagCfv: item.nrProtocolCfv ? (
        <S.LinkProtocolCfv onClick={() => openPDF(item.cfvFile)}>
          {item.nrProtocolCfv}
        </S.LinkProtocolCfv>
      ) : (
        <span>-</span>
      ),
      analyze: item.analyze ? (
        <ThumbUp fontSize="large" style={{ color: '#00c853' }} />
      ) : (
        <ThumbDown fontSize="large" style={{ color: '#c62828' }} />
      )
    }))
  }

  useEffect(() => {
    fetch()
  }, [fetch])

  function viewInMap(property) {
    const point = {
      lat: parseFloat(property.proplat.replace(/,/, '.')),
      lng: parseFloat(property.proplng.replace(/,/, '.'))
    }
    setLatLng(point)
  }

  function onExitIndividualMap() {
    setLatLng(null)
  }

  function onExitPropertiesMap() {
    dispatch(PropertiesActions.cleanPropertiesLocation())
    setShowPropertiesMap(false)
  }

  async function handleChangePage(
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    const newFilter = {
      ...filterProperties,
      ...filters
    }
    newFilter.page = newPage
    dispatch(PropertiesActions.setFilters({ ...newFilter }))
    dispatch(PropertiesActions.getProperties(newFilter, ordersKeys))
  }

  function handleNewPage() {
    const newPage = isNewPage.page
    if (newPage > pagination.totalPages) {
      const newFilter = {
        ...filterProperties,
        ...filters
      }
      newFilter.page = pagination.totalPages
      dispatch(PropertiesActions.setFilters({ ...newFilter }))
      dispatch(PropertiesActions.getProperties(newFilter, ordersKeys))
      setNewPage({ ...pagination, page: newPage })
    } else {
      const newFilter = {
        ...filterProperties,
        ...filters
      }
      newFilter.page = newPage
      dispatch(PropertiesActions.setFilters({ ...newFilter }))
      dispatch(PropertiesActions.getProperties(newFilter, ordersKeys))
      setNewPage({ ...pagination, page: newPage })
    }
  }

  function handleInputPagination(name: string, value: number) {
    setNewPage({ ...pagination, [name]: value })
  }

  function handleChangeRowsPerPage(e) {
    const newFilter = {
      ...filterProperties,
      ...filters
    }
    newFilter.limit = e.target.value
    dispatch(PropertiesActions.setFilters({ ...newFilter }))
    dispatch(PropertiesActions.getProperties(newFilter, ordersKeys))
  }

  function onPaginateFilter({ limit, page }) {
    const newFilter = {
      ...filterProperties,
      ...filters
    }

    newFilter.page = calcOffsetForPagination(
      properties.pagination.limit,
      properties.pagination.page,
      limit,
      page
    )
    newFilter.limit = limit

    dispatch(PropertiesActions.setFilters({ ...newFilter }))
    dispatch(PropertiesActions.getProperties(newFilter, ordersKeys))
  }

  async function fetchPropertiesLocationsForMap() {
    dispatch(PropertiesActions.getPropertiesLocation(filters, ordersKeys))
    setShowPropertiesMap(true)
  }

  function actionsOnTable(each) {
    return (
      <IconButton
        id="action"
        color="inherit"
        aria-label="mapButton"
        onClick={() => viewInMap(each)}
        size="medium"
        edge="start"
      >
        <Icon
          icon={
            theme.appTheme === THEMES_NAME.swift ? 'map-pin-swift' : 'map-pin'
          }
          width={12}
          height={12}
          aria-label="action"
        />
      </IconButton>
    )
  }

  function onExport() {
    setModalExport(true)
    dispatch(PropertiesActions.getPropertiesExported(filters, ordersKeys))
  }

  function filterColumnsHasCertifications(
    columns: ColumnsProps[],
    haveCertifications: boolean
  ) {
    const removeColumns = haveCertifications ? [''] : ['flagCfv']

    return columns.filter(
      (column: ColumnsProps) => !removeColumns.includes(column.name)
    )
  }

  return (
    <>
      {latLng && (
        <ModalMapMarker latLng={latLng} onExit={onExitIndividualMap} />
      )}
      {openModalCertification && (
        <Modal
          content={
            <S.ContentPdf>
              <iframe
                className="pdf-viewer"
                src={`${file}#toolbar=0&navpanes=0&scrollbar=0`}
                allowTransparency={true}
              />
            </S.ContentPdf>
          }
          cancelButton={false}
          onExit={() => setOpenModalCertification(!openModalCertification)}
        />
      )}
      {showPropertiesMap && <ClusterMaps data={propertiesLocation} />}
      <S.FullGrid item xs={12}>
        <S.HeaderContainer>
          <GridHeader
            title={t('common:propertyList')}
            mapButton={false}
            onClikMap={() => fetchPropertiesLocationsForMap()}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="primary" size="medium" onClick={onExport}>
              <S.ButtonContent>
                <CloudDownloadIcon />
                <S.ButtonText>{t('common:export')}</S.ButtonText>
              </S.ButtonContent>
            </Button>
          </div>
        </S.HeaderContainer>

        <S.GridTable>
          <Table
            columns={filterColumnsHasCertifications(
              columns,
              haveCertifications
            )}
            rows={applyIconForAnalysisAndCertificationsField() || []}
            sortColumn={{ column: filter.column, sort: filter.direction }}
            actionTable={actionsOnTable}
            actionColumnLabel={t('common:localization')}
            isLoading={tableLoading}
            isScroll={properties.items?.length > 10}
          />
          {modalExport && (
            <ModalSendEmail
              title={t('modalExportOrders:titleProperties')}
              description={t('modalExportOrders:message')}
              onExit={() => setModalExport(false)}
            />
          )}
          {properties.pagination?.totalItems > 0 && (
            <NewPagination
              count={pagination?.totalPages}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 50, 100]}
              handleInput={handleInputPagination}
              handleNewPage={handleNewPage}
              page={pagination?.page}
              limit={pagination.limit}
            />
          )}
        </S.GridTable>
      </S.FullGrid>
    </>
  )
}
