import { Base } from 'layouts'

import * as S from './styled'
import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'
import { ModuleCard } from 'components/Cards/ModuleCards'
import { useSelector } from 'react-redux'
import { all as viewModuleSelector } from 'store/ViewModule/ViewModule.selector'
import { useContext } from 'react'
import { ThemeContext } from 'App'
import { UserStorageService } from 'services'
import { useHistory, Redirect } from 'react-router-dom'

export function ViewSubModules() {
  const { selectedModule } = useSelector(viewModuleSelector)
  const history = useHistory()
  const { setTheme } = useContext(ThemeContext)
  function selectModule(module) {
    handleSelect(module)
    if (module.link) history.push(`${module.link}`)
  }

  function handleSelect(module) {
    UserStorageService.setViewModule(module.id)
    const theme = module.theme || 'standard'
    const moduleType = module.model
    setTheme(theme)
    UserStorageService.setViewModuleTheme(theme)
    UserStorageService.setViewModuleType(moduleType)
  }

  let redirectRoute = null
  if (selectedModule && selectedModule.subModules.length === 1) {
    redirectRoute = selectedModule.subModules[0].link
    handleSelect(selectedModule.subModules[0])
  }
  if (!selectedModule) {
    redirectRoute = '/'
  }
  return (
    <>
      {redirectRoute ? (
        <Redirect to={redirectRoute} />
      ) : (
        <Base>
          <S.GridContainer data-testid="cards">
            <S.GridIconCard item xs={12} md={8}>
              <S.GridCards item xs={12} md={12}>
                {selectedModule.subModules.map((module, key) => (
                  <S.GridCard
                    onClick={() => selectModule(module)}
                    item
                    xs={12}
                    md={6}
                    key={key}
                  >
                    <ModuleCard
                      variant={module.color}
                      title={module.name}
                      redirect={module.link}
                      image=""
                      icon={
                        theme.appTheme === THEMES_NAME.jbsFriboi
                          ? 'calendar-right-jbs'
                          : 'calendar-right'
                      }
                      isLoading={false}
                    />
                  </S.GridCard>
                ))}
              </S.GridCards>
            </S.GridIconCard>
          </S.GridContainer>
        </Base>
      )}
    </>
  )
}
