import { ReactNode, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { Breadcrumbs, Footer, GoBack, Sidebar } from 'components'
import { viewMode } from 'config'
import { USER_ROLES } from 'constants/user.constants'

import { UserStorageService } from 'services'

import ITENS_MENU from 'utils/routes'

import { ThemeContext } from 'App'
import { THEMES_NAME } from 'config/theme.config'

interface stateTypes {
  nameBreadcrumbs: string
}

type Props = {
  children: ReactNode
  lang?: string
  title?: string
  name?: string
}

export function Base({
  children,
  lang = 'pt-BR',
  name = 'Ecotrace Solutions',
  title = ''
}: Props) {
  const { setTheme } = useContext(ThemeContext)
  const { pathname, state } = useLocation<stateTypes>()
  const { path, params } = useRouteMatch()
  const { goBack, push } = useHistory()

  useEffect(() => {
    const defaultThemedPaths = [
      '/login',
      '/register',
      '/forgot-password',
      '/',
      '/geral'
    ]
    if (defaultThemedPaths.includes(pathname)) {
      setTheme(THEMES_NAME.jbsFriboi)
      UserStorageService.setViewModuleTheme(THEMES_NAME.jbsFriboi)
    }
  }, [pathname, setTheme])

  const { profile, user } = UserStorageService.getUserData()

  const crumbs = ITENS_MENU.filter(({ link }) => path.includes(link)).map(
    ({ link, ...rest }) => {
      if (
        rest.viewIndustry !== null &&
        rest.viewIndustry &&
        !viewMode.industry
      ) {
        push('/')
      }
      if (rest.viewRetail !== null && rest.viewRetail && !viewMode.retail) {
        push('/')
      }

      if (user.tag === USER_ROLES.viewer && !rest.viewer) {
        push('/')
      }

      if (Object.keys(params).length) {
        return {
          link: Object.keys(params).reduce(
            (path, param) => path.replace(`:${param}`, params[param]),
            link
          ),
          ...rest
        }
      } else return { link, ...rest }
    }
  )

  if (state && state.nameBreadcrumbs) {
    crumbs[crumbs.length - 1].labelPage = state.nameBreadcrumbs
  }

  return (
    <>
      <main>
        <Helmet
          htmlAttributes={{ lang }}
          title={title}
          titleTemplate={title ? `%s | ${name}` : name}
        />
        <Sidebar
          pathname={pathname}
          itens={ITENS_MENU}
          profile={profile}
          user={user}
        >
          {pathname !== '/' && <GoBack goBack={goBack} />}
          <Breadcrumbs crumbs={crumbs} />
          {children}
        </Sidebar>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  )
}
