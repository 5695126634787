import { ColumnsProps } from 'components/Table/Table'
import i18n from 'i18n'

export const columns = (): ColumnsProps[] => [
  {
    align: 'right',
    fixed: true,
    name: 'productcode',
    title: i18n.t('tables:productCode')
  },
  {
    align: 'left',
    fixed: true,
    name: 'productdescription',
    title: i18n.t('tables:productDescription')
  },
  {
    align: 'center',
    fixed: true,
    name: 'processdat',
    title: i18n.t('common:productionDate')
  },
  {
    align: 'center',
    fixed: true,
    name: 'expiresat',
    title: i18n.t('common:expirationDate')
  },
  {
    align: 'center',
    name: 'rawweight',
    title: i18n.t('tables:grossWeight')
  },
  {
    align: 'center',
    name: 'clearweight',
    title: i18n.t('tables:netWeight')
  },
  {
    align: 'center',
    name: 'qtt',
    title: i18n.t('common:qtyBoxes')
  },
  {
    align: 'center',
    name: 'slaughtersif',
    title: i18n.t('tables:slaughterSIF')
  },
  {
    align: 'center',
    name: 'processsif',
    title: i18n.t('tables:processSIF')
  },
  {
    align: 'center',
    name: 'invoice',
    title: i18n.t('tables:invoice')
  },
  {
    align: 'center',
    name: 'stockcnpj',
    title: i18n.t('tables:shipperCNPJ')
  },
  {
    align: 'left',
    name: 'docnamelocalstock',
    title: i18n.t('tables:shipperCorporateName')
  },
  {
    align: 'center',
    name: 'shipmentdate',
    title: i18n.t('tables:shippingDate')
  }
]
