import { useState, ReactNode } from 'react'

import * as S from './styled'

export type CheckButtonProps = {
  defaultValue?: boolean
  className?: string
  children: ReactNode
  onToggle?: (_value: boolean) => void
  disableChange?: boolean
}

export function CheckButton({
  defaultValue,
  children,
  onToggle,
  disableChange = false
}: CheckButtonProps) {
  const [isChecked, setIsChecked] = useState(defaultValue)

  function setToggleButton() {
    const value = !isChecked
    if (!disableChange) setIsChecked(value)
    onToggle(value)
  }

  return (
    <S.CheckButton
      className={defaultValue ? 'checked' : 'default'}
      onClick={setToggleButton}
    >
      {children}
    </S.CheckButton>
  )
}
