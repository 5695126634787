import styled, { css, keyframes } from 'styled-components'

interface CardProps {
  minWidth: number
  textLength?: number
}

const fade = keyframes`
  from {
    opacity: 0.7;
  }

  to {
    opacity: 1;
  }
`
export const Card = styled.div<{
  isLoading: boolean
}>`
  background: white; // pegar tema
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0.8rem;
  padding: 2rem 0;
  ${props =>
    props.isLoading &&
    css`
      animation: 1.5s ${fade} alternate infinite;
    `}
`
export const Quantity = styled.div<CardProps>`
  min-width: ${({ minWidth }) => minWidth}rem;
  display: flex;
  justify-content: center;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.8rem;
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  position: relative;
  left: -1.1rem;
  width: 30.9rem;
  height: 13.5rem;
  &.blue {
    background: ${({ theme }) => theme.colors.colorCardBlue};
    color: white;
  }
  &.lightBlue {
    background: ${({ theme }) => theme.colors.colorCardLight};
    color: white;
  }
  &.gray {
    background: ${({ theme }) => theme.colors.colorCardGray};
    color: white;
  }
  &.darkBlue {
    background: ${({ theme }) => theme.colors.colorCardDark};
    color: white;
  }
  &.red {
    background: ${({ theme }) => theme.colors.colorCardRed};
    color: white;
  }
  &.darkYellow {
    background: ${({ theme }) => theme.colors.colorCardDarkYellow};
    color: white;
  }
  &.grayLight {
    background: ${({ theme }) => theme.colors.colorCardGrayLight};
    color: white;
  }
`
export const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const Service = styled.div``
export const Image = styled.img`
  src: url(${({ src }) => src});
  height: auto;
  object-fit: contain;
  object-position: center;
  margin: 3rem;
  width: 23rem;
`
export const Title = styled.div`
  color: ${({ theme }) => theme.colors.primary2};
  font-size: 1.8rem;
  font-weight: bolder;
  padding-bottom: 0.5rem;
`

export const Description = styled.div`
  color: #000000;
  font-size: 1.2rem;
`
export const Icon = styled.div`
  height: 4.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.2rem;
  padding: 0.5rem;
  &:hover {
    background: #00000006;
    border-radius: 50%;
  }
`
