import styled, { css } from 'styled-components'

import MaterialTab from '@material-ui/core/Tab'
import MaterialTabList from '@material-ui/lab/TabList'
import MaterialTabPanel from '@material-ui/lab/TabPanel'

import { Grid } from '@material-ui/core'

export const Wrapper = styled(Grid)`
  // border-radius: 1rem;
  // flex-grow: 1;
  // margin-bottom: 3.2rem;

  border-radius: 1.2rem;
  background: #fff;
  margin: 20px 0px;
  box-shadow: 0px 5px 25px #0000000d;
  width: 100%;
  flex-wrap: wrap;
  padding: 20px 20px 0 20px;
`
export const HeaderContainer = styled.div`
  margin-left: 20px;
`
export const Panel = styled(MaterialTabPanel)`
  background-color: white;
  box-shadow: 0px 5px 25px #0000000d;
  border-radius: 0 1rem 1rem 1rem;
  padding: 1rem 1.6rem;
`

export const TabList = styled(MaterialTabList)`
  && {
    background-color: transparent;
    min-height: 0;

    .MuiTabs-flexContainer {
      background-color: transparent;
    }

    .MuiTabs-indicator {
      display: none;
    }
  }
`

export const Tab = styled(MaterialTab)<{ show?: boolean; isDisable?: boolean }>`
  && {
    background: transparent
      linear-gradient(180deg, #fff 0%, #fff 85%, #f2f3f7 100%) 0% 0% no-repeat
      padding-box;
    color: ${({ theme }) => theme.colors.darkGray};
    padding: 0.8rem 2.4rem;
    min-height: 0;
    ${({ show = true }) =>
      !show &&
      css`
        display: none;
      `}
    ${props =>
      props.isDisable &&
      css`
        cursor: not-allowed;
        background: #ddd;
      `}

    &:first-of-type {
      border-radius: 0.8rem 0 0 0;
    }

    &:last-of-type {
      border-radius: 0 0.8rem 0 0;
    }

    .MuiTab-wrapper {
      font-size: 1.2rem;
      text-transform: none;
    }

    &.Mui-selected {
      background: ${({ theme }) => theme.colors.primary};
      color: white;
    }
  }
`
export const GridFilter = styled(Grid)``

export const GridInput = styled(Grid)``
