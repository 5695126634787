/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table } from './Table'
import { Pagination } from 'components'
import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { all as allTableSIF } from 'store/TableSIF/TableSIF.selector'

import { columns } from 'constants/box.constants'
import { INITIAL_STATE } from 'constants/traceability.constants'
import * as S from './styled'
import { calcOffsetForPagination } from 'utils/calcOffsetForPagination'
import { TableSIFActions } from 'store/TableSIF'

export function AnalisysSIFTable() {
  const dispatch = useDispatch()

  const [filter] = useState({ ...INITIAL_STATE })
  const { filters, tableLoading } = useSelector(all)
  const { tableSIF } = useSelector(allTableSIF)

  useEffect(() => {
    if (filters.begin && filters.end && !tableLoading) {
      dispatch(
        TableSIFActions.getTableSIF({
          ...filters
          // typeDate: null,
          // years: filters.years.length
          //   ? filters.years
          //   : [new Date().getFullYear()]
        })
      )
    }
  }, [dispatch, filters])

  function onPaginateFilter({ limit, page }) {
    const newFilter = {
      ...filters
    }

    // add 1 to page to mantain compatibility with backend
    newFilter.page =
      calcOffsetForPagination(
        tableSIF.pagination.limit,
        tableSIF.pagination.page,
        limit,
        page
      ) + 1
    newFilter.limit = limit || tableSIF.pagination.limit
    dispatch(TableSIFActions.getTableSIF({ ...newFilter }))
  }

  return (
    <S.FullGrid item xs={12}>
      <S.GridTable>
        <Table
          columns={columns()}
          rows={tableSIF.items || []}
          sortColumn={{ column: filter.column, sort: filter.direction }}
          isLoading={tableLoading}
        />
        {tableSIF.pagination?.totalItems > 0 && (
          <Pagination
            filters={filters}
            limit={tableSIF.pagination.limit}
            page={tableSIF.pagination.page - 1}
            totalItems={tableSIF.pagination.totalItems}
            rowsPerPageOptions={[2, 5, 10, 50]}
            setFilter={onPaginateFilter}
          />
        )}
      </S.GridTable>
    </S.FullGrid>
  )
}
