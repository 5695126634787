import { OrderHistoryType } from 'types/OrderHistory.types'
import { HeaderBlock, Table } from 'components'
import IconButton from '@material-ui/core/IconButton'
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined'
import exportFromJSON from 'export-from-json'

import { allColumns, columnDownload } from './GridColumns'

import { USER_ROLES } from 'constants/user.constants'
import { UserStorageService } from 'services'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

type PropTypes = {
  orderHistories: OrderHistoryType[]
}

export function OrderHistoryGrid({ orderHistories }: PropTypes) {
  const { t } = useTranslation()
  const { user } = UserStorageService.getUserData()
  const allColumnsReturn = allColumns()
  const columnDownloadReturn = columnDownload()

  const columns =
    user.tag === USER_ROLES.viewer
      ? [...allColumnsReturn]
      : [...columnDownloadReturn, ...allColumnsReturn]

  function changeColumn({ column, row }) {
    if (column.name === 'payload') {
      return (
        <IconButton
          color="primary"
          aria-label="download json file"
          component="span"
          onClick={() => downloadJSON(row)}
        >
          <CloudDownloadOutlinedIcon />
        </IconButton>
      )
    } else return row[column.name]
  }

  async function downloadJSON({ hashPartner, originalData, payload }) {
    const data = {
      ...originalData,
      payload: { ...payload }
    }
    const fileName = hashPartner
    const exportType = exportFromJSON.types.json
    exportFromJSON({ data, fileName, exportType })
  }

  return (
    <>
      <S.GridTable>
        <HeaderBlock title={t('common:completeHistory')} />
        <S.TableWrapper>
          <Table
            rows={orderHistories}
            columns={columns}
            changeColumn={changeColumn}
          />
        </S.TableWrapper>
      </S.GridTable>
    </>
  )
}
