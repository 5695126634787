import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  /* height: 60%; */
  margin-bottom: 3rem;
`
export const Graphic = styled(Grid)`
  border-radius: 1.2rem;
  background: white;
  box-shadow: 0px 5px 25px #0000000d;
  margin-right: 2rem;
`
export const Details = styled(Grid)`
  border-radius: 1.2rem;
  background: white;
  box-shadow: 0px 5px 25px #0000000d;
`

export const GridHeader = styled.div`
  padding-top: 2.4rem;
  padding-bottom: 3.1rem;
`
export const Title = styled.div`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 600;
  padding-left: 2.4rem;
`
export const BoxTitle = styled.h2``
export const Table = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 0rem;
`
export const Left = styled.div`
  p {
    font-size: 1.4rem;
    margin-top: 2.7rem;
    color: #464a53;
  }
`
export const Right = styled.div`
  p {
    font-size: 1.4rem;
    margin-top: 2.7rem;
    color: #464a53;
  }
`
export const Subtitle = styled.span`
  font-size: 1.4rem;
  color: #abafb3; //tema
  font-weight: 600;
`
export const Button = styled.button`
  padding-left: 2.4rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border: none;
  background: none;
  /* padding-top: 12rem; */
  padding-bottom: 2rem;
  .icon-graphic {
    height: 2.9rem;
    width: 2.9rem;
    margin-bottom: -6px;
    margin-right: 1.5rem;
    &:hover {
      background: #00000006;
      border-radius: 50%;
    }
  }
`
export const ViewMore = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.lightGray};
  padding-right: 1.2rem;
`
