import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const GridContainer = styled(Grid)`
  padding-top: 3.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 25px #0000000d;
  border-radius: 1.2rem;
  margin: 3rem 0;
  width: 100%;
`

export const GridIconCard = styled(Grid)`
  margin: 0 auto !important;
`
export const GridCard = styled(Grid)`
  border-radius: 0.8rem;
  box-shadow: 0px 11px 29px #00000021;
  margin-bottom: 40px;
  :first-child {
    margin-right: 5rem;
  }
`
export const GridCards = styled(Grid)`
  display: flex;
`
