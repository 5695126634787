export function calcOffsetForPagination(
  oldLimit: number,
  oldPage: number,
  newLimit: number,
  newPage: number
) {
  const offset = oldPage * oldLimit
  if (newLimit && oldLimit !== newLimit && offset > 0) {
    let limitValue = newLimit
    if (oldLimit < newLimit) limitValue = newLimit + oldLimit
    const pageWithOffset: number = +(offset / limitValue).toFixed()
    return pageWithOffset
  }
  return newPage
}
