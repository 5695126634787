import { HTTP } from 'providers'
// import { ViewModuleType } from 'types/ViewModule.types'

export class ViewModuleService extends HTTP {
  private endpoint = '/viewmodules'
  private config: Record<string, any> = {
    showLoading: true
  }

  public fetchAll(params = {}) {
    return this.get({ endpoint: this.endpoint, params })
  }

  public fetchOne(id) {
    return this.get({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public async options() {
    return this.get({ endpoint: `${this.endpoint}/options` })
  }

  public async navigation(userId) {
    return this.get({ endpoint: `${this.endpoint}/navigation/${userId}` })
  }
}
