import { Base } from 'layouts'
import { GeneralFilters } from 'components/GeneralFilters'
import { InlineNetWeightFilter } from './InlineNetWeightFilter'
import * as S from './styled'
import { NetWeightChart } from 'pages/TraceabilityTables/CardCharts/NetWeightChart'

export function NetWeight() {
  return (
    <Base>
      <GeneralFilters
        InlineFilter={InlineNetWeightFilter}
        showInlineFilters={false}
      />
      <S.ChartsContainer>
        <S.GridCardsCharts container spacing={3}>
          <NetWeightChart />
        </S.GridCardsCharts>
      </S.ChartsContainer>
    </Base>
  )
}
