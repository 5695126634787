import { get } from 'lodash'

import {
  ViewModuleOptionsType,
  ViewModuleListType
} from 'types/ViewModule.types'

import { types } from './ViewModule.actions'

type ViewModule = {
  viewModules: ViewModuleListType[]
  selectedModule: ViewModuleListType | null
  loading: boolean
  options?: ViewModuleOptionsType[]
}

const INITIAL_STATE = {
  viewModules: [],
  selectedModule: null,
  loading: false,
  options: []
}

function setState(state: ViewModule) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET_LOADING]: setState(state),
    [types.SET_VIEW_MODULES]: setState(state),
    [types.SET_SELECTED_MODULE]: setState(state),
    [types.SET_VIEW_MODULES_OPTIONS]: setState(state),
    [types.GET_VIEW_MODULES_NAVIGATION]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}
