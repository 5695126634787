import { useCallback, useEffect, useState, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Table, NewPagination, Button } from 'components'
import { GridHeader } from '../GridHeader'

import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { all as allBox } from 'store/Box/Box.selector'
import { all as allOrders } from 'store/Order/Order.selector'
import { BoxActions } from 'store/Box'

// import { columns as allColumns } from 'constants/box.constants'
import { columns as allBoxColumns } from 'constants/box-retail.constants'
import { INITIAL_STATE } from 'constants/traceability.constants'
import { useTranslation } from 'react-i18next'

import * as S from './styled'
import { ColumnsProps } from 'components/Table/Table'
import { UserStorageService } from 'services'
import { ModalSendEmail } from 'components/ModalSendMail'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { THEMES_NAME } from 'config/theme.config'

// import { USER_ROLES } from 'constants/user.constants'
// import { UserStorageService } from 'services'

export function BoxesList() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [modalExport, setModalExport] = useState(false)
  const [filter, setFilter] = useState({ ...INITIAL_STATE })
  const { filters, tableLoading } = useSelector(all)
  const { filterBoxes, boxes = [] } = useSelector(allBox)
  const { ordersKeys } = useSelector(allOrders)
  const columns = [...allBoxColumns()]
  const { pagination } = boxes
  const [isNewPage, setNewPage] = useState({ ...pagination })
  const queryModel = UserStorageService.getViewModuleType()
  const theme = UserStorageService.getViewModuleTheme()
  const fetch = useCallback(() => {
    setFilter(filters)
    if (!boxes.items) dispatch(BoxActions.getBoxes(filters, ordersKeys))
  }, [dispatch, filters, ordersKeys, boxes.items])

  useEffect(() => {
    if (ordersKeys.length) fetch()
  }, [fetch, ordersKeys])

  function changeColumn({ column, row }) {
    if (column.name === 'status') {
      return <p>{row.status ? t('common:yes') : t('common:no')}</p>
    } else return row[column.name]
  }

  async function handleChangePage(
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    const newFilter = {
      ...filterBoxes,
      ...filters
    }
    newFilter.page = newPage
    dispatch(BoxActions.setFilterBoxes(newFilter))
    dispatch(BoxActions.getBoxes(newFilter, ordersKeys))
  }

  function filterColumnsByModel(columns: ColumnsProps[], queryModel: string) {
    const removeColumns =
      queryModel === 'INNATURA'
        ? ['processsif']
        : [
            'slaughterindustrycnpj',
            'boningoutindustrycnpj',
            'slaughterdat',
            'slaughtersif',
            'boningoutsif',
            theme === THEMES_NAME.sampco ? 'barcode' : ''
          ]
    return columns.filter(
      (column: ColumnsProps) => !removeColumns.includes(column.name)
    )
  }

  function handleNewPage() {
    const newPage = isNewPage.page
    if (newPage > pagination.totalPages) {
      const newFilter = {
        ...filterBoxes,
        ...filters
      }
      newFilter.page = pagination.totalPages
      dispatch(BoxActions.setFilterBoxes(newFilter))
      dispatch(BoxActions.getBoxes(newFilter, ordersKeys))
      setNewPage({ ...pagination, page: newPage })
    } else {
      const newFilter = {
        ...filterBoxes,
        ...filters
      }
      newFilter.page = newPage
      dispatch(BoxActions.setFilterBoxes(newFilter))
      dispatch(BoxActions.getBoxes(newFilter, ordersKeys))
      setNewPage({ ...pagination, page: newPage })
    }
  }

  function handleInputPagination(name: string, value: number) {
    setNewPage({ ...pagination, [name]: value })
  }

  function handleChangeRowsPerPage(e) {
    const newFilter = {
      ...filterBoxes,
      ...filters
    }
    newFilter.limit = e.target.value
    dispatch(BoxActions.setFilterBoxes(newFilter))
    dispatch(BoxActions.getBoxes(newFilter, ordersKeys))
  }

  function onExport() {
    setModalExport(true)
    dispatch(BoxActions.getBoxesExported(filters, ordersKeys))
  }

  return (
    <S.FullGrid item xs={12}>
      <S.HeaderContainer>
        {boxes.items?.length > 0 && (
          <GridHeader title={t('common:productList')} />
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button variant="primary" size="medium" onClick={onExport}>
            <S.ButtonContent>
              <CloudDownloadIcon />
              <S.ButtonText>{t('common:export')}</S.ButtonText>
            </S.ButtonContent>
          </Button>
        </div>
      </S.HeaderContainer>
      <S.GridTable>
        <Table
          columns={filterColumnsByModel(columns, queryModel)}
          rows={boxes.items || []}
          sortColumn={{ column: filter.column, sort: filter.direction }}
          isLoading={tableLoading}
          changeColumn={changeColumn}
          isScroll={boxes.items?.length > 10}
        />
        {modalExport && (
          <ModalSendEmail
            title={t('modalExportOrders:titleItems')}
            description={t('modalExportOrders:message')}
            onExit={() => setModalExport(false)}
          />
        )}
        {pagination?.totalItems > 0 && (
          <NewPagination
            count={pagination?.totalPages}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            handleInput={handleInputPagination}
            handleNewPage={handleNewPage}
            page={pagination?.page}
            limit={pagination.limit}
          />
        )}
      </S.GridTable>
    </S.FullGrid>
  )
}
