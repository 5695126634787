import { omit } from 'lodash'
import { call, put } from 'redux-saga/effects'

import { RawMaterialBoxService } from 'services'
import FilteringTraceability from 'utils/FilteringTraceability'

import * as actions from './RawMaterialBox.actions'
import * as actionsKpis from '../TraceabilityTables/TraceabilityTables.actions'
import { spinnerActions } from 'store/Spinner'

export function* getRawMaterialBoxes(action) {
  yield put(spinnerActions.setVisibility(true))
  const _filters = FilteringTraceability(action.payload.filterRawMaterialBox)

  const $box = new RawMaterialBoxService()

  const boxes = yield call($box.getBoxes.bind($box), {
    ..._filters,
    orderskeys: action.payload.ordersKeys.toString(),
    skukeys: action.payload.orderSku.toString()
  })

  if (boxes.success) {
    yield put(
      actions.setRawMaterialBoxes({
        rawMaterialBox: omit(boxes.data, ['kpis', 'charts'])
      })
    )
  } else {
    yield put(
      actions.setRawMaterialBoxes({
        rawMaterialBox: { items: [], pagination: { totalItems: 0 } }
      })
    )
    if (boxes.status !== 404) {
      yield put(actionsKpis.cleanKpis())
    }
  }

  yield put(spinnerActions.setVisibility(false))
}

export function* getRawMaterialBoxesExport(action) {
  const $order = new RawMaterialBoxService()
  const _filters = FilteringTraceability(action.payload.filterRawMaterialBox)

  yield call($order.getBoxesExport.bind($order), {
    ..._filters,
    orderskeys: action.payload.ordersKeys.toString(),
    skukeys: action.payload.orderSku.toString()
  })
}
